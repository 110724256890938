import React from 'react';
import ContentTableAdmin from './ContentAdmin';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import '../../assets/scss/layouts/pages/_home.scss';

function HomeAdmin() {
  return (
    <GCPrivatePageLayout>
      <ContentTableAdmin />
    </GCPrivatePageLayout>
  );
}
export default HomeAdmin;
