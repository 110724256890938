import React from 'react';
import axios from 'axios';
import cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import App from './App';

if (cookies.get('loginToken1')) {
  const token = cookies.get('loginToken1');
  axios.defaults.headers.common.Authorization = token;
} else if (cookies.get('loginToken')) {
  const token = cookies.get('loginToken');
  axios.defaults.headers.common.Authorization = token;
} else if (cookies.get('auth_token1')) {
  const token = cookies.get('auth_token1');
  axios.defaults.headers.common.Authorization = token;
} else if (cookies.get('auth_token')) {
  const token = cookies.get('auth_token');
  axios.defaults.headers.common.Authorization = token;
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
