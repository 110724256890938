import React, { Component } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FormLabel from '../common/commonlabel';
import CommonMultiselect from '../common/CommonMultiselect';
import {
  optionApply,
  optionIndustry,
  supportingCompany,
} from '../common/selectlist';

const selectedIntrests = [];
const selectedindustryPreferences = [];
const selectedStages = [];
export default class Opportunities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageHeight: 200,
    };
    this.resizeForm = this.resizeForm.bind(this);
  }

  componentDidMount() {
    this.resizeForm();
    window.addEventListener('resize', () => this.resizeForm());
    window.addEventListener('load', () => this.resizeForm());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeForm());
    window.removeEventListener('load', () => this.resizeForm());
  }

  resizeForm() {
    // const pageOuterHeight = document.getElementById('outerWrap').offsetHeight;
    const pageOuterHeight = window.innerHeight - 120;
    const pageheadHeight = document?.getElementById('formHead')?.offsetHeight;
    const pageCtaHeight = document?.getElementById('formCta')?.offsetHeight;
    const finalHeight = pageOuterHeight - (pageheadHeight + pageCtaHeight);
    this.setState({ pageHeight: finalHeight });
    console.log(`
    pageOuterHeight == ${pageOuterHeight} --- ${window.innerHeight} --> window.innerHeight
    pageheadHeight == ${pageheadHeight}
    pageCtaHeight == ${pageCtaHeight}
    finalHeight == ${finalHeight}
    `);
  }

  continue(ev) {
    const { validateInterestedIn, validateIndustryPreferences, nextStep } =
      this.props;
    ev.preventDefault();
    const validateInterestedIns = validateInterestedIn();
    const validateIndPreff = validateIndustryPreferences();
    if (validateInterestedIns && validateIndPreff) {
      setTimeout(() => {
        nextStep();
      }, 200);
    }
  }

  previous(ev) {
    const { prevStep } = this.props;
    ev.preventDefault();
    prevStep();
  }

  selectInterestedIn(e) {
    const { formValues, component } = this.props;
    const selectedinterest = e.currentTarget.ariaLabel;
    const val = 'Ecosystem';
    const isFound = selectedIntrests.includes(val);
    if (isFound === false) {
      selectedIntrests.push(val);
    }
    const index = formValues.interestedIn.indexOf(selectedinterest);
    if (index > -1) {
      formValues.interestedIn.splice(index, 1);
      component.setState({ interestedIn: selectedIntrests });
    } else {
      selectedIntrests.push(selectedinterest);
      component.setState({
        interestedIn: selectedIntrests,
        interestedInErr: '',
      });
    }
  }

  selectindustryPreferences(e) {
    const { formValues, component } = this.props;
    const selectedindustry = e.currentTarget.ariaLabel;
    const index = formValues.industryPreferences.indexOf(selectedindustry);
    if (index > -1) {
      formValues.industryPreferences.splice(index, 1);
      component.setState({
        industryPreferences: selectedindustryPreferences,
      });
    } else {
      selectedindustryPreferences.push(selectedindustry);
      component.setState({
        industryPreferences: selectedindustryPreferences,
        industryPreferencesErr: '',
      });
    }
  }

  selectStage(e) {
    const { formValues, component } = this.props;
    const selectedStage = e.currentTarget.ariaLabel;
    const index = formValues.stage.indexOf(selectedStage);
    if (index > -1) {
      formValues.stage.splice(index, 1);
      component.setState({ stage: selectedStages });
    } else {
      selectedStages.push(selectedStage);
      component.setState({ stage: selectedStages });
    }
  }

  render() {
    const { formValues, handleChange } = this.props;
    const { interestedInErr, industryPreferencesErr } = formValues;
    const { pageHeight } = this.state;

    return (
      <>
        <div
          id="formHead"
          className="LocationPrefernces cneterForm"
        >
          <div id="pageNumber">
            <h4> Step 2 of 5</h4>
          </div>
          <div id="pageHeader">
            <h2> What opportunities are you interested in?</h2>
          </div>
          <hr id="pageHr" />
        </div>

        <div className="formHeightCuston">
          <form
            onSubmit={(ev) => this.continue(ev)}
            className="form-horizontal"
          >
            <div
              className="formMain"
              style={{ height: pageHeight }}
            >
              <Scrollbars
                style={{ width: '100%', height: '100%' }}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                horizontal={false}
                className="scrollbarHide"
              >
                <div className="formInputWrap">
                  <div>
                    <h3> What are you interested in?</h3>
                  </div>
                  <FormGroup className="form-group-active row">
                    <FormLabel
                      id="interest"
                      htmlFor="interest"
                      text="Select all that apply"
                    />
                    <CommonMultiselect
                      itemArray={optionApply}
                      handleClick={(e) => this.selectInterestedIn(e)}
                      compareArray={formValues.interestedIn}
                    />
                    {interestedInErr ? (
                      <div
                        className="error"
                        id="error2"
                      >
                        {interestedInErr}
                      </div>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                  <hr />
                  <div>
                    <div>
                      <h3>
                        {' '}
                        What industries are you interested in working in?{' '}
                      </h3>
                    </div>
                    <FormGroup className="form-group-active row">
                      <FormLabel
                        id="industry"
                        htmlFor="industry"
                        text="Select any preferences in industry"
                      />
                      <CommonMultiselect
                        itemArray={optionIndustry}
                        handleClick={(e) => this.selectindustryPreferences(e)}
                        compareArray={formValues.industryPreferences}
                      />
                      {industryPreferencesErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {industryPreferencesErr}
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>

                    <hr />
                    <div>
                      <h3> What stage company do you want to work with? </h3>
                    </div>
                    <FormGroup className="form-group-active row">
                      <FormLabel
                        id="stage"
                        htmlFor="stage"
                        text="Select all that apply"
                      />
                      <CommonMultiselect
                        itemArray={supportingCompany}
                        handleClick={(e) => this.selectStage(e)}
                        compareArray={formValues.stage}
                      />
                    </FormGroup>

                    <hr />
                    <div>
                      <h3>
                        {' '}
                        Tell us more about the type of opportunities you are
                        looking for{' '}
                      </h3>
                    </div>
                    <FormGroup className="form-group-active row">
                      {/* <Label className="form control-label">Tell us more about the type of opportunities you are looking for</Label> */}
                      {/* <FormLabel id="roles" htmlFor="roles" text='Tell us more about what type of roles are you looking for' /> */}
                      <textarea
                        name="roles"
                        value={formValues.roles}
                        onChange={(e) => handleChange(e)}
                        placeholder="Co-founder role at an early stage startup"
                        className="form-control"
                      />
                    </FormGroup>
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div
              id="formCta"
              className="actionButton"
            >
              <div className="submit">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(ev) => this.previous(ev)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
