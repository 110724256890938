import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import cookies from 'js-cookie';

class Page401 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectLogin: false,
    };
  }

  componentDidMount() {
    this.logOut();
  }

  logOut() {
    cookies.remove('loginToken');
    cookies.remove('email');
    cookies.remove('role'); // add at last don't
    cookies.remove('loginToken1');
    cookies.remove('email1');
    cookies.remove('role1'); // add at last don't
    cookies.remove('loginAs');
    cookies.remove('currentPage');
    cookies.remove('currentClientPage');
    cookies.set('tabChange', 'no');
    this.setState({ redirectLogin: true });
    window.location.reload();
  }

  render() {
    const { redirectLogin } = this.state;
    if (redirectLogin) {
      return <Redirect to="/login" />;
    }
    return <div>page 401</div>;
  }
}
export default Page401;
