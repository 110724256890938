import React, { Component } from 'react';
import cookies from 'js-cookie';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import HomeAdmin from './components/pages/HomeAdmin';
import ClientPersonalInfo from './components/pages/ClientPersonalInfo';
import CreateUserForm from './components/pages/CreateUser';
import UpdateUser from './components/pages/UpdateUser';
import UserDetail from './components/pages/UserDetail';
import Viewprofile from './components/Settings/UserEdit';
import ProfileSetting from './components/Settings/ProfileSetting';
import UserEditDetail from './components/pages/UserEditDetail';
import Salesforce from './components/pages/SalesforcePage';
import EditProfileInfo from './components/pages/EditProfile';
import AuditContent from './components/pages/AuditContent';
import NotFound from './components/dataNotFound/DataNotFound';
import Page401 from './components/common/Page401';
import ChangePasswd from './components/Settings/UpdatePassword';
import './app.scss';
import ContactTable from './components/pages/ContactTable_new';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/create_user"
            component={CreateUserForm}
          />
          {cookies.get('loginToken') || cookies.get('auth_token') ? (
            <Route
              exact
              from="/"
              to="/admin"
              component={HomeAdmin}
            />
          ) : (
            [
              cookies.get('loginToken1') || cookies.get('auth_token1') ? (
                <Route
                  exact
                  from="/"
                  to="/client"
                  component={ContactTable}
                />
              ) : (
                <Route
                  path="/notFound"
                  component={NotFound}
                />
              ),
            ]
          )}
          <Route
            exact
            path="/admin"
            component={HomeAdmin}
          />
          <Route
            path="/client"
            component={ContactTable}
          />
          <Route
            path="/historicalIntro"
            component={ContactTable}
          />
          <Route
            path="/contact_details/:id"
            component={ClientPersonalInfo}
          />
          <Route
            exact
            path="/update_user"
            component={UpdateUser}
          />
          <Route
            exact
            path="/userdetail"
            component={UserDetail}
          />
          <Route
            exact
            path="/update_password"
            component={ChangePasswd}
          />
          <Route
            exact
            path="/edit_details"
            component={UserEditDetail}
          />
          <Route
            exact
            path="/edit_user"
            component={Viewprofile}
          />
          <Route
            exact
            path="/personal_info"
            component={ProfileSetting}
          />
          <Route
            exact
            path="/salesforce"
            component={Salesforce}
          />
          <Route
            exact
            path="/edit-profile"
            component={EditProfileInfo}
          />
          <Route
            exact
            path="/unauthorize"
            component={Page401}
          />
          <Route
            exact
            path="/audit-trail"
            component={AuditContent}
          />
        </Switch>
      </Router>
    );
  }
}

export default Main;
