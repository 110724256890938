/* eslint-disable react/no-unused-state */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import ContactDetails from '../../contactDetail/ContactDetails';
import CommonButton from '../button/CommonButton';
import { generateLinkedInUrl } from '../Utilities';

class AsideComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newData: {},
      pagination: '',
      currentIndex: 0,
    };
    this.handlePagination = this.handlePagination.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
    this.handleLinkedInClick = this.handleLinkedInClick.bind(this);
  }

  componentDidMount() {
    const { id, tableData } = this.props;
    const newData = tableData && tableData.filter((d) => d.id === parseInt(id, 10));
    this.setState({ newData: newData[0], id: newData[0].id });
    this.handlePagination();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, handleId, tableData } = this.props;
    const { id: currentStateId } = this.state;


    if (currentStateId !== prevState.id) {
      handleId(currentStateId);
    }

    if (id !== prevProps.id) {
      const newData = tableData && tableData.filter((d) => d.id === parseInt(id, 10));
      this.setState({ newData: newData[0], id: newData[0].id });
      this.handlePagination();
    }
  }

  handleLinkedInClick() {
    const { newData } = this.state;
    const linkedInUrl = newData && newData.profile;
    window.open(
      generateLinkedInUrl(linkedInUrl),
      '_blank',
      'noopener,noreferrer'
    );
  }

  handlePagination(action = '') {
    const { tableData, id } = this.props;
    const currentIndex = tableData.findIndex((item) => item.id === parseInt(id, 10));

    let newCurrentIndex;

    if (action === 'prev' && currentIndex > 0) {
      newCurrentIndex = currentIndex - 1;
    } else if (action === 'next' && currentIndex < tableData.length - 1) {
      newCurrentIndex = currentIndex + 1;
    } else {
      newCurrentIndex = currentIndex;
    }

    if (newCurrentIndex !== currentIndex) {
      this.setState({
        pagination: action,
        currentIndex: newCurrentIndex,
        newData: tableData[newCurrentIndex],
        id: tableData[newCurrentIndex].id,
      });
    }
    this.updateIndex(newCurrentIndex);
  }

  updateIndex(currentIndex) {
    this.setState({ currentIndex });
  }

  render() {
    const {
      handleRequestInfoModal,
      counts,
      tableData,
      showAside,
      handleAside,
    } = this.props;
    const { newData, currentIndex } = this.state;

    return (
      <div
        className={`${showAside ? 'slide-left-animate' : ''} aside slide-left`}
      >
        <div className="border-left aside-sec">
          <div className="aside-header">
            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-row">
                <CommonButton
                  variant="secondary"
                  clickHandler={handleAside}
                  icon="previous-arrow"
                  iconSize={16}
                  customClass="border-0 hide-slide arrow-back p-0"
                />
                <div className="contact-hdr d-flex flex-column">
                  <h3 className="aside-header-text">{`${
                    newData && newData.first_name
                  } ${newData && newData.last_name}`}</h3>
                  <p>{newData.title}</p>
                </div>
              </div>
              <div className="d-inline-flex pt-3 ico-btn">
                <CommonButton
                  clickHandler={handleRequestInfoModal}
                  variant="primary"
                  label="Request Introduction(s)"
                  icon="add-request-info"
                  iconColor="#FFFFFF"
                  buttonIconSize={16}
                  customClass="request-info"
                />
                <CommonButton
                  variant="secondary"
                  label="View LinkedIn Profile"
                  icon="Leading-Icon"
                  iconColor="primary"
                  buttonIconSize={16}
                  clickHandler={this.handleLinkedInClick}
                  isDisabled={!newData.profile}
                  customClass="linkedin-btn"
                />
              </div>
            </div>
          </div>
          <div className="aside-form mt-3 ml-3 mr-3">
            <div>
              {/* Overview Section */}
              <div className="container border rounded-3 ">
                <h4>Overview</h4>
                <div className="row my-4">
                  <ContactDetails
                    subHeading="Current Company"
                    details={newData.account_name}
                    col={2}
                  />
                  <ContactDetails
                    subHeading="Previous Companies"
                    details={
                      newData.previous_companies
                        ? Object.keys(newData).length &&
                          newData.previous_companies.replaceAll(';', ', ')
                        : ''
                    }
                  />
                </div>
                <div className="row my-4">
                  <ContactDetails
                    subHeading="Function"
                    details={
                      newData.function
                        ? Object.keys(newData).length &&
                          newData.function.replaceAll(';', ', ')
                        : ''
                    }
                  />
                </div>
                <div className="row my-4">
                  <ContactDetails
                    subHeading="Preferred Role"
                    details={
                      newData.status
                        ? Object.keys(newData).length &&
                          newData.status
                            .replaceAll('Free Agent - Operator', 'Fulltime Roles')
                            .replaceAll('Free Agent - ', '')
                            .replaceAll('Ecosystem;', '')
                            .replaceAll('Ecosystem', '')
                            .replaceAll(';', ', ')
                        : ''
                    }
                  />
                </div>
                <div className="row my-4">
                  <ContactDetails
                    subHeading="GC POC"
                    details={
                      newData.gc_poc
                        ? Object.keys(newData).length &&
                          newData.gc_poc.replaceAll(';', ', ')
                        : ''
                    }
                  />
                </div>
                <div className="row my-4">
                  <ContactDetails
                    subHeading="Industry Experience"
                    details={
                      newData.sector_experience
                        ? Object.keys(newData).length &&
                          newData.sector_experience.replaceAll(';', ', ')
                        : ''
                    }
                  />
                </div>
              </div>
              <div className="container border rounded-3 ">
                {/* Prefrence Section */}

                <h4>Preferences</h4>
                <div className="row my-4">
                  <ContactDetails
                    subHeading="Industries"
                    details={
                      newData.industry
                        ? Object.keys(newData).length &&
                          newData.industry.replaceAll(';', ', ')
                        : ''
                    }
                  />
                  <ContactDetails
                    subHeading="Region"
                    details={
                      newData.region
                        ? Object.keys(newData).length &&
                          newData.region.replaceAll(';', ', ')
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="aside-footer">
            <div className="aside-footer-container d-flex justify-content-between">
              <p>{`Contact ${currentIndex + 1} of ${counts}${
                counts > 99 ? '+' : ''
              }`}</p>
              <div>
                <CommonButton
                  customClass="bg-white border-0"
                  icon="previous-arrow"
                  iconColor="#1e1e1e"
                  iconSize={12}
                  variant="secondary"
                  clickHandler={() => this.handlePagination('prev')}
                  isDisabled={currentIndex === 0}
                />
                <CommonButton
                  customClass="bg-white border-0"
                  icon="next-arrow1"
                  iconColor="##1e1e1e"
                  iconSize={12}
                  variant="secondary"
                  clickHandler={() => this.handlePagination('next')}
                  isDisabled={currentIndex === tableData.length - 1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AsideComponent;
