import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import cookies from 'js-cookie';
import { withRouter, Link } from 'react-router-dom';
import Icon from '../shared/icomoon';
import '../../assets/scss/layouts/pages/_createUser.scss';
import '../../assets/scss/layouts/pages/_userdetails.scss';
import * as URL from '../../constants/constants';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import Loader from '../common/loader/Loader';

class UserEditDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      email: '',
      dataEdit: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state.data && location.state.email) {
      this.setState({ data: location.state.data, email: location.state.email });
    } else if (location && location.state.email) {
      this.setState({ email: location.state.email, loading: true });
      const data = {
        email: location.state.email,
      };
      axios
        .post(URL.USER_DATA, data)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status === 200 && res.data.success) {
            this.setState({
              dataEdit: res.data.data,
              email: location.state.email,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ loading: false });
          }
        });
    }
  }

  editUser(ev) {
    const { email } = this.state;
    ev.preventDefault();
    const { history } = this.props;
    history.push({
      pathname: '/update_user',
      state: {
        email,
      },
    });
  }

  render() {
    const { data, dataEdit, loading } = this.state;
    let cookie;
    if (cookies.get('role1') && cookies.get('role')) {
      cookie = '/client';
    } else if (cookies.get('role1')) {
      cookie = '/client';
    } else if (cookies.get('role')) {
      cookie = '/admin';
    }

    return (
      <div>
        {/* <NavBarHeader /> */}
        <GCPrivatePageLayout>
        {loading ? <Loader /> : ''}
        {data
          ? data.map((each) => {
              const d2 = each.last_login_at
                ? each.last_login_at.split(' ')[0].replace(/-/g, '')
                : '';
              return (
                <div className="container CustomWidth userdetails">
                  <Link
                    className="mt-6 back-link d-block"
                    to={cookie}
                  >
                    <Icon
                      icon="back-arrow1"
                      size={12}
                      color="#333"
                    />
                    BACK
                  </Link>
                  <div className="card mb-3 mt-5">
                    <div className="card-header pl-0 mb-5">
                      <h3 className="pageTitle">User Detail</h3>
                    </div>
                    <form>
                      <Row>
                        <Col xs="8">
                          <div className="form-group admin-user-details">
                            <span className='lbl' htmlFor="firstname">First name:</span>
                            <span>
                              {each.first_name ? each.first_name : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="title">Title:</span>
                            <span>
                              {each.company_title ? each.company_title : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="lastname">Last name:</span>
                            <span>{each.last_name ? each.last_name : ''}</span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="company">Company:</span>
                            <span>{each.company ? each.company : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="emailImput">Email:</span>
                            <span>{each.email ? each.email : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="role">Role:</span>
                            <span>{each.role ? each.role : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="status">Status:</span>
                            <span>{each.status ? each.status : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="lastLogin">Last Login:</span>
                            <span>
                              {d2
                                ? `${d2.substring(4, 6)}/${d2.substring(
                                    6,
                                    9
                                  )}/${d2.substring(0, 4)}`
                                : '_'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="buttonActions">
                          <button
                            type="button"
                            className="solidBtn solidBtn__blue"
                            onClick={(ev) => this.editUser(ev)}
                          >
                            Edit User
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              );
            })
          : ''}
        {dataEdit
          ? dataEdit.map((each) => {
              const d2 = each.last_login_at
                ? each.last_login_at.split(' ')[0].replace(/-/g, '')
                : '';
              return (
                <div className="container CustomWidth userdetails">
                  <Link
                    className="mt-6 back-link d-block"
                    to={cookie}
                  >
                    <Icon
                      icon="back-arrow1"
                      size={12}
                      color="#333"
                    />
                    BACK
                  </Link>
                  <div className="card mb-3 mt-5">
                    {/* <div className="card-header pl-0 mb-5">
                      <h3 className="pageTitle">User Details</h3>
                    </div> */}
                    <form>
                      <Row>
                        <Col xs="8">
                          <div className="form-group">
                            <span
                              className="lable"
                              htmlFor="firstname"
                            >
                              First name:
                            </span>
                            <span>
                              {each.first_name ? each.first_name : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="title">Title:</span>
                            <span>
                              {each.company_title ? each.company_title : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="lastname">Last name:</span>
                            <span>{each.last_name ? each.last_name : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="company">Company:</span>
                            <span>{each.company ? each.company : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="emailImput">Email:</span>
                            <span>{each.email ? each.email : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="role">Role:</span>
                            <span>{each.role ? each.role : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="status">Status:</span>
                            <span>{each.status ? each.status : '_'}</span>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div className="form-group">
                            <span htmlFor="lastLogin">Last Login:</span>
                            <span>
                              {d2
                                ? `${d2.substring(4, 6)}/${d2.substring(
                                    6,
                                    9
                                  )}/${d2.substring(0, 4)}`
                                : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="form-group">
                            <span htmlFor="status">Focus Area:</span>
                            <span>
                              {each.focus_area ? each.focus_area : '_'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="buttonActions">
                          <button
                            type="button"
                            className="solidBtn solidBtn__blue"
                            onClick={(ev) => this.editUser(ev)}
                          >
                            Edit User
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              );
            })
          : ''}
          </GCPrivatePageLayout>
      </div>
    );
  }
}
export default withRouter(UserEditDetail);
