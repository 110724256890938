import React, { Component } from 'react';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import cookies from 'js-cookie';
import { Modal } from 'react-bootstrap';
import { Col, Label } from 'reactstrap';
import Icon from '../shared/icomoon';
import Alerts from '../pages/Alerts';
import * as URL from '../../constants/constants';
import Loader from '../common/loader/Loader';

let header;

class GoogleLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: false,
      error: '',
      code: '',
      codeErr: '',
      errorMsg: '',
      email: '',
      username: '',
      role: '',
      movePage401: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.search) {
      const params = new URLSearchParams(location.search).get('code');
      const data = {
        user: {
          code: params,
        },
      };
      // this.setState({ loading: true });
      Axios.post(URL.GOOGLE_LOGIN, data)
        .then((res) => {
          if (res.data.success) {
            cookies.set('tabChange', 'no');
            // this.hideLoader();
            cookies.set('otp', res.data.otp_required_for_login);
            if (res.data.role === 'Admin') {
              cookies.set('loginToken', res.data.auth_token);
              cookies.set('email', res.data.email);
              cookies.set('role', res.data.role);
              this.setState({
                error: '',
                role: res.data.role,
                email: res.data.email,
                username: res.data.username,
              });
              if (res.data.otp_required_for_login) {
                this.setState({ showPop: true });
              } else {
                const { history } = this.props;
                history.push({
                  pathname: '/admin',
                });
              }
            } else if (res.data.role === 'User') {
              cookies.set('loginToken1', res.data.auth_token);
              cookies.set('email1', res.data.email);
              cookies.set('role1', res.data.role);
              localStorage.setItem('initialModal', true);
              this.setState({
                error: '',
                role: res.data.role,
                email: res.data.email,
                username: res.data.username,
              });
              if (res.data.otp_required_for_login) {
                this.setState({ showPop: true });
              } else {
                const { history } = this.props;
                history.push({
                  pathname: '/client',
                });
              }
            }
          } else {
            this.setState({ error: res.data.message, loading: false });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true, loading: false });
          }
        });
    }
  }

  handleAlertClose() {
    this.setState({ alerts: false });
  }

  handleClose() {
    this.setState({
      showPop: false,
      codeErr: '',
      code: '',
      errorMsg: '',
    });
  }

  handleChangeCode(ev) {
    const re = /^[0-9\b]+$/;
    if (ev.target.value === '' || re.test(ev.target.value)) {
      this.setState({ [ev.target.name]: ev.target.value });
    }
  }

  verification() {
    const {
      email, username, role, code,
    } = this.state;
    const isValid = this.validateCode();
    if (isValid) {
      this.setState({ codeErr: '' });
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      const data = {
        otp: code,
      };
      Axios.post(URL.TWO_FACTOR, data, config)
        .then((res) => {
          if (res.status === 200 && res.data.message !== 'Invalid Token') {
            this.setState({ errorMsg: '', showPop: false, code: '' });
            const { history } = this.props;
            if (role === 'Admin') {
              history.push({
                pathname: '/admin',
                state: {
                  role,
                  email,
                  username,
                },
              });
            } else if (role === 'User') {
              history.push({
                pathname: '/client',
                state: {
                  role,
                  email,
                  username,
                },
              });
            }
          } else {
            this.setState({ errorMsg: 'Invalid Token', codeErr: '' });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true, loading: false });
          }
        });
    }
  }

  validateCode() {
    let codeErr = '';
    const { code } = this.state;
    if (!code) {
      codeErr = 'Verification code is required';
    }
    if (codeErr) {
      this.setState({ codeErr, errorMsg: '' });
      return false;
    }
    return true;
  }

  render() {
    const {
      alerts, loading, showPop, error, movePage401,
      code, codeErr, errorMsg, email, username, role,
    } = this.state;
    console.log(email, username, role);
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <div className="loginPage">
        {alerts ? (
          <Alerts
            alertVal={alerts}
            closeAlert={() => this.handleAlertClose()}
          />
        ) : (
          ''
        )}
        {loading ? <Loader /> : ''}
        {error ? <div className="error">{error}</div> : ''}
        {/* <Alert html /> */}
        {showPop ? (
          <Modal
            className="discModal customModal customModal--w500"
            show={showPop}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3">
                  <Col xs="12">
                    <div className="form-group">
                      <Label className="pb-2">Enter Verification code</Label>
                      <input
                        type="text"
                        name="code"
                        className="form-control"
                        id="code"
                        value={code}
                        onChange={(ev) => this.handleChangeCode(ev)}
                      />
                      <div className="error">{codeErr}</div>
                      <div className="error">{errorMsg}</div>
                    </div>
                    <button
                      type="button"
                      className="md-w-40 solidBtn solidBtn__blue"
                      onClick={() => this.verification()}
                    >
                      Verify
                    </button>
                  </Col>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default GoogleLogin;
