/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Badge } from 'reactstrap';

class Badges extends Component {
  constructor(props) {
    super(props);

    this.createBadge = this.createBadge.bind(this);
    this.createRandomColor = this.createRandomColor.bind();
  }

  createBadge(label) {
    switch (label) {
      case 'Introduction to GC':
      case 'Introduced by GC':
        return 'introduced';
      case 'Requested':
        return 'requested';
      case 'Hiring':
      case 'Hired':
        return 'hired';
      default:
        return 'introduced';
    }
  }

  createRandomColor(label) {
    switch (label) {
      case 1:
        return 'introduced';
      case 2:
        return 'requested';
      case 'Hired':
        return 3;
      default:
        return 'introduced';
    }
  }

  render() {
    const { label } = this.props;
    return (
      <Badge
        color={this.createBadge(label)}
      >
        {label}
      </Badge>
    );
  }
}

export default Badges;
