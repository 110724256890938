export const tableHeader = [
  {
    id: 1,
    label: 'Contact',
    value: 'contact',
    val: 'first_name',
  },
  {
    id: 2,
    label: 'Company',
    value: 'company',
    val: 'account_name',
  },
  {
    id: 3,
    label: 'Preffered Role',
    value: 'Preffered Roll',
    val: 'status',
  },
  {
    id: 4,
    label: 'Functions',
    value: 'functions',
    val: 'function',
  },
  {
    id: 5,
    label: 'Previous Companies',
    value: 'previous Companies',
    val: 'previous_companies',
  },
];

export const tableHeaderHistorical =
  window.innerWidth < 768
    ? [
        {
          id: 1,
          label: 'Contact',
          value: 'contact',
          val: 'first_name',
          width: '90%',
        },
      ]
    : [
        {
          id: 1,
          label: 'Contact',
          value: 'contact',
          val: 'first_name',
          width: '18%',
        },
        {
          id: 2,
          label: 'Company',
          value: 'company',
          val: 'account_name',
          width: '20%',
        },
        {
          id: 3,
          label: 'Preferred Role',
          value: 'Preffered Roll',
          val: 'status',
          width: '20%',
        },
        {
          id: 4,
          label: 'Functions',
          value: 'functions',
          val: 'function',
          width: '20%',
        },
        {
          id: 5,
          label: 'Previous Companies',
          value: 'previous Companies',
          val: 'previous_companies',
          width: '22%',
        },
      ];
