import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GCLeftPanel from './GCLeftPanel';
import '../../assets/scss/layouts/pages/_connectForm.scss';

function Layout({ children }) {
  return (
    <div className="contactform">
      <div className="container">
        <Row className="borderRoundForm">
          <Col
            md={{ span: 4 }}
            className="bgdarkForm"
          >
            <GCLeftPanel />
          </Col>
          <Col
            md={{ span: 8 }}
            className="custom-margin"
          >
            {children}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Layout;
