import React, { Component } from 'react';
import {
  Button, FormGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FormLabel from '../common/commonlabel';
import CommonMultiselect from '../common/CommonMultiselect';
import { optionRegion } from '../common/selectlist';

const selectedCountries = [];

export default class locationPreferences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageHeight: 200,
    };
    this.resizeForm = this.resizeForm.bind(this);
  }

  componentDidMount() {
    this.resizeForm();
    window.addEventListener('resize', () => this.resizeForm());
    window.addEventListener('load', () => this.resizeForm());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeForm());
    window.removeEventListener('load', () => this.resizeForm());
  }

  
  handlePrimaryRegion(value) {
const { component}=this.props;
    if (value) {
      component.setState({ primaryRegion: value.value });
      component.setState({ primaryRegionErr: '' });
    }
  }

  resizeForm() {
    // const pageOuterHeight = document.getElementById('outerWrap').offsetHeight;
    const pageOuterHeight = window.innerHeight - 120;
    const pageheadHeight = document?.getElementById('formHead')?.offsetHeight;
    const pageCtaHeight = document?.getElementById('formCta')?.offsetHeight;
    const finalHeight = pageOuterHeight - (pageheadHeight + pageCtaHeight);
    this.setState({ pageHeight: finalHeight });
  }

  continue(ev) {
    const { validateIndustry, validateprimaryRegion, nextStep }=this.props;
    ev.preventDefault();
    validateIndustry();
    const isvalidateprimaryRegion = validateprimaryRegion();
    setTimeout(() => {
      if (isvalidateprimaryRegion) {
        nextStep();
      }
    }, 200);
  }

  previous(ev) {
    const { prevStep }=this.props;
    ev.preventDefault();
    prevStep();
  }


  selectMuptiplee(e) {
    const { formValues, component }=this.props;
    const selectedCountry = e.currentTarget.ariaLabel;
    const index = formValues.countries.indexOf(selectedCountry);
    if (index > -1) {
      formValues.countries.splice(index, 1);
      component.setState({ countries: selectedCountries });
    } else {
      selectedCountries.push(selectedCountry);
      component.setState({ countries: selectedCountries });
      // setFormValues({ ...formValues, countries: selectedCountries })
    }
  }
  

  render() {
    const { formValues } = this.props;
    const { pageHeight } = this.state;
    const { primaryRegionErr } = formValues;
    return (
      <>
        <div
          id="formHead"
          className="LocationPrefernces cneterForm"
        >
          <div id="pageNumber">
            <h4> Step 4 of 5</h4>
          </div>
          <div id="pageHeader">
            <h2> What are your location preferences?</h2>
          </div>
          <hr />
        </div>
        <div className="formHeightCuston">
          <form
            onSubmit={(ev) => this.continue(ev)}
            className="form-horizontal"
          >
            <div className="formMain" style={{ height: pageHeight }}>
            <Scrollbars
                style={{ width: '100%', height: '100%' }}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                horizontal={false}
                className='scrollbarHide'
              >
              <div className="formInputWrap">
                <FormGroup className="form-group-active row">
                  <FormLabel
                    id="lblprimaryregion"
                    htmlFor="primaryregion"
                    text="Current primary location"
                  />
                  <div className="inputValidate col-sm-6">
                    <Select
                      name="primaryRegion"
                      id="pregion"
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      defaultValue={{
                        label: formValues.primaryRegion,
                        value: formValues.primaryRegion,
                      }}
                      onChange={(values) => this.handlePrimaryRegion(values)}
                      options={optionRegion}
                      className="status-box"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '3px',
                        colors: {
                          ...theme.colors,
                          primary25: '#f8f9fa',
                          primary: '#fafafa',
                        },
                      })}
                    />
                     {primaryRegionErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {primaryRegionErr}
                        </div>
                      ) : (
                        ''
                      )}
                  </div>
                </FormGroup>
                <hr />
                <div>
                  <div>
                    <h3> Preferred locations </h3>
                  </div>
                  <div>
                    {' '}
                    Please select any preferences in region for full time
                    opportunities
                  </div>
                  <FormGroup className="form-group-active row">
                    <FormLabel
                      id="region"
                      htmlFor="region"
                      Text="Region"
                    />

                    <CommonMultiselect
                      itemArray={optionRegion}
                      handleClick={(e) => this.selectMuptiplee(e)}
                      compareArray={formValues.countries}
                    />
                  </FormGroup>
                </div>
              </div>
              </Scrollbars>
            </div>
            <div
              id="formCta"
              className="actionButton"
            >
              <div className="submit">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(ev) => this.previous(ev)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
