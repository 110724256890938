  /* eslint-disable no-unused-vars */
  /* eslint-disable react/prefer-stateless-function */
  import React, { Component, useEffect } from 'react';
  import { FormLabel, Modal, Form } from 'react-bootstrap';

  import CommonButton from '../button/CommonButton';
  import { renderContacts } from '../Utilities';
  import Icon from '../../shared/icomoon';
  import SelectedContacts from '../SelectedContacts';

  function RequestIntroModal({
    openModal,
    handleOpenModal,
    handleInputChange,
    desc,
    reqInfoContList,
    submitReqInto,
    addedContactErr,
    otherMsgErr,
    openSuccessModal,
    handleSuccessModal,
    successModalTitle,
    successModalMessage,
  }) {
    console.log('otherMsgErr:', otherMsgErr);
    const [modalName, setModalName] = React.useState(
      openSuccessModal ? 'successModal' : 'selectedContacts'
    );

    useEffect(() => {
      if (openSuccessModal) {
        setModalName('successModal');
      }
    }, [openSuccessModal]);

    useEffect(() => {
      if (openModal) {
        setModalName('selectedContacts');
      }
    }, [openModal]);

    const handleContinue = () => {
      setModalName('requestModal');
    };

    const renderModal = () => {
      switch (modalName) {
        case 'successModal':
          return (
            <ModalSuccess
              successModalMessage={successModalMessage}
              handleSuccessModal={handleSuccessModal}
            />
          );
        case 'selectedContacts':
          return (
            <SelectedContacts
              handleOpenModal={handleOpenModal}
              contacts={reqInfoContList}
              handleContinue={handleContinue}
            />
          );
        default:
          return (
            <RequestModal
              handleOpenModal={handleOpenModal}
              handleInputChange={handleInputChange}
              desc={desc}
              reqInfoContList={reqInfoContList}
              submitReqInto={submitReqInto}
              addedContactErr={addedContactErr}
              otherMsgErr={otherMsgErr}
            />
          );
      }
    };

    const renderModalTitle = () => {
      switch (modalName) {
        case 'successModal':
          return successModalTitle;
        case 'selectedContacts':
          return 'Selected Contacts';
        default:
          return 'Request an Introduction';
      }
    };

    return (
      <Modal
        show={openModal || openSuccessModal}
        onHide={openSuccessModal ? handleSuccessModal : handleOpenModal}
      >
      <Modal.Header closeButton>
        {modalName === 'selectedContacts' ? (
          <Modal.Title>
            <p className="m-0">
              Before requesting an introduction, please review the types of roles these candidates are interested in.
            </p>
          </Modal.Title>
        ) : (
          <Modal.Title>
            <h4 className="m-0">{renderModalTitle()}</h4>
          </Modal.Title>
        )}
      </Modal.Header>
        {renderModal()}
      </Modal>
    );
  }

  function RequestModal({
    handleOpenModal,
    handleInputChange,
    desc,
    reqInfoContList,
    submitReqInto,
    addedContactErr,
    otherMsgErr,
  }) {
    return (
      <>
        <Modal.Body>
          <div>
            <FormLabel>You&#39;re requesting an introduction(s) to</FormLabel>
            <div className="d-flex flex-wrap">
              {reqInfoContList &&
                reqInfoContList.length !== 0 &&
                reqInfoContList.map((item) =>
                  renderContacts(item.name, item.email)
                )}
            </div>
            {addedContactErr ? (
              <span className="error">{addedContactErr}</span>
            ) : (
              ''
            )}
          </div> <br/>
          <Form>
            <Form.Group controlId="requestIntroForm.message">
              <Form.Label>
              What specific information would you like us to share with the candidate about your company, the opportunity, and any recent milestones or achievements?
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={handleInputChange}
                className="rounded-3"
                placeholder="What specific information would you like us to share with the candidate about your company, the opportunity, and any recent milestones or achievements?"
                name="otherMsg"
                defaultValue={desc}
              />
              {otherMsgErr ? <span className="error">{otherMsgErr}</span> : ''}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <CommonButton
            variant="secondary"
            clickHandler={handleOpenModal}
            label="Cancel"
          >
            Close
          </CommonButton>
          <CommonButton
            variant="primary"
            clickHandler={submitReqInto}
            label="Submit"
          >
            Submit
          </CommonButton>
        </Modal.Footer>
      </>
    );
  }

  function ModalSuccess({ successModalMessage, handleSuccessModal }) {
    return (
      <>
        <Modal.Body className="success-body">
          <Icon
            icon="Tickmark"
            color="primary"
            size={30}
          />
          {successModalMessage()}
        </Modal.Body>
        <Modal.Footer>
          <CommonButton
            variant="primary"
            label="Done"
            clickHandler={handleSuccessModal}
          />
        </Modal.Footer>
      </>
    );
  }

  export default RequestIntroModal;
