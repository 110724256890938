/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import cookies from 'js-cookie';
import axios from 'axios';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; // npm i -S cropperjs
import { Row, Col } from 'reactstrap';
import { Modal, Button } from 'react-bootstrap';
import loadImage from 'blueimp-load-image';
import Icon from '../shared/icomoon';
import * as URL from '../../constants/constants';
import Loader from '../common/loader/Loader';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.cropperRef = React.createRef();
    // this.fileInputRef = React.createRef();
    this.state = {
      selectedFile: '',
      email: '',
      loading: false,
      content: '',
      firstName: '',
      lastName: '',
      changePassword: '',
      showProfile: '',
      openImgModal: false,
      file: '',
      imagePreviewUrl: '',
      removeImgState: false,
      showOldImg: '',
      movePage401: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { data, changePasswd, showProfile } = this.props;
    if (data !== prevProps.data) {
      this.setState({
        changePassword: changePasswd,
        showProfile,
        showOldImg: data.map((each) => each.avatar),
        content: data,
        firstName: data.map((each) => each.first_name),
        lastName: data.map((each) => each.last_name),
        email: data.map((each) => each.email),
      });
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  updateDone(ev) {
    ev.preventDefault();
    const { file } = this.state;
    const options = {
      canvas: true,
    };
    if (!file) return;
    loadImage.parseMetaData(file, (data) => {
      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }
      loadImage(
        file,
        (img) => {
          // we will convert image to base64 string so we can sent it as string
          const base64 = img.toDataURL(file.type);
          this.setState({ selectedFile: base64 }, () => {
            this.upload();
          });
        },
        options
      );
    });
  }

  cancelImg() {
    this.setState({ imagePreviewUrl: '', openImgModal: false });
  }

  crossPicture() {
    this.setState({ imagePreviewUrl: '' });
  }

  upload() {
    const { selectedFile } = this.state;
    this.showLoader();
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    axios
      .post(URL.AVATAR, { avatar: selectedFile.split(',')[1] }, config)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            email: res.data.data.email,
            openImgModal: false,
          });
          this.hideLoader();
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true });
        }
      });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  updateProfileImg() {
    this.setState({ openImgModal: true });
  }

  closeImgModal() {
    this.setState({ openImgModal: false });
  }

  removeImg(ev) {
    ev.preventDefault();
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    axios
      .get(URL.REMOVE_PROFILE_IMG, config)
      .then((res) => {
        if (res) {
          this.setState({ removeImgState: false });
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true });
        }
      });
  }

  removeImgModal() {
    this.setState({ removeImgState: true });
  }

  closeRemoveImg() {
    this.setState({ removeImgState: false });
  }

  cancelRemoveImg() {
    this.setState({ removeImgState: false });
  }

  render() {
    const {
      email,
      loading,
      content,
      firstName,
      lastName,
      changePassword,
      openImgModal,
      showProfile,
      movePage401,
      imagePreviewUrl,
      removeImgState,
      showOldImg,
    } = this.state;
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }

    return (
      <div className="userInfoMod  mt-4">
        {loading ? <Loader /> : ''}

        <div className="userEmailInfo">
          <div className="avatar avimg-90">
            <div>
              {showOldImg && showOldImg[0] !== '' ? (
                <img
                  src={showOldImg}
                  alt=""
                  id="img"
                  className="img"
                />
              ) : (
                [
                  content
                    ? content.map((each) => (
                        <span>
                          {each.first_name.substring(1, 0) +
                            each.last_name.substring(1, 0).toUpperCase()}
                        </span>
                      ))
                    : '',
                ]
              )}
            </div>
          </div>
          <div className="userInfo">
            {changePassword || showProfile ? (
              ''
            ) : (
              <button
                type="button"
                className="btn btn-outline-light btn-outline-secondary mb-2 mr-1"
                onClick={(ev) => this.updateProfileImg(ev)}
              >
                {showOldImg && showOldImg[0] ? 'Change' : 'Upload'} Profile
                Picture
              </button>
            )}
            {showOldImg && showOldImg[0] && !changePassword && !showProfile ? (
              <button
                type="button"
                className="btn btn-outline-light btn-outline-secondary mb-2"
                onClick={(ev) => this.removeImgModal(ev)}
              >
                Clear Picture
              </button>
            ) : (
              ''
            )}
            {removeImgState ? (
              <Modal
                className="discModal customModal customModal--w500 widthAuto"
                show={removeImgState}
                animation={false}
              >
                <Icon
                  icon="cross"
                  className="closebuttonCust"
                  onClick={() => this.closeRemoveImg()}
                  size={10}
                  color="#919eab"
                />
                <Modal.Body>
                  <div className="sBlk py-2">
                    <div className="crForm--login mt-3">
                      <h5>Confirm removing profile picture</h5>
                      <p className="mt-2">
                        Are you sure you want to remove profile picture ?
                      </p>
                      <Row className="mt-4">
                        <Col className="buttonActions updateBtn">
                          <button
                            type="button"
                            className="md-w-40 btn btn-outline-primary mr-3"
                            onClick={() => this.cancelRemoveImg()}
                          >
                            Cancel
                          </button>
                          <Button
                            className="w-auto solidBtn solidBtn__blue"
                            onClick={(ev) => this.removeImg(ev)}
                          >
                            Remove profile picture
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            ) : (
              ''
            )}
            <h4>
              {!firstName
                ? ''
                : firstName[0].charAt(0).toUpperCase() +
                  firstName[0].slice(1)}{' '}
              {!lastName
                ? ''
                : lastName[0].charAt(0).toUpperCase() + lastName[0].slice(1)}
            </h4>
            <h5>{!email ? '' : email}</h5>
          </div>
        </div>
        {openImgModal ? (
          <Modal
            className="discModal customModal customModal--w500"
            show={openImgModal}
            animation={false}
            onHide={() => this.closeImgModal()}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.closeImgModal()}
              size={10}
              color="#919eab"
            />
            <div className="modal-hdr d-flex">
              <h5>
                <div className="modal-title h4">Upload an avatar</div>
              </h5>
              <Modal.Header
                closeButton
                className="border-0 "
              />
            </div>

            <Modal.Body>
              <div className="imageUploadWrap">
                {imagePreviewUrl ? (
                  <div className="cropWrap">
                    <div className="removeImage">
                      <Icon
                        icon="cross"
                        onClick={() => this.crossPicture()}
                        size={10}
                        color="#ffffff"
                      />
                    </div>
                    <Cropper
                      className="cropWrap--image"
                      ref={this.cropperRef}
                      src={imagePreviewUrl}
                      aspectRatio={350 / 350}
                      guides={false}
                      background={false}
                    />
                  </div>
                ) : (
                  <div className="file-W-auto">
                    <div className="file-drop dragBrowse">
                      <div className="file-drop-target">
                        <div className="DragDrop d-flex justify-content-center align-items-center">
                          <div className="dropFile">
                            <Icon
                              icon="upload"
                              size={18}
                              color="#919eab"
                            />
                          </div>
                          <div className="dropFile">
                            Drop files to attach, or
                          </div>
                          <div className="btn-Upload-Image Img-Browse-btn">
                            <div className="Choose-text-Hid">
                              <div className="Input-Chosse-btn">
                                <form onSubmit={(ev) => this.updateDone(ev)}>
                                  <input
                                    // ref={this.fileInputRef}
                                    className="fileInput d-none"
                                    id="fileInput"
                                    type="file"
                                    onChange={(e) => this.handleImageChange(e)}
                                  />
                                  <label
                                    className="fontSize browse-Up-btn purple_link"
                                    htmlFor="fileInput"
                                  >
                                    browse
                                  </label>
                                  .
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="buttonActions col justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  onClick={(ev) => this.cancelImg(ev)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={(ev) => this.updateDone(ev)}
                >
                  Update
                </button>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default withRouter(FileUpload);
