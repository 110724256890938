import React from 'react';
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import CommonLabelText from '../common/label/CommonLabelText';
import CommonChecbox from '../common/checkbox/CommonCheckbox';
import CommonLink from '../common/links/CommonLink';
import CommonButton from '../common/button/CommonButton';
import Icon from '../shared/icomoon';
import googleIcon from '../../assets/images/google.svg';

function LoginForm({
  formValues,
  handleChange,
  handleSignIn,
  googleLogin,
  toggleForgot,
  passwdVisible,
}) {
  return (
    <div className="mt-3">
      <Form
        onSubmit={handleSignIn}
        className="form-horizontal"
      >
        <FormGroup className="form-group-active">
          <InputGroup>
            <InputGroupAddon addonType={['prepend']}>
              <InputGroupText>
                <Icon
                  icon="Email"
                  size={20}
                  color="#919eab"
                />
              </InputGroupText>
            </InputGroupAddon>
            <CommonLabelText
              name="email"
              id="email"
              placeholder="Email"
              defaultValue={formValues.email}
              handleChange={handleChange}
            />
          </InputGroup>
          <div className="error">{formValues.emailError}</div>
        </FormGroup>
        <FormGroup className="form-group-active">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon
                  icon="lock"
                  size={20}
                  color="#919eab"
                />
              </InputGroupText>
            </InputGroupAddon>
            <CommonLabelText
              type={formValues.isPasswdShown ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder="Password"
              defaultValue={formValues.password}
              handleChange={handleChange}
            />

            <InputGroupAddon addonType="postpend">
              <InputGroupText>
                <Icon
                  icon={formValues.isPasswdShown ? 'eye' : 'close-eye'}
                  size={18}
                  color="#919eab"
                  onClick={passwdVisible}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <div className="error">{formValues.passwordError}</div>
        </FormGroup>

        <div className="d-flex align-items-center justify-content-between ">
          <CommonChecbox
            name="rememberMe"
            label="Remember Me"
            id="rememberMe"
            checked={formValues.rememberMe}
            handleChange={handleChange}
          />
          <CommonLink
            toggleForgot={toggleForgot}
            text="Forgot your password?"
          />
        </div>

        <FormGroup>
          <CommonButton
            imgSrc={googleIcon}
            variant="google"
            label="Login with Google"
            googleLogin={googleLogin}
            customClass="mx-auto solidBtn solidBtn-hollow solidBtn-hollow__blue d-flex justify-content-center mt-4 btn-mx330"
          />
        </FormGroup>
        <span className="divider d-inline-block w-100 text-center mb-4">
          or
        </span>
        <FormGroup>
          <CommonButton
            variant="submit"
            label="Portfolio Company Login"
            customClass="d-block mx-auto btn-mx280 solidBtn solidBtn__blue btn-primary btn-mx330"
          />
        </FormGroup>
      </Form>
    </div>
  );
}

export default LoginForm;
