import React from 'react';

const aa = ['Silicon Valley', 'New York', 'Pacific Northwest'];

function CommonMultiSelect(props) {
  const { handleClick, itemArray, compareArray = aa } = props;
  return (
    <div className="prefferedLoc">
      {itemArray.map((item) => (
        <button
          aria-label={item.value}
          key={item.value}
          onClick={handleClick}
          type="button"
          className={`btn ${
            compareArray.indexOf(item.value) > -1
              ? 'prefloc-selected'
              : 'btn-outline-primary'
          } mt-2  prefferedLoc-btn`}
        >
          {' '}
          {item.label}{' '}
        </button>
      ))}
    </div>
  );
}
export default CommonMultiSelect;
