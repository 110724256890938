import React from 'react';
import { Label } from 'reactstrap';

function CommonChecbox({ name, id, value, label, handleChange }) {
  return (
    <div className="checkBox mt-2">
      <input
        id={id}
        className="form-check-input"
        name={name}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />

      <Label htmlFor="rememberme">{label}</Label>
    </div>
  );
}

export default CommonChecbox;
