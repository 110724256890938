import React from 'react';
import { Label, Input } from 'reactstrap';

function CommonLabelText({
  type,
  name,
  id,
  value,
  placeholder,
  label,
  handleChange,
  disabled = false,
  dataplacement,
  tooltip,
  title,
  setDefaultValue,
}) {
  return (
    <>
      <Label className="control-label">{label}</Label>
      <Input
        type={type || 'text'}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={handleChange}
        disabled={disabled}
        data-placement={dataplacement}
        data-toggle={tooltip}
        title={title}
        defaultValue={setDefaultValue}
      />
    </>
  );
}

export default CommonLabelText;
