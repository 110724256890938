export default function Utilities(text, type) {
  let newStr;
  let roleString;
  let advisorString;
  let consultantString;
  let boardString;
  // let exploreString;
  let words;
  let matchData;
  let res;
  let convertString;
  let removeUndersocre;
  let seekingLevel;
  let seekingInternship;
  let seekingInvestorRole;
  let seekingMentor;
  switch (type) {
    case 'GET_SELECTED_VAL':
      if (text) {
        newStr = text.replace(/;/g, ' ');
        roleString = newStr.replace(/Free Agent - Operator/g, 'Operating_Roles');
        advisorString = roleString.replace(/Free Agent - Advisor/g, 'Advising_Opps');
        consultantString = advisorString.replace(/Free Agent - Consultant/g, 'Consulting_Opps');
        boardString = consultantString.replace(/Free Agent - Board/g, 'Board_Opps');
        seekingLevel = boardString.replace(/Seeking Entry-Level Job/g, 'Entry-level');
        seekingInternship = seekingLevel.replace(/Seeking Internship/g, 'Internship');
        seekingInvestorRole = seekingInternship.replace(/Free Agent - Investor/g, 'Angel_Investing_Opps');
        seekingMentor = seekingInvestorRole.replace(/Free Agent - Mentor/g, 'Mentor');
        // exploreString = boardString.replace(/Free Agent - Board/g, 'Board_Opps');

        words = seekingMentor.split(' ');
        matchData = ['Operating_Roles', 'Board_Opps', 'Advising_Opps', 'Consulting_Opps', 'Entry-level', 'Internship', 'Angel_Investing_Opps', 'Mentor'];

        res = matchData.filter((v) => words.indexOf(v) > -1); // or array2.includes(v)
        convertString = res.toString().replace(/,/g, ', ');
        removeUndersocre = convertString.replace(/_/g, ' ');
        return removeUndersocre;
      }
      break;
    default:
      return text;
  }
}
