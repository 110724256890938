// const url = 'https://igcdevapi-6r1v.onrender.com';
let url = 'https://networkapi.generalcatalyst.com';
if (
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('onrender')
) {
  url = 'https://igcdevapi-6r1v.onrender.com';
}
export const SIGN_IN = `${url}/api/v1/sign_in`;
export const POPUP_DATA_AFTER_LOGIN = `${url}/api/v1/popup_data_after_login`;
export const FORGOT_PASSWORD = `${url}/api/v1/forgot_password`;
export const UPDATE_PASSWORD = `${url}/api/v1/update_password`;
export const USER_HOME = `${url}/api/v1/all_users`;
export const SEARCH_ADMIN = `${url}/api/v1/search`; // sign_up
export const SEARCH_CLIENT = `${url}/api/v1/salesforce_search`; // sign_up
export const SIGN_UP = `${url}/api/v1/sign_up`;
export const GOOGLE_LOGIN = `${url}/api/v1/google_login`;
export const SALESFORCE = `${url}/api/v1/salesforce`; // get api for already authenticate in salesforce
export const SALESFORCE_CONTACT = `${url}/api/v1/salesforce_contact`;
// export const LOGS = `${url}/api/v1/logs`; // old
export const LOGS = `${url}/api/v1/daily_logs`; // new
export const GENERATE_TOKENS = `${url}/api/v1/generate_tokens`;
export const AUTH_URL = `${url}/api/v1/auth_url`;
export const ACCESS_TOKEN = `${url}/api/v1/access_token`;
export const INTRO_MAIL = `${url}/api/v1/intro_mail`; // contact_data
export const PERSONAL_INFO = `${url}/api/v1/contact_data`;
export const INDEX = `${url}/api/v1/index`;
export const USER_DATA = `${url}/api/v1/user_data`; // profile_setting
export const PROFILE = `${url}/api/v1/profile`; // profile setting
export const AVATAR = `${url}/api/v1/avatar`;
export const REMOVE_PROFILE_IMG = `${url}/api/v1/remove_avatar`; // REMOVE_PROFILE_IMG
export const DELETE_CLIENT = `${url}/api/v1/delete_client`; // delete client
export const UPDATE_CLIENT = `${url}/api/v1/update_client`; //  update_client
export const CHANGE_PASSWORD = `${url}/api/v1/change_password`;
export const FILTER_DROPDOWN = `${url}/api/v1/filter_dropdown`; // filter_dropdown
export const TWO_FACTOR = `${url}/api/v1/two_factor`; // two factor
export const LOG_OUT = `${url}/api/v1/sign_out`; // sign_out
export const RESET_TOKEN = `${url}/api/v1/reset_token`; // reset_token
export const ACCOUNT_SEARCH = `${url}/api/v1/account_search`; // account_search
export const EXPIRE_CHECK = `${url}/api/v1/expire_check`; // EXPIRE CHECK
export const DEACTIVATE = `${url}/api/v1/deactivate`;
export const CHECK_LINK = `${url}/api/v1/check_link`;
export const SEND_MAGIC_LINK = `${url}/api/v1/send_magic_link`; // send_magic_link
export const MAGIC_LOGIN = `${url}/api/v1/magic_login`;
export const LOGIN_AS = `${url}/api/v1/login_as`; // login_as
export const TERMS_AND_CONDITIONS = `${url}/api/v1/terms_and_conditions`; // terms_and_conditions
export const CONTACT_US = `${url}/api/v1/contact_us`;
export const PASSWORD_ISSUE = `${url}/api/v1/password_issue`;
export const SIGN_OUT = `${url}/api/v1/sign_out`;
export const CONTACT_FORM =
  'https://dms.ringlead.com/api/v1/webtolead/6478/1a93150c-f28a-4786-8b96-3ac4f7c5f1dd/submission/';
export const CONTACT_DETAIL = `${url}/api/v1/contact_detail`;
export const ADD_AUDIT_TRAIL = `${url}/api/v1/create_audit`;
export const AUDIT_DETAIL = `${url}/api/v1/index_audit`;
export const GET_AUDIT_BY_ID = `${url}/api/v1/show_audit`;
export const SEARCH_AUDIT = `${url}/api/v1/search_audit`;
export const ADD_FEEDBACK = `${url}/api/v1/feedback`;
export const EXPORT_DATA = `${url}/api/v1/spreadsheet_data`;
export const DELETE_AUDIT_RECORD = `${url}/api/v1/delete_audit_data`; // delete audit trail record
