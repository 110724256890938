/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
import Layout from '../layout/Layout';
import LoginForm from './LoginForm';
import * as URL from '../../constants/constants';
import CommonModal from '../common/Modal/CommonModal';
import Loader from '../common/loader/Loader';

let header;

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      username: '',
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      errorMsg: '',
      isPasswdShown: false,
      error: '',
      showPop: false,
      rememberMe: '',
      alerts: false,
      loading: false,
      movePage401: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.verification = this.verification.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleForgot = this.toggleForgot.bind(this);
    this.passwdVisible = this.passwdVisible.bind(this);
  }

  componentDidMount() {
    console.log(REDIRECT_URI);
    const { history } = this.props;
    if (cookies.get('loginToken') || cookies.get('auth_token')) {
      history.push({ pathname: '/admin' });
    } else if (cookies.get('loginToken1') || cookies.get('auth_token1')) {
      history.push({ pathname: '/client' });
    }
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const email = rememberMe ? localStorage.getItem('user') : '';
    const password = rememberMe ? localStorage.getItem('passwd') : '';
    this.setState({ email, password, rememberMe });
  }

  handleChange(event) {
    const input = event.target;
    console.log('changeeeee', event.target.value);
    console.log('inputinputinput: ', input);
    const value = input.type === 'checkbox' ? input.checked : input.value;
    this.setState({
      [event.target.name]: value,
      emailError: '',
      passwordError: '',
      errorMsg: '',
      error: '',
    });
  }

  handleChangeCode(ev) {
    console.log(ev.target.value);
    const re = /^[0-9\b]+$/;
    if (ev.target.value === '' || re.test(ev.target.value)) {
      this.setState({ [ev.target.name]: ev.target.value });
    }
  }

  handleSignIn(ev) {
    console.log('handleSignInnnnn');
    ev.preventDefault();
    const { email, password, rememberMe } = this.state;
    console.log('email and password', email, password);
    const isValid = this.validate();
    if (isValid) {
      const data = {
        email,
        password,
      };
      this.showLoader();
      axios
        .post(URL.SIGN_IN, data)
        .then((res) => {
          console.log('res.data', res.data);
          if (res.data.success) {
            cookies.set('tabChange', 'no');
            this.hideLoader();
            cookies.set('otp', res.data.otp_required_for_login);
            if (res.data.role === 'Admin') {
              cookies.set('loginToken', res.data.auth_token);
              cookies.set('email', res.data.email);
              cookies.set('role', res.data.role);
              localStorage.setItem('loginName', res.data.username);
              localStorage.setItem('loginFocusArea', res.data.focus_area);
              localStorage.setItem('rememberMe', rememberMe);
              localStorage.setItem('user', rememberMe ? email : '');
              localStorage.setItem('passwd', rememberMe ? password : '');
              this.setState({
                error: '',
                role: res.data.role,
                email: res.data.email,
                username: res.data.username,
              });
              if (res.data.otp_required_for_login) {
                this.setState({ showPop: true });
              } else {
                const { history } = this.props;
                history.push({
                  pathname: '/admin',
                });
              }
            } else if (res.data.role === 'User') {
              cookies.set('loginToken1', res.data.auth_token);
              cookies.set('email1', res.data.email);
              cookies.set('role1', res.data.role);
              localStorage.setItem('rememberMe1', rememberMe);
              localStorage.setItem('user1', rememberMe ? email : '');
              localStorage.setItem('passwd1', rememberMe ? password : '');
              localStorage.setItem('loginName', res.data.username);
              localStorage.setItem('loginFocusArea', res.data.focus_area);
              localStorage.setItem('initialModal', true);
              this.setState({
                error: '',
                role: res.data.role,
                email: res.data.email,
                username: res.data.username,
              });
              if (res.data.otp_required_for_login) {
                this.setState({ showPop: true });
              } else {
                const { history } = this.props;
                history.push({
                  pathname: '/client',
                });
              }
            }
          } else {
            this.setState({ error: res.data.message, loading: false });
          }
          if (res && res.status === 201) {
            console.log('redirect to: ', res.data.redirect_to);
            const { history } = this.props;
            history.push({
              pathname: '/login',
            });
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  handleClose() {
    this.setState({
      showPop: false,
      codeErr: '',
      code: '',
      errorMsg: '',
    });
  }

  toggleForgot() {
    const { history } = this.props;
    history.push({
      pathname: '/forgot_password',
    });
  }

  validate() {
    let emailError = '';
    let passwordError = '';
    const mailRegex =
      /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    const { email, password } = this.state;
    if (!email) {
      emailError = 'Email is required';
    } else if (email && !mailRegex.test(email)) {
      emailError = 'Invalid Email';
    }
    if (!password) {
      passwordError = 'Password is required';
    }
    if (emailError || passwordError) {
      this.setState({ emailError, passwordError });
      return false;
    }
    return true;
  }

  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  googleLogin(ev) {
    ev.preventDefault();
    console.log('CLIENT_ID', CLIENT_ID);
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/auth';
    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', oauth2Endpoint);
    const params = {
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      response_type: 'code',
      scope:
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      include_granted_scopes: 'true',
      state: 'pass-through value',
    };

    for (const p in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'show');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  }

  verification() {
    const { email, username, role, code } = this.state;
    const isValid = this.validateCode();
    if (isValid) {
      this.setState({ codeErr: '' });
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      const data = {
        otp: code,
      };
      axios
        .post(URL.TWO_FACTOR, data, config)
        .then((res) => {
          if (res.status === 200 && res.data.message !== 'Invalid Token') {
            this.setState({ errorMsg: '', showPop: false, code: '' });
            const { history } = this.props;
            if (role === 'Admin') {
              history.push({
                pathname: '/admin',
                state: {
                  role,
                  email,
                  username,
                },
              });
            } else if (role === 'User') {
              history.push({
                pathname: '/client',
                state: {
                  role,
                  email,
                  username,
                },
              });
            }
          } else {
            this.setState({ errorMsg: 'Invalid Token', codeErr: '' });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  passwdVisible() {
    const { isPasswdShown } = this.state;
    this.setState({
      isPasswdShown: !isPasswdShown,
    });
  }

  validateCode() {
    let codeErr = '';
    const { code } = this.state;
    if (!code) {
      codeErr = 'Verification code is required';
    }
    if (codeErr) {
      this.setState({ codeErr, errorMsg: '' });
      return false;
    }
    return true;
  }

  render() {
    const { showPop, loading, error, movePage401 } = this.state;
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <Layout>
        {loading ? <Loader /> : ''}
        <div className="login-wrapper">
          <div>
            <h2 className="login-h2"> Login</h2>
          </div>
          <hr />
          <div className="">
            {error ? <div className="error">{error}</div> : ''}
            <LoginForm
              handleChange={this.handleChange}
              handleSignIn={this.handleSignIn}
              googleLogin={this.googleLogin}
              toggleForgot={this.toggleForgot}
              passwdVisible={this.passwdVisible}
              formValues={this.state}
              component={this}
            />
          </div>
        </div>
        {showPop ? (
          <CommonModal
            handleClose={this.handleClose}
            handleChangeCode={this.handleChangeCode}
            formValues={this.state}
            verification={this.verification}
          />
        ) : (
          ''
        )}
      </Layout>
    );
  }
}

export default Login;
