import React, { Component } from 'react';
import axios from 'axios';
import Alerts from '../pages/Alerts';
import * as URL from '../../constants/constants';

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const urlEmail = match.params[0];
    const data = {
      email: urlEmail,
    };
    axios.post(URL.PASSWORD_ISSUE, data)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ alerts: res.data.message }, () => {
            window.setTimeout(() => {
              this.setState({ alerts: false });
            }, 6000);
          });
        }
      })
      .catch(() => {
      });
  }

  handleAlertClose() {
    this.setState({ alerts: false });
  }

  render() {
    const { alerts } = this.state;
    return (
      <div>
        {
          alerts
            ? (
              <Alerts
                alertVal={alerts}
                closeAlert={() => this.handleAlertClose()}
              />
            )
            : ''
        }
      </div>
    );
  }
}
export default Password;
