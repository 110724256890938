import React from 'react';
import { Button } from 'reactstrap';

function CommonLink({ text, toggleForgot }) {
  return (
    <Button
      color="link"
      onClick={toggleForgot}
    >
      {text}
    </Button>
  );
}

export default CommonLink;
