import React from 'react';

function Alerts({ alertVal, closeAlert }) {
  return (
    <div>
      {alertVal ? (
        <div
          className="alert alert-success alert-dismissible"
          role="alert"
        >
          {alertVal}
          {closeAlert ? (
            <button
              type="button"
              className="close"
              onClick={closeAlert}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
export default Alerts;
