import React, {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import CommonHeaders from '../common/headers/CommonHeaders';
import CommonButton from '../common/button/CommonButton';
import CommonFilters from '../common/filters/CommonFilters';

function GCPrivatePageLayout({
  children,
  clickHandler,
  newUserIcon,
  headerlabel,
  buttonLabel,
  buttonVariant,
  buttonIcon,
  buttonIconColor,
  buttonIconSize,
  handleFeedback,
  selectedFunctionArr,
  selectedIndustryArr,
  selectedStatusArr,
  selectedRegionArr,
  selectedLevelArr,
  selectedExperienceArr,
  toggelFilter,
  setFilterToggle,
  searchValue,
  handleSearchInput,
  clearFilters,
  filterCount,
  activeValue,
  pathName,
  isMobile,
  showAside,
  handleNewest,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [pageTitle, setPageTitle] = useState(headerlabel);
  useEffect(() => {
    let adminPageTitle;
    const loginAs = Cookies.get('loginAs');
    const isUser = Cookies.get('role1');
    const isAdmin = Cookies.get('role');
    const pagePath = window.location.pathname;
    if (isUser === 'User' && (pagePath === '/client' || pagePath === '/')) {
      setShowFilters(true);
    }
    if (isUser === 'User') {
      if (pagePath === '/client') {
        adminPageTitle = 'Contact';
      } else if (pagePath === '/historicalIntro') {
        adminPageTitle = 'Requested Introductions';
      } else if (
        pagePath === '/edit_details' ||
        pagePath === '/personal_info'
      ) {
        adminPageTitle = 'User Details';
      } else if (pagePath === '/edit_user') {
        adminPageTitle = 'Profile Settings';
      } else if (pagePath === '/update_password') {
        adminPageTitle = 'Update Password';
      }
      setPageTitle(adminPageTitle);
    }
    if (isAdmin === 'Admin') {
      if (pagePath === '/' || pagePath === '/admin') {
        adminPageTitle = 'User List';
      } else if (pagePath === '/salesforce') {
        adminPageTitle = 'Salesforce Config';
      } else if (pagePath === '/audit-trail') {
        adminPageTitle = 'Audit Trail List';
      } else if (
        pagePath === '/edit_details' ||
        pagePath === '/personal_info'
      ) {
        adminPageTitle = 'User Details';
      } else if (pagePath === '/update_user') {
        adminPageTitle = 'Edit User Details';
      } else if (pagePath === '/update_password') {
        adminPageTitle = 'Change Password';
      } else if (pagePath === '/edit_user') {
        adminPageTitle = 'Profile Settings';
      } else if (pagePath === '/create_user') {
        adminPageTitle = 'Create User';
      }

      if (loginAs === 'loginAs') {
        if (pagePath === '/client') {
          adminPageTitle = 'Contact';
        } else if (pagePath === '/historicalIntro') {
          adminPageTitle = 'Requested Introductions';
        }
      }
      setPageTitle(adminPageTitle);
    }
  }, []);
  return (
    <div className="radiusContainer my-4">
      <div
        id="InnerRow"
        className="inner-row"
      >
        {showAside && isMobile ? null : (
          <div id="HeaderWrap">
            <CommonHeaders
              handleFeedback={handleFeedback}
              activeValue={activeValue}
              pathName={pathName}
            />
            <div className="d-flex justify-content-between pt-1 hdr-title header-wrapper hdr-brd">
              <h4 className="">{pageTitle}</h4>
              <div className="request-header position-relative d-flex align-items-center">
                <CommonButton
                  clickHandler={clickHandler}
                  variant={buttonVariant}
                  label={buttonLabel}
                  iconSrc={newUserIcon}
                  icon={buttonIcon}
                  iconColor={buttonIconColor}
                  iconSize={buttonIconSize}
                />
                {/* TODO  FOR FUTURE FUNCTONALITY */}
                {/* <img src={threeDots} alt="" /> */}
              </div>
            </div>
            {showFilters && (
              <div className="header-wrapper">
                <CommonFilters
                  selectedFunctionArr={selectedFunctionArr}
                  selectedIndustryArr={selectedIndustryArr}
                  selectedStatusArr={selectedStatusArr}
                  selectedRegionArr={selectedRegionArr}
                  selectedLevelArr={selectedLevelArr}
                  selectedExperienceArr={selectedExperienceArr}
                  toggelFilter={toggelFilter}
                  setFilterToggle={setFilterToggle}
                  searchValue={searchValue}
                  handleSearchInput={handleSearchInput}
                  clearFilters={clearFilters}
                  filterCount={filterCount}
                  handleNewest={handleNewest}
                />
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default GCPrivatePageLayout;
