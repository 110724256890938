/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import cookies from 'js-cookie';
import Icon from '../shared/icomoon';
// import NavBarHeader from '../includes/Header';
import '../../assets/scss/layouts/pages/_home.scss';
import '../../assets/scss/layouts/pages/_createUser.scss';
import * as URL from '../../constants/constants';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import {
  customStylesReactSelect,
  customThemeReactSelect,
} from '../common/Utilities';

const optionStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Deactivated' },
];
const optionRole = [
  { value: 'Admin', label: 'Admin' },
  { value: 'User', label: 'User' },
];

class UpdateUser extends Component {
  constructor(props) {
    super(props);
    // const { val } = this.props;
    this.state = {
      firstName: '',
      firstNames: '', // actually it's last name
      email: '',
      status: '',
      company: '',
      title: '',
      focusArea: '',
      // titleChange: '',
      role: '',
      roleChange: '',
      firstNameErr: '',
      firstNamesErr: '',
      companyErr: '',
      // value: '',
      statusChange: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.email) {
      this.setState({ email: location.state.email });
      const data = {
        email: location.state.email,
      };
      axios
        .post(URL.USER_DATA, data)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              firstName: res.data.data.map((each) => each.first_name),
              firstNames: res.data.data.map((each) => each.last_name),
              company: res.data.data.map((each) => each.company),
              title: res.data.data.map((each) => each.company_title),
              roleChange: res.data.data.map((each) => each.role),
              statusChange: res.data.data.map((each) => each.status),
              focusArea: res.data.data.map((each) =>
                each.focus_area ? each.focus_area : ''
              ),
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      firstNameErr: '',
      firstNamesErr: '',
      companyErr: '',
    });
  }

  handleRole(values) {
    this.setState({ role: values });
  }

  handleUpdate() {
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        firstNameErr: '',
        firstNamesErr: '',
        companyErr: '',
      });
      const {
        firstName,
        firstNames,
        status,
        email,
        company,
        title,
        role,
        statusChange,
        roleChange,
        focusArea,
      } = this.state;
      const data = {
        email,
      };
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      axios
        .post(URL.USER_DATA, data) // update_client
        .then((response) => {
          if (response.data.data) {
            const id = response.data.data.map((each) => each.id);
            const data2 = {
              id: id[0],
              first_name: firstName,
              last_name: firstNames,
              company,
              company_title: title,
              role: role.value ? role.value : roleChange[0],
              email,
              status: status.value ? status.value : statusChange[0],
              focus_area: focusArea,
            };
            axios
              .post(URL.UPDATE_CLIENT, data2, config)
              .then((res) => {
                if (res) {
                  const { history } = this.props;
                  history.push({
                    pathname: '/edit_details',
                    state: {
                      data: res.data.data,
                      email,
                    },
                  });
                }
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  this.setState({ movePage401: true });
                }
              });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  handleChanged(values) {
    this.setState({ status: values }); //  convert to obj
  }

  validate() {
    let firstNameErr = '';
    let firstNamesErr = '';
    let companyErr = '';
    const { firstName, company, firstNames } = this.state;
    if (!firstName) {
      firstNameErr = 'Required';
    }
    if (!firstNames) {
      firstNamesErr = 'Required';
    }
    if (!company) {
      companyErr = 'Required';
    }
    if (firstNameErr || companyErr || firstNamesErr) {
      this.setState({ firstNameErr, companyErr, firstNamesErr });
      return false;
    }
    return true;
  }

  cancel(ev) {
    const { email } = this.state;
    ev.preventDefault();
    const { history } = this.props;
    history.push({
      pathname: '/edit_details',
      state: {
        email,
      },
    });
  }

  render() {
    const {
      firstName,
      firstNames,
      company,
      title,
      focusArea,
      email,
      status,
      statusChange,
      role,
      roleChange,
      firstNameErr,
      companyErr,
      firstNamesErr,
      movePage401,
    } = this.state;
    let cookie;
    if (cookies.get('role1') && cookies.get('role')) {
      cookie = '/client';
    } else if (cookies.get('role1')) {
      cookie = '/client';
    } else if (cookies.get('role')) {
      cookie = '/admin';
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <div>
        {/* <NavBarHeader /> */}
        <GCPrivatePageLayout>
          <div className="content-div">
            <div className="container CustomWidth">
              <Link
                className="mt-6 back-link d-block"
                to={cookie}
              >
                <Icon
                  icon="back-arrow1"
                  size={12}
                  color="#333"
                />
                BACK
              </Link>
              <div className="card mb-3 mt-5 pl-0 mb-4">
                {/* <div className="card-header  pl-0 mb-4">
                <h3 className="pageTitle">EDIT USER DETAILS</h3>
              </div> */}
                <form>
                  <Row>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="firstname">First name</label>
                        <input
                          name="firstName"
                          type="text"
                          className="form-control"
                          id="firstname"
                          value={firstName}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{firstNameErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="firstname">Last name</label>
                        <input
                          name="firstNames"
                          type="text"
                          className="form-control"
                          id="LinkedinProfile"
                          value={firstNames}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{firstNamesErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="company">Company</label>
                        <input
                          name="company"
                          type="text"
                          className="form-control"
                          id="LinkedinProfile"
                          value={company}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{companyErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="nameImput">Title</label>
                        <input
                          name="title"
                          type="text"
                          id="LinkedinProfile"
                          className="form-control"
                          // id="LinkedinProfile"
                          value={title}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        {/* <Select
                        name="title"
                        isSearchable={false}
                        placeholder={titleChange}
                        value={title}
                        onChange={(values) => this.handleTitle(values)}
                        options={optionTitle}
                        className="status-box"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '3px',
                          colors: {
                            ...theme.colors,
                            primary25: '#f8f9fa',
                            primary: '#fafafa',
                          },
                        })}
                      /> */}
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="emailImput">Email</label>
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          id="emailImput"
                          value={email}
                          disabled="disabled"
                          data-placement="right"
                          data-toggle="tooltip"
                          title="Not Editable"
                        />
                      </div>
                    </Col>
                    {/* {
                    cookies.get('email') === email
                      ? (
                        <div>current user can not change own role</div>
                      )
                      : 'other user data can be change'
                  } */}
                    {cookies.get('role') === 'Admin' &&
                    cookies.get('email') !== email ? (
                      <Col xs="6">
                        <div className="form-group">
                          <label htmlFor="nameImput">Role</label>
                          <Select
                            name="role"
                            isSearchable={false}
                            placeholder={roleChange}
                            value={role}
                            onChange={(values) => this.handleRole(values)}
                            options={optionRole}
                            className="status-box"
                            styles={customStylesReactSelect}
                            theme={customThemeReactSelect}
                          />
                        </div>
                      </Col>
                    ) : (
                      [
                        cookies.get('role') === 'User' ||
                        cookies.get('email') === email ? (
                          <Col xs="6">
                            <div className="form-group">
                              <label htmlFor="nameImput">Role</label>
                              <select
                                type="text"
                                name="name"
                                className="form-control"
                                id="nameImput"
                                placeholder="Role"
                                disabled="disabled"
                                data-placement="right"
                                data-toggle="tooltip"
                                title="Not Editable"
                              >
                                <option
                                  value="0"
                                  // disabled="disabled"
                                >
                                  {roleChange}
                                </option>
                              </select>
                            </div>
                          </Col>
                        ) : (
                          ''
                        ),
                      ]
                    )}
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="focusArea">Focus Area</label>
                        <input
                          name="focusArea"
                          type="text"
                          className="form-control"
                          id="focusArea"
                          value={focusArea}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                      </div>
                    </Col>
                    {statusChange[0] !== 'Expired' &&
                    statusChange[0] !== 'Invited' ? (
                      <Col xs="6">
                        <div className="form-group">
                          <label htmlFor="website">Status</label>
                          <Select
                            name="value"
                            isSearchable={false}
                            className="status-box"
                            placeholder={statusChange}
                            value={status}
                            options={optionStatus}
                            onChange={(values) => this.handleChanged(values)}
                            styles={customStylesReactSelect}
                            theme={customThemeReactSelect}
                          />
                        </div>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                  <Row>
                    <Col className="buttonActions updateBtn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.handleUpdate()}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={(ev) => this.cancel(ev)}
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </GCPrivatePageLayout>
      </div>
    );
  }
}
export default UpdateUser;
