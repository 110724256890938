import React from 'react';
import { Button } from 'reactstrap';
import Icon from '../../shared/icomoon';

function CommonButton(props) {
  const {
    variant,
    clickHandler,
    label,
    imgSrc,
    customClass,
    googleLogin,
    type = 'button',
    iconSrc,
    showBadge,
    badge,
    isDisabled,
    icon,
    iconColor = '#000000',
    iconSize = '12',
  } = props;
  if (variant === 'round') {
    return (
      <RoundButton
        label={label}
        clickHandler={clickHandler}
      />
    );
  } else if (variant === 'google') {
    return (
      <GoogleButton
        googleLogin={googleLogin}
        label={label}
        imgSrc={imgSrc}
        customClass={customClass}
      />
    );
  } else if (variant === 'submit') {
    return (
      <SubmitButton
        label={label}
        customClass={customClass}
        clickHandler={clickHandler}
      />
    );
  } else if (variant === 'primary') {
    return (
      <PrimaryButton
        variant={variant}
        label={label}
        customClass={customClass}
        clickHandler={clickHandler}
        type={type}
        iconSrc={iconSrc}
        icon={icon}
        iconColor={iconColor}
        iconSize={iconSize}
        badge={badge}
      />
    );
  } else if (variant === 'secondary') {
    return (
      <SecondaryButton
        variant={variant}
        label={label}
        customClass={customClass}
        clickHandler={clickHandler}
        type={type}
        iconSrc={iconSrc}
        isDisabled={isDisabled}
        showBadge={showBadge}
        icon={icon}
        iconColor={iconColor}
        iconSize={iconSize}
        badge={badge}
      />
    );
  } else {
    return false;
  }
}

export default CommonButton;

function RoundButton({ clickHandler, label }) {
  return <Button onClick={clickHandler}> {label}</Button>;
}

function GoogleButton({ googleLogin, label, imgSrc, customClass }) {
  return (
    <Button
      className={`btn ${customClass}`}
      onClick={googleLogin}
    >
      <img
        src={imgSrc}
        alt="google-icon"
        height="20px"
      />
      {label}
    </Button>
  );
}

function PrimaryButton({
  label,
  clickHandler,
  customClass,
  type,
  variant,
  iconSrc,
  showBadge,
  icon,
  iconColor,
  iconSize,
  badge,
}) {
  return (
    <Button
      color={variant}
      className={customClass}
      onClick={clickHandler}
      type={type}
      name={label}
    >
      {iconSrc && (
        <img
          src={iconSrc}
          alt=""
        />
      )}
      {icon && (
        <Icon
          icon={icon}
          size={iconSize}
          color={iconColor}
        />
      )}
      {label && label}
      {showBadge && (
        <span className="badge badge-dark filter-badge">{badge}</span>
      )}
    </Button>
  );
}

function SecondaryButton({
  label,
  clickHandler,
  customClass,
  type,
  variant,
  iconSrc,
  isDisabled,
  showBadge,
  icon,
  iconColor,
  iconSize,
  badge,
}) {
  return (
    <Button
      color={variant}
      className={customClass}
      onClick={clickHandler}
      type={type}
      outline
      disabled={isDisabled}
      name={label}
    >
      {iconSrc && (
        <img
          src={iconSrc}
          alt=""
        />
      )}
      {icon && (
        <Icon
          icon={icon}
          size={iconSize}
          color={iconColor}
        />
      )}
      {label}
      {showBadge && (
        <span className="badge badge-dark filter-badge">{badge}</span>
      )}
    </Button>
  );
}

function SubmitButton({ label, customClass, clickHandler }) {
  return (
    <Button
      type="submit"
      onClick={clickHandler}
      className={`btn ${customClass}`}
    >
      {' '}
      {label}
    </Button>
  );
}
