import React from 'react';
import SalesforceComp from './Salesforcetab';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';

function SalesForce(props) {
  const { location } = props;
  let roles;
  if (location && location.state && location.state.role) {
    roles = location.state.role;
  }
  return (
    <div>
      {/* <NavBarHeader /> */}
      <GCPrivatePageLayout>
        <div className="">
          <SalesforceComp authRole={roles} />
        </div>
      </GCPrivatePageLayout>
    </div>
  );
}
export default SalesForce;
