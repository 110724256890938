import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import CommonLabelText from '../common/label/CommonLabelText';
import CommonButton from '../common/button/CommonButton';
import Icon from '../shared/icomoon';

function ForgotPasswordForm({ formValues, handleChange, handleForget }) {
  return (
    <div className="mt-3">
      <Form>
        <FormGroup className="form-group-active">
          <InputGroup>
            <InputGroupAddon addonType={['prepend']}>
              <InputGroupText>
                <Icon
                  icon="Email"
                  size={20}
                  color="#919eab"
                />
              </InputGroupText>
            </InputGroupAddon>
            <CommonLabelText
              name="email"
              id="email"
              placeholder="Email"
              autoComplete="off"
              defaultValue={formValues.email}
              handleChange={handleChange}
            />
          </InputGroup>
        </FormGroup>
        <div className="error">{formValues.emailError}</div>

        <FormGroup>
          <CommonButton
            variant="submit"
            label="Submit"
            customClass="d-block mx-auto btn-mx280 solidBtn solidBtn__blue btn-primary btn-mx330"
            clickHandler={handleForget}
          />
        </FormGroup>
      </Form>
      <Row className="align-items-center mt-4 text-center">
        <Col xs="12">
          <span>
            Back to
            <Link
              to="/"
              color="link"
            >
              &nbsp;Login
            </Link>
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPasswordForm;
