import React from 'react';
import Icon from '../shared/icomoon';

function SearchField({ value, onChange, placeholder = '' }) {
  return (
    <div className="position-relative search-header">
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name="searchContact"
        className="rounded-8 search-input"
      />
      <div className="position-absolute input-icon-addon d-flex h-100 top-0">
        <span className="search-icon">
          <Icon
            icon="search"
            size={12}
            color="#666666"
          />
        </span>
      </div>
    </div>
  );
}

export default SearchField;
