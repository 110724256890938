import React from 'react';
import RoundLoader from '../../../assets/images/roundLoader.svg';

function Loader() {
  return (
    <div className="siteLoader rdLoader">
      <div className="rdLoader__inner">
        <img
          src={RoundLoader}
          alt="Loader"
        />
      </div>
    </div>
  );
}

export default Loader;
