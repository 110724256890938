import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {
  renderAdditionalCount,
  renderTooltip,
  generateLinkedInUrl,
} from '../Utilities';
import Badges from '../badges/Badges';
import Icon from '../../shared/icomoon';

function TableBody({
  pathName,
  handleAsideDataShow,
  id,
  tableData,
  getSelectedFilters,
  selectedContactsArr,
}) {
  const [mouseYPosition, setMouseYPosition] = useState(1);

  function getMousePosition(event) {
    const innHeight = window.innerHeight;
    setMouseYPosition(innHeight - event.clientY);
  }

  useEffect(() => {
    window.addEventListener('mousemove', getMousePosition);
    return () => {
      window.removeEventListener('mousemove', getMousePosition);
    };
  }, []);

  return (
    <tbody>
      {tableData &&
        tableData.map((item, index) => {
          const selectedRowIndex = tableData.findIndex(
            (obj) => obj.id === parseInt(id, 10)
          );
          const prefferedRolls =
            item.status &&
            item.status
              .replaceAll('Free Agent - Operator', 'Fulltime Roles')
              .replaceAll('Free Agent - ', '')
              .replaceAll('Ecosystem;', '')
              .replaceAll('Ecosystem', '')
              .split(';');
          const functions = item.function && item.function.split(';');
          const isChecked = selectedContactsArr.some(
            (d) => d.salesforceId === item.cont_id
          );
          return (
            <tr
              key={item.id}
              className={
                selectedRowIndex === index
                  ? 'table-active align-middle'
                  : 'align-middle'
              }
              onClick={() => handleAsideDataShow(item)}
            >
              <td>&nbsp;</td>
              <td className="align-middle">
                <div className="d-flex align-items-center">
                  <input
                    id={item.id}
                    onChange={(e) => getSelectedFilters(e, item)}
                    type="checkbox"
                    name={item.cont_name}
                    checked={isChecked}
                  />
                </div>
              </td>
              <td className="cont-info align-middle">
                <div
                  id={item.id}
                  aria-hidden="true"
                >
                  <div className="d-flex contact-name align-items-center">
                    <h5 className="mb-0">{item.cont_name}</h5>
                    {item.profile && (
                      <span className="ml-1">
                        <a
                          href={generateLinkedInUrl(item.profile)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon
                            icon="Leading-Icon"
                            color="#006699"
                            size={18}
                            className="linkedin-ico"
                          />
                        </a>
                      </span>
                    )}
                  </div>
                  <p>{item.title}</p>
                  <p className="company_name">{item.account_name}</p>
                </div>
              </td>
              <td className="align-middle">
                <div className="d-flex align-items-center">
                  {item.account_name}
                </div>
              </td>
              <td className="align-middle">
                <div className="d-flex align-items-center">
                  {prefferedRolls ? prefferedRolls[0] : ''}
                  {prefferedRolls && prefferedRolls.length > 1 ? (
                    <OverlayTrigger
                      overlay={renderTooltip(prefferedRolls)}
                      placement={mouseYPosition < 220 ? 'top' : 'bottom'}
                    >
                      {renderAdditionalCount(prefferedRolls.length - 1)}
                    </OverlayTrigger>
                  ) : (
                    ''
                  )}
                </div>
              </td>
              <td className="align-middle">
                <div className="d-flex align-items-center">
                  {functions ? functions[0] : ''}
                  {functions && functions.length > 1 ? (
                    <OverlayTrigger
                      overlay={renderTooltip(functions)}
                      placement={mouseYPosition < 220 ? 'top' : 'bottom'}
                    >
                      {renderAdditionalCount(functions.length - 1)}
                    </OverlayTrigger>
                  ) : (
                    ''
                  )}
                </div>
              </td>
              <td className="align-middle">{item.previous_companies}</td>
              {pathName.includes('/historicalIntro') && (
                <td className="align-middle">
                  <Badges
                    label={
                      item.igc_status
                        ? item.igc_status.replaceAll(';', ', ')
                        : ''
                    }
                  />
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
}

export default TableBody;
