import React from 'react';

import { Modal, Table } from 'react-bootstrap';
import CommonButton from './button/CommonButton';

function SelectedContacts({ handleOpenModal, handleContinue, contacts }) {
  return (
    <>
      <Modal.Body>
        <Table className="contact-list-table">
          <thead>
            <tr>
              <th className="align-middle">&nbsp;</th>
              <th className="align-middle">Contact</th>
              <th className="align-middle">Preferred Role</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.salesforceId}>
                <td>&nbsp;</td>
                <td className="align-middle">{contact.name}</td>
                <td className="align-middle">
                  {contact.status
                    ? Object.keys(contact).length &&
                      contact.status
                        .replaceAll('Free Agent - Operator', 'Fulltime Roles')
                        .replaceAll('Free Agent - ', '')
                        .replaceAll('Ecosystem;', '')
                        .replaceAll('Ecosystem', '')
                        .replaceAll(';', ', ')
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <CommonButton
          variant="secondary"
          clickHandler={handleOpenModal}
          label="Cancel"
        >
          Close
        </CommonButton>
        <CommonButton
          variant="primary"
          clickHandler={handleContinue}
          label="Next"
        >
          Submit
        </CommonButton>
      </Modal.Footer>
    </>
  );
}

export default SelectedContacts;
