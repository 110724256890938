import React, { Component } from 'react';
import axios from 'axios';
import cookies from 'js-cookie';
import { withRouter, Redirect } from 'react-router-dom';

import * as URL from '../../../constants/constants';
import DropDownButton from '../dropdownButton/DropDownButton';
import DropDownProfileButton from '../dropdownButton/DropDownProfileButton';
import gcLogo from '../../../assets/images/contact-gc-logo.png';
import { renderAvatar } from '../Utilities';
import Feedbacks from '../Feedback';
import NavBarHeader from '../../includes/Header';
import Loader from '../loader/Loader';
import GeneralCataLogo from '../../../assets/images/GC-header-logo.png';

let header;
class CommonHeaders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      userData: {},
      loading: false,
      openProfDropDown: false,
      setShowAdminFilter: false,
      loginAs: cookies.get('loginAs'),
      redirectLogin: false,
    };
    this.getUserData = this.getUserData.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleProfDropDown = this.handleProfDropDown.bind(this);
    this.handleSetting = this.handleSetting.bind(this);
  }

  componentDidMount() {
    const role = cookies.get('role');
    const pagePath = window.location.pathname;
    if (role === 'Admin') {
      this.setState({
        userRole: role,
      });
    }
    if (role === 'Admin') {
      const showAdminNavbar =
        pagePath === '/' ||
        pagePath === '/admin' ||
        pagePath === '/salesforce' ||
        pagePath === '/audit-trail' ||
        pagePath === '/update_user' ||
        pagePath === '/edit_details' ||
        pagePath === '/create_user';
      if (showAdminNavbar) {
        this.setState({
          setShowAdminFilter: true,
        });
      }
    }
    this.getUserData();
  }

  handleProfDropDown() {
    this.setState((prev) => ({ openProfDropDown: !prev.openProfDropDown }));
  }

  handleSetting() {
    const { history } = this.props;
    history.push({
      pathname: '/personal_info',
    });
  }

  getUserData() {
    const { location } = this.props;
    let loggedInUser;
    if (
      location &&
      location.state &&
      location.state.data &&
      location.state.email
    ) {
      loggedInUser = location.state.email;
      this.setState({ loading: true });
    } else if (cookies.get('email') && cookies.get('email1')) {
      loggedInUser = cookies.get('email1');
    } else if (cookies.get('email1')) {
      loggedInUser = cookies.get('email1');
    } else if (cookies.get('email')) {
      loggedInUser = cookies.get('email');
    }
    const data = { email: loggedInUser };
    axios
      .post(URL.USER_DATA, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.status === 200 && res.data.success) {
          this.setState({
            userData: res.data.data[0],
            email: loggedInUser,
          });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  logOut() {
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    this.setState({ loading: true });
    axios
      .delete(URL.SIGN_OUT, config)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          cookies.remove('loginToken');
          cookies.remove('email');
          cookies.remove('role'); // add at last don't
          cookies.remove('loginToken1');
          cookies.remove('email1');
          cookies.remove('role1'); // add at last don't
          cookies.remove('loginAs');
          cookies.remove('currentPage');
          cookies.remove('currentClientPage');
          cookies.set('tabChange', 'no');
          localStorage.removeItem('acctStatus');
          localStorage.removeItem('industryStatus');
          localStorage.removeItem('stageStatus');
          localStorage.removeItem('regionStatus');
          localStorage.removeItem('levelStatus');

          localStorage.removeItem('account_Status');
          localStorage.removeItem('industry_Status');
          localStorage.removeItem('stage_Status');
          localStorage.removeItem('region_Status');
          localStorage.removeItem('level_Status');
          this.setState({ redirectLogin: true });
          window.location.reload();
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        const apiStatus = err.response.status;
        if (apiStatus === 401 || apiStatus === 500) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  render() {
    const {
      email,
      userData,
      openProfDropDown,
      userRole,
      setShowAdminFilter,
      loginAs,
      loading,
      redirectLogin,
      movePage401,
    } = this.state;
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    if (redirectLogin) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="header-wrapper">
        {loading && <Loader />}
        <div className="d-flex header-div align-items-center">
          <div className="d-flex align-items-center browse-header">
            <img
              className="app-logo"
              src={gcLogo}
              alt=""
            />

            {userRole !== 'Admin' || loginAs ? (
              <DropDownButton
                direction="down"
                label="Browse"
                customClass="drop-butt-trans position-relative"
              />
            ) : null}
          </div>
          {userRole !== 'Admin' || loginAs ? (
            <div className="center-logo">
              <img
                src={GeneralCataLogo}
                alt="logo"
              />
            </div>
          ) : null}
          {userRole !== 'Admin' && <Feedbacks />}
          <DropDownProfileButton
            name={`${userData && userData.first_name} ${
              userData && userData.last_name
            }`}
            email={email}
            openProfDropDown={openProfDropDown}
            handleProfDropDown={this.handleProfDropDown}
            size={32}
            userAvatar={renderAvatar(userData)}
            logOut={this.logOut}
            handleSetting={this.handleSetting}
          />
        </div>
        {setShowAdminFilter && (
          <div className="red">
            <NavBarHeader />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CommonHeaders);
