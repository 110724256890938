/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["clickInfo", "handleNextLast", "addClass", "removeClass", "handleScroll"]
}] */
/* eslint-disable */
import React, { Component, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import {
  Label, FormGroup
} from 'reactstrap';
import Alerts from './Alerts';
import FormLogo from '../../assets/images/gcLogoCenterIcon.png';
import '../../assets/scss/layouts/pages/_connectForm.scss';
import GeneralCataLogo from '../../assets/images/generalCata-logo.png';
import axios from 'axios';
import * as URL from '../../constants/constants';
import PersonalInfo from '../contact/PersonalInfo.jsx';
import LocationPreferences from '../contact/locationPreferences';
import Experience from '../contact/Experience';
import Opportunities from '../contact/Opportunities';
import Complete from '../contact/Complete';
import ContactForm1 from './ContactForm';

export default class MainContactForm extends Component {
  render() {
    return (
      <div className="contactform">
        <div className="container">
          <Row className="borderRoundForm align-items-stretch">
            <Col
              md={{ span: 4 }}
              className="bgdarkForm"
            >
              <div className="Form-leftSide">
                <div className="headingGroup">
                  <h3>Welcome to General Catalyst's Talent Network</h3>
                  <br />
                  <br />
                  <p>
                    In this form you'll have the opportunity to indicate whether
                    you're interested in exploring full-time roles, advisory
                    opportunities or founding a company and if you'd like us to
                    share your information with our founders or keep it private
                    to GC's internal team.
                  </p>
                  <br />
                  <p>
                    After you complete this form, you'll get an email
                    confirmation with a link where you can edit or remove your
                    info at any time.
                    <br /> <br />
                    Questions? Reach out to{' '}
                    <a href="mailto: talent@generalcatalyst.com">
                      talent@generalcatalyst.com
                    </a>
                  </p>
                </div>

                <div className="contactForm-leftlogo">
                  <img
                    src={GeneralCataLogo}
                    alt="logo"
                  />
                </div>
              </div>
            </Col>
            <Col
              md={{ span: 8 }}
              id="outerWrap"
              className="custom-margin"
            >
              <ContactForm1 />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
