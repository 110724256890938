/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import cookies from 'js-cookie';
import { Row, Col } from 'reactstrap';
// import NavBarHeader from '../includes/Header';
import Icon from '../shared/icomoon';
import Alerts from './Alerts';
import '../../assets/scss/layouts/pages/_home.scss';
import '../../assets/scss/layouts/pages/_createUser.scss';
import * as URL from '../../constants/constants';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import {
  customStylesReactSelect,
  customThemeReactSelect,
} from '../common/Utilities';

const optionRole = [
  { value: 'Admin', label: 'Admin' },
  { value: 'User', label: 'User' },
];
class createUserComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      // status: '',
      company: '',
      companyTitle: '',
      email: '',
      role: '',
      password: '',
      firstNameErr: '',
      lastNameErr: '',
      companyErr: '',
      emailErr: '',
      roleErr: '',
      errMsg: '',
      alerts: false,
      movePage401: false,
    };
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      firstNameErr: '',
      lastNameErr: '',
      companyErr: '',
      emailErr: '',
      roleErr: '',
      errMsg: '',
    });
  }

  handleRole(values) {
    this.setState({ role: values });
  }

  handleUser(ev) {
    ev.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        firstNameErr: '',
        lastNameErr: '',
        companyErr: '',
        emailErr: '',
        roleErr: '',
      });
      const {
        firstName,
        lastName,
        company,
        companyTitle,
        email,
        role,
        password,
      } = this.state;
      const data = {
        email,
        first_name: firstName.charAt(0).toUpperCase() + firstName.slice(1),
        last_name: lastName.charAt(0).toUpperCase() + lastName.slice(1),
        company,
        company_title: companyTitle,
        role: role.value,
        password,
      };
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      console.log('data: ', data);
      axios
        .post(URL.SIGN_UP, data, config)
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.message !== 'Email has already been taken'
          ) {
            this.setState(
              { errMsg: '', alerts: 'Successfully created a new user' },
              () => {
                window.setTimeout(() => {
                  this.setState({ alerts: false });
                  const { history } = this.props;
                  history.push({
                    pathname: '/admin',
                    state: {
                      role: 'Admin',
                    },
                  });
                }, 3000);
              }
            );
          } else if (
            res.data.success &&
            res.data.message === 'Email has already been taken'
          ) {
            this.setState({ errMsg: res.data.message, emailErr: '' });
          } else if (res.status === 201) {
            this.setState({ errMsg: res.data.message });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  validate() {
    let firstNameErr = '';
    let lastNameErr = '';
    let companyErr = '';
    let emailErr = '';
    let roleErr = '';
    const { firstName, lastName, company, email, role } = this.state;
    const mailRegex =
      /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    if (!email) {
      emailErr = 'Required';
      this.setState({ emailErr: '' });
    } else if (email && !mailRegex.test(email)) {
      emailErr = 'Invalid Email';
      this.setState({ emailErr: '' });
    }
    if (!firstName) {
      firstNameErr = 'Required';
    }
    if (!lastName) {
      lastNameErr = 'Required';
    }
    if (!company) {
      companyErr = 'Required';
    }
    if (!role.value) {
      roleErr = 'Required';
    }
    if (emailErr || firstNameErr || lastNameErr || companyErr || roleErr) {
      this.setState({
        emailErr,
        firstNameErr,
        lastNameErr,
        companyErr,
        roleErr,
      });
      return false;
    }
    return true;
  }

  cancel() {
    const { history } = this.props;
    this.setState({
      firstName: '',
      lastName: '',
      company: '',
      companyTitle: '',
      email: '',
      role: '',
      firstNameErr: '',
      lastNameErr: '',
      companyErr: '',
      emailErr: '',
      roleErr: '',
    });
    history.push({
      pathname: '/admin',
      state: {
        role: 'Admin',
      },
    });
  }

  render() {
    const {
      firstName,
      lastName,
      company,
      email,
      role,
      roleErr,
      companyTitle,
      alerts,
      firstNameErr,
      lastNameErr,
      companyErr,
      emailErr,
      errMsg,
      movePage401,
    } = this.state;
    let cookie;
    if (cookies.get('role')) {
      cookie = '/admin';
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <div>
        <GCPrivatePageLayout>
          {alerts ? (
            <Alerts
              alertVal={alerts}
              // closeAlert={() => this.handleAlertClose()}
            />
          ) : (
            ''
          )}
          <div className="content-div">
            <div className="container CustomWidth">
              <Link
                className="mt-6 back-link d-block"
                to={cookie}
              >
                <Icon
                  icon="back-arrow1"
                  size={12}
                  color="#333"
                />
                BACK
              </Link>
              <div className="card mb-3 mt-5 pl-0 mb-4">
                {/* <div className="card-header">
                <h3 className="pageTitle">CREATE USER</h3>
              </div> */}
                <form
                  onReset={() => this.cancel()}
                  onSubmit={(ev) => this.handleUser(ev)}
                >
                  <Row>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="firstname">First name</label>
                        <input
                          autoComplete="off"
                          name="firstName"
                          type="text"
                          className="form-control"
                          id="firstname"
                          value={firstName}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{firstNameErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="lastname">Last name</label>
                        <input
                          autoComplete="off"
                          name="lastName"
                          type="text"
                          className="form-control"
                          id="lastname"
                          value={lastName}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{lastNameErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="company">Company</label>
                        <input
                          autoComplete="off"
                          name="company"
                          type="text"
                          className="form-control"
                          id="company"
                          value={company}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{companyErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="lastname">Title</label>
                        <input
                          autoComplete="off"
                          name="companyTitle"
                          type="text"
                          className="form-control"
                          id="companyTitle"
                          value={companyTitle}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="nameImput">Role</label>
                        <Select
                          name="role"
                          isSearchable={false}
                          value={role}
                          onChange={(values) => this.handleRole(values)}
                          options={optionRole}
                          className="status-box"
                          styles={customStylesReactSelect}
                          theme={customThemeReactSelect}
                        />
                        <div className="error">{roleErr}</div>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="form-group">
                        <label htmlFor="emailImput">Email</label>
                        <input
                          name="email"
                          autoComplete="new-email"
                          type="email"
                          className="form-control"
                          id="emailImput"
                          value={email}
                          onChange={(ev) => this.handleChange(ev)}
                        />
                        <div className="error">{emailErr}</div>
                        <div className="error">{errMsg}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="buttonActions updateBtn">
                      <button
                        type="submit"
                        className="btn btn-primary"
                      >
                        Create User
                      </button>
                      <button
                        type="button"
                        onClick={() => this.cancel()}
                        className="btn btn-outline-secondary"
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </GCPrivatePageLayout>
      </div>
    );
  }
}
export default withRouter(createUserComp);
