import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
import { RESET_TOKEN } from '../../constants/constants';

import Loader from '../common/loader/Loader';

class RefreshSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectLogin: false,
      loading: false,
      error: '',
      email: '',
      username: '',
      role: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const urlToken = match.params[0];
    const url = `${RESET_TOKEN}?token=${urlToken}`;
    this.setState({ loading: true });
    axios
      .get(url)
      .then((res) => {
        console.log('Response', res);
        this.setState({ loading: false });
        if (res.status === 200 && res.data.success) {
          cookies.remove('loginToken');
          cookies.remove('email');
          cookies.remove('role'); // add at last don't
          cookies.remove('loginToken1');
          cookies.remove('email1');
          cookies.remove('role1'); // add at last don't
          cookies.remove('loginAs');
          cookies.remove('currentPage');
          cookies.remove('currentClientPage');
          cookies.set('tabChange', 'no');
          // this.setState({ redirectLogin: true });
          this.setState({
            error: '',
            role: res.data.role,
            email: res.data.email,
            username: res.data.username,
          });
          const { history } = this.props;
          // window.location.reload();
          if (res.data.role === 'User') {
            cookies.set('loginToken1', res.data.auth_token);
            cookies.set('email1', res.data.email);
            cookies.set('role1', res.data.role);
            this.setState({
              error: '',
              role: res.data.role,
              email: res.data.email,
              username: res.data.username,
            });
            history.push({
              pathname: '/client',
            });
          } else {
            cookies.set('loginToken', res.data.auth_token);
            cookies.set('email', res.data.email);
            cookies.set('role', res.data.role);
            this.setState({
              error: '',
              role: res.data.role,
              email: res.data.email,
              username: res.data.username,
            });
            history.push({
              pathname: '/admin',
            });
          }
        } else {
          alert(res.data.message);
        }
      })
      .catch(() => {});
  }

  render() {
    const { loading, redirectLogin } = this.state;
    const { error, email, username, role } = this.state;
    console.log(email, username, role, error);
    if (redirectLogin) {
      return <Redirect to="/login" />;
    }
    return <div>{loading ? <Loader /> : ''}</div>;
  }
}
export default RefreshSession;
