/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["clickInfo", "handleNextLast", "addClass", "removeClass", "handleScroll"]
}] */
/* eslint-disable */
import React, { Component } from 'react';
import Layout from '../layout/Layout';
import { withRouter, Link } from 'react-router-dom';
import {
    Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import {
    Card, CardHeader,
} from 'reactstrap';
import axios from 'axios';
import cookies from 'js-cookie';
import NavBarHeader from '../includes/Header';
import PersonalInfo from './PersonalInfo';
import SecurityTable from '../pages/SecurityTab';
import UserInfo from '../pages/FileUpload';
import Icon from '../shared/icomoon';
import * as URL from '../../constants/constants';
import Feedback from '../common/Feedback';
import Feedbacks from '../common/Feedback';
import CommonHeaders from '../common/headers/CommonHeaders';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import Loader from '../common/loader/Loader';
class ProfileSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            content: '',
            loading: false,
            movePage401: false,
        };
    }

    componentDidMount() {
        let mail;
        if (cookies.get('email') && cookies.get('email1')) {
            mail = cookies.get('email1');
        } else if (cookies.get('email1')) {
            mail = cookies.get('email1');
        } else if (cookies.get('email')) {
            mail = cookies.get('email');
        }
        this.showLoader();
        const data = {
            email: mail,
        };
        axios.post(URL.USER_DATA, data)
            .then((res) => {
                if (res) {
                    this.setState({
                        content: res.data.data,
                    });
                    this.hideLoader();
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    this.setState({ movePage401: true, loading: false });
                }
            });
    }

    selectTabs(tabVal) {
        this.setState({
            tabIndex: tabVal,
        }, () => {
            if (tabVal === 0) {
              const { history } = this.props;
              history.push({
                pathname: '/personal_info',
              });
            }
        });
    }

    hideLoader() {
        this.setState({ loading: false });
    }

    showLoader() {
        this.setState({ loading: true });
    }

    editUser(ev) {
        ev.preventDefault();
        const { data } = this.props;
        const { history } = this.props;
        history.push({
          pathname: '/edit_user',
          state: {
            data,
          },
        });
      }

    render() {
        const {
            tabIndex, content, loading, movePage401,
        } = this.state;
        let data;
        let cookie;
        if (content) {
            data = content;
        }
        if (cookies.get('role1') && cookies.get('role')) {
            cookie = '/client';
        } else if (cookies.get('role1')) {
            cookie = '/client';
        } else if (cookies.get('role')) {
            cookie = '/admin';
        }

        if (movePage401) {
            return <Redirect to="/unauthorize" />;
        }

        return (
          <>
            <GCPrivatePageLayout
              headerlabel="Profile Setting"
              clickHandler={(ev) => this.editUser(ev)}
              buttonLabel="Edit Profile"
              buttonVariant="primary"
            >
              <div className="content-div">
                <div
                  className={
                    cookies.get('loginAs') || cookies.get('loginToken1')
                      ? 'clientApp'
                      : ''
                  }
                >
                  {/* <NavBarHeader /> */}
                  <div className="container CustomWidth">
                    {loading ? <Loader /> : ''}
                    <Link
                      className="mt-2 back-link d-block"
                      to={cookie}
                    >
                      <Icon
                        icon="back-arrow1"
                        size={12}
                        color="#333"
                      />
                      BACK
                    </Link>
                    <Tabs
                      activeKey={tabIndex}
                      selectedIndex={tabIndex}
                      onSelect={(tabVal) => this.selectTabs(tabVal)}
                    >
                      <TabList>
                        <Tab>Personal Information</Tab>
                        {cookies.get('loginAs') ? (
                          ''
                        ) : (
                          <Tab>Security Setting</Tab>
                        )}
                      </TabList>
                      <TabPanel>
                        <UserInfo
                          data={data}
                          showProfile="showProfile"
                        />
                        <PersonalInfo data={data} />
                      </TabPanel>
                      {cookies.get('loginAs') ? (
                        ''
                      ) : (
                        <TabPanel>
                          <SecurityTable />
                        </TabPanel>
                      )}
                    </Tabs>
                  </div>
                  {cookies.get('loginAs') || cookies.get('loginToken1') ? (
                    <Feedback />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </GCPrivatePageLayout>
          </>
        );
    }
}

export default withRouter(ProfileSetting);
