import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import CommonButton from '../button/CommonButton';
import Icon from '../../shared/icomoon';

function CollapseFilterList({
  filterList,
  filterHeader,
  getSelectedFilters,
  checkSelected,
  handleShowHideFilters,
  showButton,
}) {
  const [show, setShow] = useState(true);

  const toggle = () => setShow(!show);
  return (
    <div>
      <div id="accordion">
        <div className="card">
          <div
            className="card-header"
            id="headingOne"
          >
            <h5 className="mb-0">
              <button
                type="button"
                className="accordion-button"
                onClick={toggle}
              >
                <div className="d-flex justify-content-between align-items-center">
                  {filterHeader}
                  <Icon
                    icon="downarrow2"
                    size={16}
                    color="#1e1e1e"
                  />
                </div>
              </button>
            </h5>
          </div>

          <Collapse isOpen={show}>
            <div
              id={filterHeader}
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                {filterList &&
                  filterList.map((item) => {
                    const isCheckboxSelected = checkSelected.includes(
                      item.value
                    );
                    return (
                      <div
                        className="d-flex"
                        key={filterHeader + item.value}
                      >
                        <label
                          htmlFor={filterHeader + item.value}
                          className="d-flex"
                        >
                          <input
                            type="checkbox"
                            checked={isCheckboxSelected}
                            className="mr-2"
                            onChange={(e) =>
                              getSelectedFilters(e, filterHeader)
                            }
                            name={item.value}
                            id={filterHeader + item.value}
                          />
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                <CommonButton
                  variant="secondary"
                  label={!showButton ? 'Show More' : 'Show Less'}
                  customClass="border-0"
                  clickHandler={() =>
                    handleShowHideFilters(filterHeader.toLowerCase())
                  }
                />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default CollapseFilterList;
