import React from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { renderUserInfoTab } from '../Utilities';

function DropDownProfileButton({
  email,
  name,
  openProfDropDown,
  handleProfDropDown,
  size,
  userAvatar,
  logOut,
  handleSetting,
}) {
  return (
    <Dropdown
      isOpen={openProfDropDown}
      toggle={handleProfDropDown}
    >
      <DropdownToggle caret>
        {userAvatar && !userAvatar.avatar && (
          <div className="initials">{userAvatar.initials}</div>
        )}

        {userAvatar && userAvatar.avatar && (
          <img
            className="rounded-circle profile-btn"
            src={userAvatar.avatar}
            width={size}
            height={size}
            alt="HH"
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="profile-dropdown">
        <DropdownItem>
          {renderUserInfoTab(email, name, size, userAvatar)}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          className="profile-link"
          onClick={handleSetting}
        >
          Settings
        </DropdownItem>
        <DropdownItem
          className="profile-link"
          onClick={() => logOut()}
        >
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropDownProfileButton;
