/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

import { Table } from 'react-bootstrap';

import TableBody from './TableBody';
import TableHeader from './TableHeader';

import { tableHeader, tableHeaderHistorical } from './TableHeadersData';

class TableWrapper extends Component {
  render() {
    const {
      handleAside,
      handleAsideDataShow,
      id,
      showAside,
      tableData,
      getSelectedFilters,
      selectedContactsArr,
      sortData,
      pathName,
      handleSelectAll,
      sortState,
      isSelectedAll,
    } = this.props;
    return (
      <div className="content-div-new">
        <div id="RenderTbl">
          <div className={showAside ? 'collapse-table' : ''}>
            <Table className="contact-list-table">
              <TableHeader
                tableHeader={
                  pathName.includes('/historicalIntro')
                    ? tableHeader
                    : tableHeaderHistorical
                }
                sortData={sortData}
                handleSelectAll={handleSelectAll}
                tableData={tableData}
                sortState={sortState}
                isSelectedAll={isSelectedAll}
              />
              <TableBody
                showAside={showAside}
                handleAside={handleAside}
                handleAsideDataShow={handleAsideDataShow}
                id={id}
                tableData={tableData}
                getSelectedFilters={getSelectedFilters}
                selectedContactsArr={selectedContactsArr}
                pathName={pathName}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default TableWrapper;
