/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import cookies from 'js-cookie';
import '../../assets/scss/layouts/pages/_home.scss';

import Loader from '../common/loader/Loader';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // redirectOut: false,
      roles: '',
      loading: false,
      isMenuOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    if (cookies.get('role') && cookies.get('role1')) {
      this.setState({ roles: cookies.get('role1') });
    } else if (cookies.get('role1')) {
      this.setState({ roles: cookies.get('role1') });
    } else if (cookies.get('role')) {
      this.setState({ roles: cookies.get('role') });
    }
  }

  toggle() {
    const { isMenuOpen } = this.state;
    this.setState({
      isMenuOpen: !isMenuOpen,
    });
  }

  auditTrail() {
    const { roles } = this.state;
    const { history } = this.props;
    history.push({
      pathname: '/audit-trail',
      state: {
        role: roles,
      },
    });
    cookies.set('tabChange', 'auditTrail');
  }

  salesForce() {
    const { roles } = this.state;
    const { history } = this.props;
    history.push({
      pathname: '/salesforce',
      state: {
        role: roles,
      },
    });
    cookies.set('tabChange', 'salesForce');
  }

  homeFunc() {
    cookies.set('tabChange', 'no');
  }

  render() {
    const { roles, loading, isMenuOpen } = this.state;
    const abc = cookies.get('tabChange');
    return (
      <>
        {loading ? <Loader /> : ''}
        <Navbar
          color="#FFFFFF"
          expand="md"
          light
        >
          <div className=" w-100 text-right toggle-div">
            <NavbarToggler
              onClick={this.toggle}
              className="me-2"
              full
            />
          </div>
          <Collapse
            navbar
            isOpen={isMenuOpen}
          >
            <div className="m-auto menu">
              {roles ? (
                <Nav
                  className="m-auto menu"
                  navbar
                >
                  <NavItem>
                    <NavLink
                      className={
                        abc === 'no' ||
                        window.location.pathname === '/admin' ||
                        window.location.pathname === '/client'
                          ? 'active'
                          : ''
                      }
                      href={
                        cookies.get('role') && cookies.get('role1')
                          ? '/client'
                          : cookies.get('role')
                          ? '/admin'
                          : cookies.get('role1')
                          ? '/client'
                          : '/'
                      }
                      onClick={() => this.homeFunc()}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                  {!cookies.get('role1') ? (
                    <NavItem>
                      <NavLink
                        className={
                          (abc === 'salesForce' ||
                            window.location.pathname === '/salesforce') &&
                          window.location.pathname !== '/admin'
                            ? 'active'
                            : ''
                        }
                        onClick={() => this.salesForce()}
                      >
                        Salesforce
                      </NavLink>
                    </NavItem>
                  ) : (
                    ''
                  )}
                  {!cookies.get('role1') ? (
                    <NavItem>
                      <NavLink
                        className={
                          (abc === 'auditTrail' ||
                            window.location.pathname === '/audit-trail') &&
                          window.location.pathname !== '/admin'
                            ? 'active'
                            : ''
                        }
                        onClick={() => this.auditTrail()}
                      >
                        Audit Trail
                      </NavLink>
                    </NavItem>
                  ) : (
                    ''
                  )}
                </Nav>
              ) : (
                ''
              )}
            </div>
          </Collapse>
        </Navbar>
      </>
    );
  }
}
export default withRouter(Header);
