import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import '../../assets/scss/layouts/pages/_createUser.scss';
import '../../assets/scss/layouts/pages/_userdetails.scss';

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  editUser(ev) {
    ev.preventDefault();
    const { data } = this.props;
    const { history } = this.props;
    history.push({
      pathname: '/edit_user',
      state: {
        data,
      },
    });
  }

  render() {
    const { data } = this.props;
    return (
      <div>
        <div className="butn">
          <Col className="buttonActions updateBtn">
            <button
              type="button"
              className="solidBtn solidBtn__blue"
              onClick={(ev) => this.editUser(ev)}
            >
              Edit Profile
            </button>
          </Col>
        </div>
        <div>
          {data
            ? data.map((each) => {
                const d2 = each.last_login_at
                  ? each.last_login_at.split(' ')[0].replace(/-/g, '')
                  : '';
                return (
                  <div className="container CustomWidth userdetails">
                    <div className="card mb-3 mt-5">
                      <div className="card-header pl-0">
                        <h3 className="pageTitle">
                          {each.role === 'User'
                            ? 'User Information'
                            : 'User Detail'}
                        </h3>
                      </div>
                      <form>
                        <Row>
                          <Col xs="8">
                            <div className="form-group">
                              <span htmlFor="firstname">First name:</span>
                              <span>
                                {each.first_name ? each.first_name : '_'}
                              </span>
                            </div>
                          </Col>

                          <Col xs="4">
                            <div className="form-group">
                              <span htmlFor="company">Company:</span>
                              <span>{each.company ? each.company : '_'}</span>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="form-group">
                              <span htmlFor="lastname">Last name:</span>
                              <span>
                                {each.last_name ? each.last_name : '_'}
                              </span>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div className="form-group">
                              <span htmlFor="title">Title:</span>
                              <span>{each.title ? each.title : '_'}</span>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="form-group">
                              <span htmlFor="emailImput">Email:</span>
                              <span>{each.email ? each.email : '_'}</span>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div className="form-group">
                              <span htmlFor="role">Role:</span>
                              <span>{each.role ? each.role : '_'}</span>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="form-group">
                              <span htmlFor="status">Status:</span>
                              <span>{each.status ? each.status : '_'}</span>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div className="form-group">
                              <span htmlFor="lastLogin">Last Login:</span>
                              <span>
                                {d2
                                  ? `${d2.substring(4, 6)}/${d2.substring(
                                      6,
                                      9
                                    )}/${d2.substring(0, 4)}`
                                  : '_'}
                              </span>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="form-group">
                              <span htmlFor="focusArea">Focus Area:</span>
                              <span>
                                {each.focus_area ? each.focus_area : '_'}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="">
                          <Col
                            xs="12"
                            className="mb-5"
                          >
                            <Link
                              to="/update_password"
                              className="purple_link"
                            >
                              Change Password
                            </Link>
                          </Col>
                        </Row>
                        <Row />
                      </form>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    );
  }
}
export default withRouter(UserDetail);
