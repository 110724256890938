export const developmentKeys = {
  referred: '00N6e00000GrYnH',
  linkedin: '00N5A00000M5BaC',
  interestedIn: '00N5A00000M5BaD',
  experience: '00N6e00000MRdgW',
  expert: '00N5A00000M5BaA', // 00N8Y00000GTsKd
  industryPreferences: '00N5A00000M5BaE',
  industryExperience: '00N8Y00000GTsKd',
  countries: '00N8Y00000GTsKs',
  primaryRegion: '00N5A00000M5BaG',
  stage: '00N05000005v6Gr',
  roles: '00N5A00000M5Ba9',
  ethnicity: '00N6e00000MRTWW',
  gender: '00N6e00000MRTWb',
  previous_company: '00N05000005usQj',
  gc_portfolio: '00N5A00000M5BaF',
  linkedIn_profile_image: '00N05000005ut7q',
  update_news: '00N05000005v6Gw',
  language: '00N0500000E0s9f',
};

export const productionKeys = {
  referred: '00N6e00000GrYnH', // whoseInfo
  linkedin: '00N5A00000M5BaC', // linkedInProfile
  interestedIn: '00N5A00000M5BaD', // applyStatusJoined
  experience: '00N6e00000MRdgW', // experienceStatusJoined
  expert: '00N5A00000M5BaA', // industryexperienceStatusJoined
  industryPreferences: '00N5A00000M5BaE', // industryStatusJoined
  industryExperience: '00N8Y00000GTsKd',
  countries: '00N8Y00000GTsKs', // regionStatusJoined
  primaryRegion: '00N5A00000M5BaG', // primaryregionStatusJoined
  stage: '00N6e00000GrYOl', // supportingCompanyStageJoined
  roles: '00N5A00000M5Ba9', // additionalInfo
  ethnicity: '00N6e00000MRTWW', // ethnicityStatusJoined
  gender: '00N6e00000MRTWb', // genderStatusJoined
  previous_company: '00N8Y00000GTwmb',
  gc_portfolio: '00N5A00000M5BaF',
  linkedIn_profile_image: '00N8Y00000HX1Wd',
  update_news: '00N5A00000M5BaB',
  language: '00NUP000000EjYL',
};

export const developmentSandboxId = { oid: '00D050000008rXn', origin: 'Web' };
export const productinSandboxId = { oid: '00Di0000000faUd', origin: 'Web' };
