import React , { useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import filterIcon from '../../../assets/images/filter-list-black.png';

import { renderFilters, renderTooltip } from '../Utilities';
import CommonButton from '../button/CommonButton';
import SearchField from '../SearchField';

function CommonFilters({
  setFilterToggle,
  searchValue,
  handleSearchInput,
  selectedFunctionArr,
  selectedIndustryArr,
  selectedStatusArr,
  selectedRegionArr,
  selectedLevelArr,
  selectedExperienceArr,
  clearFilters,
  filterCount,
  handleNewest,
}) {
  const [newest, setNewest] = useState(true);
  return (
    <div className="siteFilter d-flex align-items-top col-gap search pt-3">
      <SearchField
        value={searchValue}
        onChange={handleSearchInput}
        placeholder="Search for anything"
      />
      <div>
        <CommonButton
          customClass="rounded-8 border-search pl-4 position-relative filter-btn"
          clickHandler={setFilterToggle}
          label="All filters"
          variant="secondary"
          iconSrc={filterIcon}
          showBadge={true}
          badge={filterCount || null}
        />
      </div>
      <div>
      <CommonButton
          customClass="rounded-8 border-search pl-4 position-relative filter-btn"
          clickHandler={() => {
            setNewest(!newest);
            handleNewest();
          }}
          label={newest ? 'New Contacts' : 'All Contacts'}
          variant="secondary"
        />  
      </div>
      <div
        id="filterGroup"
        className="d-flex flex-wrap col-gap"
      >
        {selectedFunctionArr && selectedFunctionArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedFunctionArr)}
            >
              {renderFilters(
                'Function',
                selectedFunctionArr,
                'selectedFunctionArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedIndustryArr && selectedIndustryArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedIndustryArr)}
            >
              {renderFilters(
                'Preferred Industry',
                selectedIndustryArr,
                'selectedIndustryArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedStatusArr && selectedStatusArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedStatusArr)}
            >
              {renderFilters(
                'Status',
                selectedStatusArr,
                'selectedStatusArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedRegionArr && selectedRegionArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedRegionArr)}
            >
              {renderFilters(
                'Current Region',
                selectedRegionArr,
                'selectedRegionArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedLevelArr && selectedLevelArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedLevelArr)}
            >
              {renderFilters(
                'Level',
                selectedLevelArr,
                'selectedLevelArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
        {selectedExperienceArr && selectedExperienceArr.length > 0 && (
          <div className="border filter-box rounded-8 d-flex align-items-center px-2">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip(selectedExperienceArr)}
            >
              {renderFilters(
                'Industry Experience',
                selectedExperienceArr,
                'selectedExperienceArr',
                clearFilters,
                10
              )}
            </OverlayTrigger>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommonFilters;
