import React from 'react';
import cookies from 'js-cookie';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ component: Cmp }, ...rest) {
  return (
    <Route
      {...rest}
      render={(props) =>
        cookies.get('loginToken') ||
        cookies.get('loginToken1') ||
        cookies.get('auth_token') ||
        cookies.get('auth_token1') ? (
          <Cmp {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
export default ProtectedRoute;
