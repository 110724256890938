/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["clickInfo", "handleNextLast", "addClass", "removeClass", "handleScroll"]
}] */
/* eslint-disable */
import React from 'react';
import GeneralCataLogo from '../../assets/images/generalCata-logo.png';

function GCLeftPanel() {
  return (
    <div className="Form-leftSide">
      <div className="headingGroup">
        <h3>Welcome to General Catalyst's Talent Network</h3>
        <br />
        <br />
        <p>
          GC's talent marketplace where you can find candidates we've recently
          connected with. In the portal, you will be able to view and request
          introductions to free agent operators, board members, and advisors
          within the GC network.
          <br /> <br />
          If you have questions, or if another member of your team needs access
          to IGC, reach out to the GC Talent Team at{' '}
          <a href="mailto: talent@generalcatalyst.com">
            talent@generalcatalyst.com
          </a>
          .
        </p>
      </div>

      <div className="contactForm-leftlogo">
        <img
          src={GeneralCataLogo}
          alt="logo"
        />
      </div>
    </div>
  );
}

export default GCLeftPanel;
