import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
import {
  FormGroup,
  InputGroupAddon,
  Row,
  Col,
  InputGroupText,
  InputGroup,
} from 'reactstrap';

import Alerts from '../pages/Alerts';
import * as URL from '../../constants/constants';
import Icon from '../shared/icomoon';
import CommonLabelText from '../common/label/CommonLabelText';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';

import Loader from '../common/loader/Loader';

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPasswd: '',
      newPasswd: '',
      confirmPasswd: '',
      oldPassErr: '',
      newPassErr: '',
      confirmPassErr: '',
      isPasswdShownOld: '',
      isPasswdShownNew: '',
      isPasswdShownconf: '',
      content: '',
      loading: false,
      showPop: false,
    };
  }

  componentDidMount() {
    let mail;
    if (cookies.get('email') && cookies.get('email1')) {
      mail = cookies.get('email1');
    } else if (cookies.get('email1')) {
      mail = cookies.get('email1');
    } else if (cookies.get('email')) {
      mail = cookies.get('email');
    }
    this.showLoader();
    const data = {
      email: mail,
    };
    this.setState({ loading: true });
    axios
      .post(URL.USER_DATA, data)
      .then((res) => {
        if (res) {
          this.setState({
            content: res.data.data,
          });
          this.hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      oldPassErr: '',
      newPassErr: '',
      confirmPassErr: '',
    });
  }

  handleAlertClose() {
    this.setState({ showPop: false });
  }

  handleAlertPops() {
    this.setState({ showPops: false });
    cookies.remove('loginToken');
    cookies.remove('email');
    cookies.remove('role'); // add at last don't

    cookies.remove('loginToken1');
    cookies.remove('email1');
    cookies.remove('role1'); // add at last don't

    cookies.remove('loginAs');
    cookies.remove('currentPage');
    cookies.remove('currentClientPage');
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  // snyk ignore:start

  validate() {
    let oldPassErr = '';
    let newPassErr = '';
    let confirmPassErr = '';
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/; // /([0-9])/;
    const { oldPasswd, newPasswd, confirmPasswd } = this.state;
    if (!oldPasswd) {
      oldPassErr = 'Old password is required';
    }
    if (!newPasswd) {
      newPassErr = 'Required';
    } else if (newPasswd.length < 3 || newPasswd.length > 15) {
      newPassErr = 'Password should be less than 15 and greater than 2';
    } else if (!passwordRegex.test(newPasswd)) {
      newPassErr = 'Special Character, Number and UpperCase Letter is required';
    }
    if (!confirmPasswd) {
      confirmPassErr = 'Required';
    } else if (newPasswd !== confirmPasswd) {
      confirmPassErr = 'Password should be match';
    }
    if (oldPassErr || newPassErr || confirmPassErr) {
      this.setState({ oldPassErr, newPassErr, confirmPassErr });
      return false;
    }
    return true;
  }

  // snyk ignore:end
  
  passwdVisibleOld() {
    const { isPasswdShownOld } = this.state;
    this.setState({
      isPasswdShownOld: !isPasswdShownOld,
    });
  }

  passwdVisibleNew() {
    const { isPasswdShownNew } = this.state;
    this.setState({
      isPasswdShownNew: !isPasswdShownNew,
    });
  }

  passwdVisibleConf() {
    const { isPasswdShownconf } = this.state;
    this.setState({
      isPasswdShownconf: !isPasswdShownconf,
    });
  }

  updatePasswd() {
    const { oldPasswd, newPasswd, confirmPasswd } = this.state;
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        oldPassErr: '',
        newPassErr: '',
        confirmPassErr: '',
      });
      const data = {
        old_password: oldPasswd,
        new_password: newPasswd,
        confirm_password: confirmPasswd,
      };
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      this.setState({ loading: true });
      axios
        .post(URL.CHANGE_PASSWORD, data, config)
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            this.setState({
              showPops: 'Change password successfully',
              loading: false,
            });
          } else if (
            res.status === 201 &&
            res.data.message === 'Incorrect Old Password'
          ) {
            this.setState(
              { oldPasswd: '', showPop: res.data.message, loading: false },
              () => {
                window.setTimeout(() => {
                  this.setState({ showPop: false });
                }, 6000);
              }
            );
          } else if (res.status === 201) {
            this.setState(
              {
                oldPasswd: '',
                newPasswd: '',
                confirmPasswd: '',
                showPop: res.data.message,
                loading: false,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showPop: false });
                }, 6000);
              }
            );
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true, loading: false });
          }
        });
    }
  }

  cancel() {
    const { history } = this.props;
    history.push({
      pathname: '/personal_info',
    });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  render() {
    const {
      oldPasswd,
      newPasswd,
      confirmPasswd,
      newPassErr,
      confirmPassErr,
      oldPassErr,
      isPasswdShownOld,
      isPasswdShownNew,
      isPasswdShownconf,
      content,
      loading,
      showPop,
      showPops,
      movePage401,
    } = this.state;
    // let data;
    if (content) {
      //  data = content;
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <GCPrivatePageLayout headerlabel="Change Password">
        <div className="content-div">
          <div className="container CustomWidth userdetails changepass m-70">
            {loading ? <Loader /> : ''}
            <form className="form-horizontal w-100">
              <FormGroup>
                <InputGroup>
                  <CommonLabelText
                    label="Old Password"
                    type={isPasswdShownOld ? 'text' : 'password'}
                    name="oldPasswd"
                    id="oldPasswd"
                    value={oldPasswd}
                    handleChange={(ev) => this.handleChange(ev)}
                  />
                  <InputGroupAddon addonType="postpend">
                    <InputGroupText>
                      <Icon
                        icon={isPasswdShownOld ? 'eye' : 'close-eye'}
                        size={18}
                        color="#919eab"
                        onClick={() => this.passwdVisibleOld()}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <div className="error">{oldPassErr}</div>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <CommonLabelText
                    label="New Password"
                    type={isPasswdShownNew ? 'text' : 'password'}
                    name="newPasswd"
                    id="newPasswd"
                    value={newPasswd}
                    handleChange={(ev) => this.handleChange(ev)}
                  />
                  <InputGroupAddon addonType="postpend">
                    <InputGroupText>
                      <Icon
                        icon={isPasswdShownNew ? 'eye' : 'close-eye'}
                        size={18}
                        color="#919eab"
                        onClick={() => this.passwdVisibleNew()}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>

                <div className="error">{newPassErr}</div>
              </FormGroup>

              <FormGroup>
                <InputGroup>
                  <CommonLabelText
                    label="Confirm Password"
                    type={isPasswdShownconf ? 'text' : 'password'}
                    name="confirmPasswd"
                    id="confirmPasswd"
                    value={confirmPasswd}
                    handleChange={(ev) => this.handleChange(ev)}
                  />
                  <InputGroupAddon addonType="postpend">
                    <InputGroupText>
                      <Icon
                        icon={isPasswdShownconf ? 'eye' : 'close-eye'}
                        size={18}
                        color="#919eab"
                        onClick={() => this.passwdVisibleConf()}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <div className="error">{confirmPassErr}</div>
              </FormGroup>

              <Row>
                <Col className="buttonActions">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.updatePasswd()}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary "
                    onClick={() => this.cancel()}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </form>
          </div>

          {showPop ? (
            <Alerts
              alertVal={showPop}
              closeAlert={() => this.handleAlertClose()}
            />
          ) : (
            ''
          )}
          {showPops ? (
            <Alerts
              alertVal={showPops}
              closeAlert={() => this.handleAlertPops()}
            />
          ) : (
            ''
          )}
        </div>
      </GCPrivatePageLayout>
    );
  }
}
export default UpdatePassword;
