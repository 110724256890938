/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Icon from '../../shared/icomoon';

class TableHeader extends Component {
  render() {
    const {
      tableHeader,
      sortData,
      handleSelectAll,
      tableData,
      sortState,
      isSelectedAll,
    } = this.props;
    return (
      <thead>
        <tr
          style={{ position: 'sticky', top: 0, background: '#fff' }}
          className="align-middle"
        >
          <th className="align-middle">&nbsp;</th>
          <th className="align-middle">
            {' '}
            <input
              type="checkbox"
              name="selectAll"
              onChange={(e) => handleSelectAll(e, tableData)}
              checked={isSelectedAll}
            />{' '}
          </th>
          {tableHeader &&
            tableHeader.map((item) => (
              <th
                key={item.id}
                style={{ width: item.width }}
                className="align-middle"
              >
                <span className="text-wrap text-truncate">{item.label}</span>
                {item.value !== 'Status' && (
                  <span
                    onClick={() => sortData(item.value)}
                    aria-hidden="true"
                  >
                    <Icon
                      icon="Union"
                      color="secondary"
                      size={12}
                      className={`${
                        sortState !== item.val ? 'inactive-filter' : ''
                      }`}
                    />
                  </span>
                )}
              </th>
            ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
