/* eslint-disable */
import React, { Component } from 'react';
import { Button, FormGroup, Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Redirect } from 'react-router-dom';
import { Label } from 'reactstrap';
import FormLabel from '../common/commonlabel';
import CommonMultiselect from '../common/CommonMultiselect';
import { ethnicity, gender, languages } from '../common/selectlist';

import {
  developmentKeys,
  productionKeys,
  developmentSandboxId,
  productinSandboxId,
} from '../common/KeyList';

const selectedEthnicity = [];
const selectedlanguage = [];

const { hostname } = window.location;
const sandbox = hostname.includes('network')
  ? productinSandboxId
  : developmentSandboxId;
const keyMap = hostname.includes('network') ? productionKeys : developmentKeys;
export class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      pageHeight: 539,
    };
    this.convertArrToString = this.convertArrToString.bind(this);
    this.updateMultipleKeys = this.updateMultipleKeys.bind(this);
    this.resizeForm = this.resizeForm.bind(this);
  }

  componentDidMount() {
    this.resizeForm();
    window.addEventListener('resize', () => this.resizeForm());
    window.addEventListener('load', () => this.resizeForm());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeForm());
    window.removeEventListener('load', () => this.resizeForm());
  }

  // handleShowPopup(ev) {
  //   ev.preventDefault();
  //   const { component } = this.props;
  //   component.setState({ showPop: false });
  // }

  resizeForm() {
    // const pageOuterHeight = document.getElementById('outerWrap').offsetHeight;
    const pageOuterHeight = window.innerHeight - 120;
    const pageheadHeight = document.getElementById('formHead').offsetHeight;
    const pageCtaHeight = document.getElementById('formCta').offsetHeight;
    const finalHeight = pageOuterHeight - (pageheadHeight + pageCtaHeight);
    this.setState({ pageHeight: finalHeight });
  }

  continue(ev) {
    ev.preventDefault();
    const { component } = this.props;
    component.setState({ showPop: true });
  }

  Cancel(ev) {
    ev.preventDefault();
    const { component } = this.props;
    component.setState({ showPop: false });
  }

  previous(ev) {
    ev.preventDefault();
    const { prevStep } = this.props;
    prevStep();
  }

  /* eslint-disable */
  // TODO need to change the method as per the eslint rule

  updateMultipleKeys(obj, keysMap) {
    if (
      typeof obj !== 'object' ||
      obj === null ||
      Object.keys(obj).length === 0
    ) {
      return;
    }
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        this.updateMultipleKeys(obj[key], keysMap);
      }
      if (keysMap.hasOwnProperty(key)) {
        obj[keyMap[key]] = obj[key];
        delete obj[key];
      }
    }
  }

  convertArrToString(arr) {
    const newStr = arr.toString().replaceAll(',', ';');
    return newStr;
  }

  /* eslint-enable */

  finish(ev) {
    const { formValues } = this.props;
    const {
      countries,
      language,
      // eslint-disable-next-line no-shadow
      ethnicity,
      expert,
      industryExperience,
      interestedIn,
      selectedExperience,
      industryPreferences,
      stage,
      experience,
    } = formValues;
    ev.preventDefault();
    const modifiedObj = formValues;
    console.log('valllllll:', modifiedObj.language);
    modifiedObj.countries = this.convertArrToString(countries);
    modifiedObj.ethnicity = this.convertArrToString(ethnicity);
    modifiedObj.expert = this.convertArrToString(expert);
    modifiedObj.language = this.convertArrToString(language);
    modifiedObj.industryExperience =
      this.convertArrToString(industryExperience);
    modifiedObj.interestedIn = this.convertArrToString(interestedIn);
    modifiedObj.selectedExperience =
      this.convertArrToString(selectedExperience);
    modifiedObj.stage = this.convertArrToString(stage);
    modifiedObj.industryPreferences =
      this.convertArrToString(industryPreferences);
    modifiedObj.experience = this.convertArrToString(experience);
    this.updateMultipleKeys(modifiedObj, keyMap);
    const newobj = { ...sandbox, ...modifiedObj };
    const leadData = new URLSearchParams(newobj);
    const url =
      'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';
    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'text/html, application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: leadData,
    })
      .then((response) => {
        console.log('Lead saved successfully!', response);
        this.setState({ isSubmitted: true });
      })
      .catch((error) => {
        console.error('Error saving lead:', error);
      });
  }

  selectEthnicity(e) {
    const { formValues, component } = this.props;
    const selectethnicity = e.currentTarget.ariaLabel;
    const index = formValues.ethnicity.indexOf(selectethnicity);
    if (index > -1) {
      formValues.ethnicity.splice(index, 1);
      component.setState({ ethnicity: selectedEthnicity });
    } else {
      selectedEthnicity.push(selectethnicity);
      component.setState({ ethnicity: selectedEthnicity });
    }
  }

  selectGender(e) {
    const { component } = this.props;
    const selectedgender = e.currentTarget.ariaLabel;
    component.setState({ gender: selectedgender });
  }

  selectLanguage(e) {
    const { formValues, component } = this.props;
    const selectLanguage = e.currentTarget.ariaLabel;
    const index = formValues.language.indexOf(selectLanguage);
    if (index > -1) {
      formValues.language.splice(index, 1);
      component.setState({ language: selectedlanguage });
    } else {
      selectedlanguage.push(selectLanguage);
      component.setState({ language: selectedlanguage });
    }
  }

  render() {
    const { formValues, handleChange } = this.props;
    const { isSubmitted, pageHeight } = this.state;
    if (isSubmitted) {
      return <Redirect to="/congratulation" />;
    }
    return (
      <>
        <div
          id="formHead"
          className="LocationPrefernces cneterForm"
        >
          <div id="pageNumber">
            <h4> Step 5 of 5</h4>
          </div>
          <div id="pageHeader">
            <h2>Share your background</h2>
          </div>
          <hr />
        </div>
        <div className="formHeightCuston">
          <div
            className="formMain"
            style={{ height: pageHeight }}
          >
            <Scrollbars
              style={{ width: '100%', height: '100%' }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              horizontal={false}
              className="scrollbarHide"
            >
              <div className="formInputWrap">
                <div className="form-horizontal">
                  <FormGroup className="form-group-active">
                    <h3>What is your race?</h3>
                    <FormLabel
                      id="ethnicity"
                      // eslint-disable-next-line react/style-prop-object
                      style="padding: 0 !important; "
                      htmlFor="ethnicity"
                      text="Select all that apply"
                    />
                    <CommonMultiselect
                      itemArray={ethnicity}
                      handleClick={(e) => this.selectEthnicity(e)}
                      compareArray={formValues.ethnicity}
                    />
                  </FormGroup>
                  <hr />
                  <div>
                    <h3> What is your gender? </h3>
                    <FormGroup className="form-group-active">
                      <FormLabel
                        id="lblgender"
                        htmlFor="gender"
                        Text="Select one"
                      />
                      <CommonMultiselect
                        itemArray={gender}
                        handleClick={(e) => this.selectGender(e)}
                        compareArray={formValues.gender}
                      />
                    </FormGroup>
                  </div>
                  <hr />
                  <div>
                    <h3> What language are you fluent in? </h3>
                    <FormGroup className="form-group-active">
                      <FormLabel
                        id="lbllanguage"
                        htmlFor="language"
                        Text="Select multiple"
                      />
                      <CommonMultiselect
                        itemArray={languages}
                        handleClick={(e) => this.selectLanguage(e)}
                        compareArray={formValues.language}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
          <div
            id="formCta"
            className="actionButton"
          >
            <div className="submit">
              <Button
                color="secondary"
                variant="contained"
                onClick={(ev) => this.previous(ev)}
              >
                Previous
              </Button>
              <Button
                variant="primary"
                type="button"
                onClick={(ev) => this.continue(ev)}
              >
                Finish
              </Button>
            </div>
          </div>
        </div>

        {formValues.showPop ? (
          <div className="formHeightCuston">
            <Modal
              show={formValues.showPop}
              className="discModal customModal  customModal--w500 mymodal justify-content-start modal-contactfrom"
              centered={true}
              animation={false}
              onClick={(ev) => this.Cancel(ev)}
            >
              <Modal.Header closeButton>Submit profile</Modal.Header>
              <Modal.Body>
                <div className="container CustomWidth px-0">
                  <div className="card">
                    <div>
                      <p>
                        You are about to submit your information to the
                        GC&apos;s secure database. Please provide optional
                        consent to the options below.
                      </p>
                    </div>
                    <FormGroup className="form-group-active col-sm-12">
                      <div className="checkBox mt-2">
                        <input
                          id="otoggle-on-q13"
                          className="form-check-input"
                          name="gc_portfolio"
                          type="checkbox"
                          checked={formValues.gc_portfolio}
                          onChange={(e) => handleChange(e)}
                        />

                        <Label
                          className="font-weight-bold"
                          htmlFor="toggle-on-q13"
                        >
                          Share my info with GC portfolio companies
                        </Label>
                      </div>
                      <div className="checkBox">
                        <input
                          id="otoggle-on-q14"
                          className="form-check-input"
                          name="linkedIn_profile_image"
                          type="checkbox"
                          checked={formValues.linkedIn_profile_image}
                          onChange={(e) => handleChange(e)}
                        />
                        <Label
                          className="font-weight-bold"
                          htmlFor="otoggle-on-q14"
                        >
                          Use my LinkedIn Profile Image
                        </Label>
                      </div>
                      <div className="checkBox">
                        <input
                          id="otoggle-on-q15"
                          className="form-check-input"
                          name="update_news"
                          type="checkbox"
                          checked={formValues.update_news}
                          onChange={(e) => handleChange(e)}
                        />
                        <Label
                          className="font-weight-bold"
                          htmlFor="otoggle-on-q15"
                        >
                          Sign up to receive news and updates from the GC team
                        </Label>
                      </div>
                    </FormGroup>

                    <div className="actionButton-modal">
                      <div className="submit">
                        <button
                          type="button"
                          className="md-w-90 btn btn-outline-primary mr-3"
                          onClick={(e) => this.Cancel(e)}
                        >
                          Cancel
                        </button>
                        <Button
                          variant="primary"
                          type="button"
                          onClick={(e) => this.finish(e)}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default Confirm;
