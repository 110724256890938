/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import cookies from 'js-cookie';
import { Card, CardHeader, Row, Col } from 'reactstrap';
import UserInfo from './FileUpload';
import NavBarHeader from '../includes/Header';
import '../../assets/scss/layouts/pages/_home.scss';
import '../../assets/scss/layouts/pages/_createUser.scss';
import Loader from '../common/loader/Loader';
import * as URL from '../../constants/constants';

const optionStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Deactivated' },
];
const optionRole = [
  { value: 'Admin', label: 'Admin' },
  { value: 'User', label: 'User' },
];

class UserInfoprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userName: '',
      firstName: '',
      firstNames: '',
      company: '',
      title: '',
      email: '',
      role: '',
      roleChange: '',
      status: '',
      statusChange: '',
      // lastLogin: '',
      firstNameErr: '',
      firstNamesErr: '',
      companyErr: '',
      avt: '',
      content: '',
      movePage401: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.data) {
      this.setState({
        firstName: location.state.data.map((each) => each.first_name),
        firstNames: location.state.data.map((each) => each.last_name),
        company: location.state.data.map((each) => each.company),
        title: location.state.data.map((each) => each.title),
        email: location.state.data.map((each) => each.email),
        roleChange: location.state.data.map((each) => each.role),
        statusChange: location.state.data.map((each) => each.status),
        // lastLogin: location.state.data.map((each) => each.status),
      });
    }
    let mail;
    if (cookies.get('email') && cookies.get('email1')) {
      mail = cookies.get('email1');
    } else if (cookies.get('email1')) {
      mail = cookies.get('email1');
    } else if (cookies.get('email')) {
      mail = cookies.get('email');
    }
    this.showLoader();
    const data = {
      email: mail,
    };
    axios
      .post(URL.USER_DATA, data)
      .then((res) => {
        if (res) {
          this.setState({
            content: res.data.data,
          });
          this.hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      firstNameErr: '',
      firstNamesErr: '',
      companyErr: '',
    });
  }

  handleBtn() {
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        firstNameErr: '',
        firstNamesErr: '',
        companyErr: '',
      });
      const {
        firstName,
        firstNames,
        company,
        role,
        roleChange,
        status,
        avt,
        statusChange,
        title, // lastLogin,
      } = this.state;
      const data = {
        first_name: firstName,
        last_name: firstNames,
        company,
        title,
        // title: title.value ? title.value : titleChange[0],
        role: role.value ? role.value : roleChange[0],
        status: status.value ? status.value : statusChange,
        avatar: avt,
      };
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      this.showLoader();
      axios
        .post(URL.PROFILE, data, config)
        .then((res) => {
          if (res.data.success) {
            this.hideLoader();
            const { history } = this.props;
            history.push({
              pathname: '/personal_info',
            });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }

  handleRole(values) {
    this.setState({ role: values });
  }

  handleStatus(values) {
    this.setState({ status: values }); //  convert to obj
  }

  cancel() {
    const { history } = this.props;
    history.push({
      pathname: '/personal_info',
    });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  validate() {
    let firstNameErr = '';
    let firstNamesErr = '';
    let companyErr = '';
    const { firstName, company, firstNames } = this.state;
    if (!firstName) {
      firstNameErr = 'Required';
    }
    if (!firstNames) {
      firstNamesErr = 'Required';
    }
    if (!company) {
      companyErr = 'Required';
    }
    if (firstNameErr || companyErr || firstNamesErr) {
      this.setState({ firstNameErr, companyErr, firstNamesErr });
      return false;
    }
    return true;
  }

  render() {
    const {
      firstName,
      firstNames,
      company,
      title,
      email,
      role,
      status,
      firstNameErr,
      loading,
      statusChange,
      roleChange,
      content,
      firstNamesErr,
      companyErr,
      movePage401,
    } = this.state;
    let data;
    // let img;
    if (content) {
      data = content;
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <div>
        <NavBarHeader />
        <br />
        <br />
        <div className="container CustomWidth">
          {loading ? <Loader /> : ''}
          <Card className="mt-5">
            <CardHeader className="card-header">
              <h3 className="pageTitle">PROFILE SETTINGS</h3>
            </CardHeader>
          </Card>
          <UserInfo data={data} />
          <div className="card mb-3 mt-5">
            <div className="card-header">
              <h3 className="pageTitle">User Information</h3>
            </div>
            <form>
              <Row>
                <Col xs="6">
                  <div className="form-group">
                    <label htmlFor="firstname">First name</label>
                    <input
                      name="firstName"
                      type="text"
                      className="form-control"
                      id="firstname"
                      value={firstName}
                      onChange={(ev) => this.handleChange(ev)}
                    />
                    <div className="error">{firstNameErr}</div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="form-group">
                    <label htmlFor="firstname">Last name</label>
                    <input
                      name="firstNames"
                      type="text"
                      className="form-control"
                      id="firstnames"
                      value={firstNames}
                      onChange={(ev) => this.handleChange(ev)}
                    />
                    <div className="error">{firstNamesErr}</div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="form-group">
                    <label htmlFor="company">Company</label>
                    <input
                      name="company"
                      type="text"
                      className="form-control"
                      id="LinkedinProfile"
                      value={company}
                      onChange={(ev) => this.handleChange(ev)}
                    />
                    <div className="error">{companyErr}</div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="form-group">
                    <label htmlFor="nameImput">Title</label>
                    <input
                      name="title"
                      type="text"
                      className="form-control"
                      id="title"
                      value={title}
                      onChange={(ev) => this.handleChange(ev)}
                    />
                    {/* <Select
                      name="title"
                      placeholder={titleChange}
                      value={title}
                      onChange={(values) => this.handleTitle(values)}
                      options={optionTitle}
                      className="status-box"
                      theme={theme => ({
                        ...theme,
                        borderRadius: '3px',
                        colors: {
                          ...theme.colors,
                          primary25: '#f8f9fa',
                          primary: '#fafafa',
                        },
                      })}
                    /> */}
                  </div>
                </Col>
                <Col xs="6">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      name="email"
                      type="text"
                      className="form-control"
                      id="email"
                      value={email}
                      disabled="disabled"
                      data-placement="right"
                      data-toggle="tooltip"
                      title="Not Editable"
                      onChange={(ev) => this.handleChange(ev)}
                    />
                  </div>
                </Col>
                {cookies.get('role') === 'Admin' &&
                cookies.get('role1') !== 'User' &&
                cookies.get('email') !== email[0] ? (
                  <Col xs="6">
                    <div className="form-group">
                      <label>Role</label>

                      <Select
                        name="role"
                        placeholder={roleChange}
                        value={role}
                        onChange={(values) => this.handleRole(values)}
                        options={optionRole}
                        className="status-box"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '3px',
                          colors: {
                            ...theme.colors,
                            primary25: '#f8f9fa',
                            primary: '#fafafa',
                          },
                        })}
                      />
                    </div>
                  </Col>
                ) : (
                  [
                    cookies.get('role') === 'User' ||
                    cookies.get('role1') === 'User' ||
                    cookies.get('email') === email[0] ? (
                      <Col xs="6">
                        <div className="form-group">
                          <label htmlFor="nameImput">Role</label>
                          <div className="select-box">
                            <select
                              type="text"
                              name="name"
                              className="form-control"
                              id="nameImput"
                              placeholder="Role"
                              disabled="disabled"
                              data-placement="right"
                              data-toggle="tooltip"
                              title="Not Editable"
                            >
                              <option value="0">{roleChange}</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ''
                    ),
                  ]
                )}
                {cookies.get('role') === 'Admin' &&
                cookies.get('role1') !== 'User' ? (
                  <Col xs="6">
                    <div className="form-group">
                      <label htmlFor="Status">Status</label>
                      <Select
                        name="status"
                        disabled="disabled"
                        className="status-box"
                        placeholder={statusChange}
                        value={status}
                        options={optionStatus}
                        onChange={(values) => this.handleStatus(values)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '3px',
                          colors: {
                            ...theme.colors,
                            primary25: '#f8f9fa',
                            primary: '#fafafa',
                          },
                        })}
                      />
                    </div>
                  </Col>
                ) : (
                  [
                    cookies.get('role') === 'User' ||
                    cookies.get('role1') === 'User' ? (
                      <Col xs="6">
                        <div className="form-group">
                          <label htmlFor="nameImput">Status</label>
                          <div className="select-box">
                            <select
                              type="text"
                              name="name"
                              className="form-control"
                              id="nameImput"
                              placeholder="Role"
                              disabled="disabled"
                              data-placement="right"
                              data-toggle="tooltip"
                              title="Not Editable"
                            >
                              <option value="0">{statusChange}</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ''
                    ),
                  ]
                )}
              </Row>
              <Row>
                <Col className="buttonActions updateBtn mb-5">
                  <button
                    type="button"
                    className="solidBtn solidBtn__blue"
                    onClick={() => this.handleBtn()}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary ml-4"
                    onClick={() => this.cancel()}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default UserInfoprofile;
