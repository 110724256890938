import React from 'react';
import { Label } from 'reactstrap';

function ContactDetails({ subHeading, details, col }) {
  return (
    <div className={`${col === 2 ? 'col-6' : 'col'}`}>
      <Label htmlFor={subHeading}>{subHeading}</Label>
      <div className="form-block">{details}</div>
    </div>
  );
}

export default ContactDetails;
