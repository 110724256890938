import React, { Component } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FormLabel from '../common/commonlabel';

export default class PersonalInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageHeight: 200,
    };
    this.resizeForm = this.resizeForm.bind(this);
  }

  componentDidMount() {
    this.resizeForm();
    window.addEventListener('resize', () => this.resizeForm());
    window.addEventListener('load', () => this.resizeForm());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeForm());
    window.removeEventListener('load', () => this.resizeForm());
  }

  resizeForm() {
    // const pageOuterHeight = document.getElementById('outerWrap').offsetHeight;
    const pageOuterHeight = window.innerHeight - 115;
    const pageheadHeight = document?.getElementById('formHead')?.offsetHeight;
    const pageCtaHeight = document?.getElementById('formCta')?.offsetHeight;
    const finalHeight = pageOuterHeight - (pageheadHeight + pageCtaHeight);
    this.setState({ pageHeight: finalHeight });
  }

  continue(ev) {
    ev.preventDefault();
    const {
      validateCompany,
      validateMail,
      validateName,
      validateLastName,
      validateReferred,
      validateLinkedIn,
      nextStep,
    } = this.props;

    const isFirstNameValid = validateName();
    const isLastNameValid = validateLastName();
    const isEmailValid = validateMail();
    const isCompanyValid = validateCompany();
    const isRefrenceValidated = validateReferred();
    const isLinkedInValidated = validateLinkedIn();
    setTimeout(() => {
      if (
        isFirstNameValid &&
        isLastNameValid &&
        isEmailValid &&
        isCompanyValid &&
        isRefrenceValidated &&
        isLinkedInValidated
      ) {
        nextStep();
      }
    }, 200);
  }

  // back(ev) {
  //   const { prevStep } = this.props;
  //   ev.preventDefault();
  //   prevStep();
  // }

  render() {
    const { values, handleChange } = this.props;
    const { pageHeight } = this.state;
    const { lastNameErr, firstNameErr, emailErr, companyErr, referredErr, linkedInErr } =
      values;
    return (
      <>
        <div
          id="formHead"
          className="PersonalInfo cneterForm"
        >
          <div id="pageNumber">
            <h4> Step 1 of 5</h4>
          </div>
          <div id="pageHeader">
            <h2> Tell us about yourself</h2>
          </div>
          <hr id="pageHr" />
        </div>

        <div className="formHeightCuston">
          <form
            onSubmit={(ev) => this.continue(ev)}
            className="form-horizontal"
          >
            <div
              className="formMain"
              style={{ height: pageHeight }}
            >
              <Scrollbars
                style={{ width: '100%', height: '100%' }} // autoHide autoHideTimeout={1000} autoHideDuration={200}
                horizontal={false}
                className="scrollbarHide"
              >
                <div className="formInputWrap">
                  <FormGroup className="form-group-active row no-gutters">
                    <FormLabel
                      id="lblFirst"
                      htmlFor="firstName"
                      text="First name"
                    />
                    <div className="inputValidate col-sm-6">
                      <input
                        name="first_name"
                        className="form-control "
                        defaultValue={values.first_name}
                        ref={this.firstRef}
                        type="text"
                        placeholder="First name"
                        onChange={(e) => handleChange(e)}
                      />
                      {firstNameErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {firstNameErr}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group-active row no-gutters">
                    <FormLabel
                      id="lbllast"
                      htmlFor="lastName"
                      text="Last name"
                    />
                    <div className="inputValidate col-sm-6">
                      <input
                        name="last_name"
                        className="form-control"
                        defaultValue={values.last_name}
                        type="text"
                        placeholder="Last name"
                        onChange={(e) => handleChange(e)}
                      />
                      {lastNameErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {lastNameErr}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group-active row no-gutters">
                    <FormLabel
                      id="lblcompany"
                      htmlFor="company"
                      text="Most recent company"
                    />
                    <div className="inputValidate col-sm-6">
                      <input
                        name="company"
                        className="form-control"
                        defaultValue={values.company}
                        type="text"
                        placeholder="Most recent company"
                        onChange={(e) => handleChange(e)}
                      />
                      {companyErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {companyErr}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group-active row no-gutters">
                    <FormLabel
                      id="lbltitle"
                      htmlFor="title"
                      text="Most recent title"
                    />
                    <div className="inputValidate col-sm-6">
                      <input
                        name="title"
                        className="form-control"
                        defaultValue={values.title}
                        type="text"
                        placeholder="Most recent title"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </FormGroup>
                  {/* <hr /> */}
                  <div>
                    {/* <div><h3> Contact Info </h3></div> */}
                    <FormGroup className="form-group-active row no-gutters">
                      <FormLabel
                        id="lblemail"
                        htmlFor="email"
                        text="Preferred email"
                      />
                      <div className="inputValidate col-sm-6">
                        <input
                          name="email"
                          className="form-control"
                          defaultValue={values.email}
                          type="text"
                          placeholder="Email"
                          onChange={(e) => handleChange(e)}
                        />
                        {emailErr ? (
                          <div
                            className="error"
                            id="error2"
                          >
                            {emailErr}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group-active row no-gutters">
                      <FormLabel
                        id="lblphoneno"
                        htmlFor="phoneno"
                        text="Preferred phone"
                      />
                      <div className="inputValidate col-sm-6">
                        <input
                          name="phone"
                          className="form-control"
                          defaultValue={values.phone}
                          type="text"
                          placeholder="Phone number"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group-active row no-gutters">
                      <FormLabel
                        id="lbllinkedIn"
                        htmlFor="linkedIn"
                        text="LinkedIn public profile URL"
                      />
                      <div className="inputValidate col-sm-6">
                        <input
                          name="linkedin"
                          className="form-control"
                          value={values.linkedin}
                          type="text"
                          placeholder="linkedin Url"
                          onChange={(e) => handleChange(e)}
                        />
                        {linkedInErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {linkedInErr}
                        </div>
                      ) : (
                        ''
                      )}
                      </div>
                    </FormGroup>
                    {/* <hr /> */}
                    <FormGroup className="form-group-active row no-gutters">
                      <FormLabel
                        id="lblreferral"
                        htmlFor="referral"
                        text="Who referred you to the GC network?"
                      />
                      <div className="inputValidate col-sm-6">
                        <input
                          name="referred"
                          className="form-control"
                          defaultValue={values.referred}
                          type="text"
                          placeholder="Referral"
                          onChange={(e) => handleChange(e)}
                        />
                        {referredErr ? (
                          <div
                            className="error"
                            id="error2"
                          >
                            {referredErr}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div
              id="formCta"
              className="actionButton"
            >
              <div className="submit">
                <Button
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
