import React from 'react';
import { Label } from 'reactstrap';

const commonlabel = ({ label, text }) => (
  <Label
    id={label}
    className="control-label col-sm-6"
  >
    {text}
  </Label>
);

export default commonlabel;
