import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import NavBarHeader from '../includes/Header';
import Utilities from '../Utilities';
import * as URL from '../../constants/constants';
import Icon from '../shared/icomoon';
import CheckCircle from '../../assets/images/check-circle.png';
import Feedback from '../common/Feedback';

class ClientPersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      showOther: false,
      company: '',
      opportunity: '',
      otherMsg: '',
      companyError: '',
      opportunityError: '',
      info: '',
      contactName: '',
      accountName: '',
      movePage401: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state) {
      this.setState({
        info: location.state.info,
        contactName: location.state.info.map((each) => each.cont_name),
        accountName: location.state.info.map((each) => each.account_name),
      });
    }
  }

  handleChange(ev) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  handleRequest(id) {
    this.setState({ showPop: true, id });
  }

  handleClose() {
    this.setState({
      showPop: false,
      company: '',
      companyError: '',
      opportunity: '',
      opportunityError: '',
      otherMsg: '',
    });
  }

  handleCloseOther() {
    this.setState({
      showOther: false,
    });
  }

  handleOtherBtn() {
    this.setState({
      showOther: false,
    });
  }

  validateMail() {
    const { company, opportunity } = this.state;
    let companyError = '';
    let opportunityError = '';
    const spaceRule = /^\s*\s*$/;
    if (!company) {
      companyError = 'Required';
    } else if (spaceRule.test(company)) {
      companyError = 'Required';
    }
    if (!opportunity) {
      opportunityError = 'Required';
    } else if (spaceRule.test(opportunity)) {
      opportunityError = 'Required';
    }
    if (companyError || opportunityError) {
      this.setState({ companyError, opportunityError });
      return false;
    }
    return true;
  }

  sendMail() {
    const isValid = this.validateMail();
    if (isValid) {
      this.setState({ companyError: '', opportunityError: '' });
      const { company, contactName, accountName, opportunity, otherMsg, id } =
        this.state;
      const subject = `Requesting Intro to ${contactName} at ${accountName}`; // Request an intro to {contactName} at {accountName}
      const data = {
        subject,
        intro: otherMsg,
        company,
        opportunity,
        id,
        message: 'Message',
        about_company: company,
        about_opportunity: opportunity,
        other: otherMsg,
        request_type: 'Request Introduction',
        sender: contactName[0],
      };
      console.log('other message: ', accountName, contactName);
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      axios
        .post(URL.INTRO_MAIL, data, config)
        .then((res) => {
          axios.post(URL.ADD_AUDIT_TRAIL, data, config);
          if (res.data.success && res.data.message !== 'Missing Parameter') {
            this.setState({
              showPop: '',
              showOther: true,
              company: '',
              opportunity: '',
              opportunityError: '',
              otherMsg: '',
              id: '',
            }); // showOther: true
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  cancel() {
    this.setState({
      showPop: '',
      company: '',
      companyError: '',
      opportunity: '',
      opportunityError: '',
      otherMsg: '',
    });
  }

  render() {
    const {
      showPop,
      info,
      showOther,
      contactName,
      accountName,
      company,
      companyError,
      opportunity,
      opportunityError,
      otherMsg,
      movePage401,
      id,
    } = this.state;
    let cookie;
    if (cookies.get('role1') && cookies.get('role')) {
      cookie = '/client';
    } else if (cookies.get('role1')) {
      cookie = '/client';
    } else if (cookies.get('role')) {
      cookie = '/admin';
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <div
        className={
          cookies.get('loginAs') || cookies.get('loginToken1')
            ? 'clientApp'
            : ''
        }
      >
        <NavBarHeader />
        <br />
        {info
          ? info.map((each) => {
              const myStr = Utilities(each.status, 'GET_SELECTED_VAL');
              return (
                <div className="container CustomWidth userdetails">
                  <Link
                    className="mt-6 back-link d-block"
                    to={cookie}
                  >
                    <Icon
                      icon="back-arrow1"
                      size={12}
                      color="#333"
                    />
                    BACK
                  </Link>
                  <div className="card mb-3 mt-5">
                    <div className="card-header">
                      <h3 className="pageTitle">Contact Details</h3>
                    </div>
                    <h4 className="pageTitle pb-3">Basic Information</h4>
                    <form>
                      <Row>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="nameImput">Name:</span>
                            <span>
                              {each.first_name
                                ? `${each.first_name} ${each.last_name}`
                                : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="nameImput">Function:</span>
                            <span>
                              {each.function
                                ? each.function.replace(/;/g, ', ')
                                : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="company">Company:</span>
                            <span>
                              {each.account_name ? each.account_name : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="firstname">Current Location:</span>
                            <span>
                              {each.location && each.location !== ','
                                ? each.location
                                : ''}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="linkedinprofile">Title:</span>
                            <span>{each.title ? each.title : '_'}</span>
                          </div>
                        </Col>
                        {/* <Col xs="12" md="6">
                            <div className="form-group">
                              <span htmlFor="nameImput">Last name:</span>
                              <span>{each.last_name ? each.last_name : '_'}</span>
                            </div>
                          </Col> */}
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="firstname">Profile Link:</span>
                            <span>
                              <a
                                href={each.profile}
                                className="purple_link"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {each.profile ? each.profile : '_'}
                              </a>
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span htmlFor="previouscompany">
                              Previous Company:
                            </span>
                            <span>
                              {each.previous_companies
                                ? each.previous_companies
                                : ''}
                            </span>
                          </div>
                        </Col>
                        <div className="card-header w-100 p-3">
                          <h3 className="pageTitle">Preferences</h3>
                        </div>

                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span>Industry:</span>
                            <span>
                              {each.industry
                                ? each.industry.replace(/;/g, ', ')
                                : '_'}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                        >
                          <div className="form-group">
                            <span>Region:</span>
                            <span>
                              {each.region
                                ? each.region.replace(/;/g, ', ')
                                : '_'}
                            </span>
                          </div>
                        </Col>
                        <div className="card-header w-100 p-3">
                          <h3 className="pageTitle">Additional Information</h3>
                        </div>
                        <Col
                          xs="12"
                          md="9"
                        >
                          <div className="form-group noteText">
                            <span>Notes:</span>
                            <span>{each.notes ? each.notes : '_'}</span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="9"
                        >
                          <div className="form-group noteText">
                            <span>GC POC:</span>
                            <span>{each.gc_poc}</span>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          md="9"
                        >
                          <div className="form-group noteText">
                            <span>Open to:</span>
                            <span>{myStr || '_'}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="buttonActions mt-4">
                          <button
                            type="button"
                            className="solidBtn solidBtn__blue w-auto"
                            onClick={() => this.handleRequest(each.id)}
                          >
                            Request Introduction
                          </button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              );
            })
          : ''}
        <br />
        {showPop ? (
          <Modal
            className="discModal customModal customModal--w500 justify-content-start"
            show={showPop}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3">
                  <Form>
                    <FormGroup>
                      <Label className="modal-label mb-4 mt-2">
                        <span className="fontS12">To: </span>
                        <span className="fixedmail">
                          talent@generalcatalyst.com
                        </span>
                      </Label>
                      <Label className="modal-title d-block">
                        <span>Subject:</span>
                        Requesting Intro to {contactName} at {accountName}
                      </Label>
                      <Label className="modal-title d-block">
                        <span>About Your Company:</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="company"
                        value={company}
                        onChange={(ev) => this.handleChange(ev)}
                        autoComplete="off"
                      />
                      <div className="error">{companyError}</div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="modal-title d-block">
                        <span>About Your Opportunity:</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="opportunity"
                        value={opportunity}
                        onChange={(ev) => this.handleChange(ev)}
                        autoComplete="off"
                      />
                      <div className="error">{opportunityError}</div>
                    </FormGroup>
                    <FormGroup>
                      <Label className="modal-title d-block">
                        <span>Other:</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="otherMsg"
                        value={otherMsg}
                        onChange={(ev) => this.handleChange(ev)}
                        autoComplete="off"
                      />
                    </FormGroup>
                    <Row>
                      <Col className="buttonActions updateBtn">
                        <button
                          type="button"
                          className="md-w-40 btn btn-outline-primary mr-3"
                          onClick={() => this.cancel()}
                        >
                          Cancel
                        </button>
                        <Button
                          className="md-w-40 solidBtn solidBtn__blue"
                          onClick={() => this.sendMail(id)}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        {showOther ? (
          <Modal
            className="discModal customModal customModal--w500 thanksModal"
            show={showOther}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleCloseOther()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3 text-center">
                  <img
                    src={CheckCircle}
                    alt="CheckCircle"
                  />
                  <h2>
                    Thanks for requesting
                    <br />
                    an intro
                  </h2>
                </div>
                <Row>
                  <Col className="buttonActions">
                    <Button
                      type="button"
                      className="m-auto w-auto solidBtn solidBtn__blue pl-4 pr-4"
                      onClick={() => this.handleOtherBtn()}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        <Feedback />
      </div>
    );
  }
}
export default ClientPersonalInfo;
