/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import axios from 'axios';
import Layout from '../layout/Layout';
import ForgotPasswordForm from './ForgotPasswordForm';
import Alerts from '../pages/Alerts';
import '../../assets/scss/layouts/pages/_forgotpass.scss';
import * as URL from '../../constants/constants';
import Loader from '../common/loader/Loader';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      alerts: false,
      errorMsg: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleForget = this.handleForget.bind(this);
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }

  handleForget(ev) {
    const { email } = this.state;
    ev.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        emailError: '',
      });
      const data = {
        email,
      };
      axios
        .post(URL.FORGOT_PASSWORD, data)
        .then((res) => {
          if (res.data.status === 200) {
            const { history } = this.props;
            history.push({
              pathname: '/reset_password',
              state: {
                email,
                link: 'forget',
              },
            });
          } else {
            this.setState({ errorMsg: res.data.message, loading: false });
          }
        })
        .catch(() => {
          this.setState({ errorMsg: 'Something went wrong', loading: false });
        });
    }
  }

  handleAlertClose() {
    this.setState({ alerts: false });
  }

  validate() {
    let emailError = '';
    const { email } = this.state;
    const mailRegex =
      /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    if (!email) {
      emailError = 'Email is required';
    } else if (email.length < 3) {
      emailError = 'length should be greater than 3';
    } else if (email && !mailRegex.test(email)) {
      emailError = 'Invalid Email';
    }
    if (emailError) {
      this.setState({ emailError });
      return false;
    }
    return true;
  }

  render() {
    const { alerts, loading } = this.state;

    return (
      <Layout>
        {loading ? <Loader /> : ''}
        {alerts ? (
          <Alerts
            alertVal={alerts}
            closeAlert={() => this.handleAlertClose()}
          />
        ) : (
          ''
        )}
        <div className="forgotpassword login-wrapper">
          <div>
            <h2 className="login-h2"> Forgot Password</h2>
            <hr />
          </div>
          <ForgotPasswordForm
            handleChange={this.handleChange}
            handleForget={this.handleForget}
            formValues={this.state}
            component={this}
          />
        </div>
      </Layout>
    );
  }
}

export default ForgotPassword;
