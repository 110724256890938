import React, { Component } from 'react';
import { Container, Label, Row, Col, FormGroup, InputGroup } from 'reactstrap';
import Alerts from './Alerts';
import Checkmark from '../../assets/images/checkmark.png';
import '../../assets/scss/layouts/pages/_connectForm.scss';
import Logo from '../../assets/images/gcLogo.png';
import '../../assets/scss/layouts/pages/_login.scss';

class UnsubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      unsubcheckbox: 'true',
      unGCnewssubcheckbox: 'true',
      unGCnewssubcheckboxfinal: 'false',
      lastNameErr: '',
      emailErr: '',
      alerts: '',
    };
    this.firstRef = React.createRef();
    this.lastRef = React.createRef();
    this.emailRef = React.createRef();
    this.companyRef = React.createRef();
    this.checkRef = React.createRef();
    this.handleUnsubChange = this.handleUnsubChange.bind(this);
    this.handleunGCnewssubcheckbox = this.handleunGCnewssubcheckbox.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const array = [];
    const params = new URLSearchParams(location.search);
    // eslint-disable-next-line no-restricted-syntax
    for (const param of params) {
      array.push(param[1]);
    }
    if (array.length > 0) {
      const emailaddr = array[2];
      this.setState({
        firstName: array[0],
        lastName: array[1],
        email: emailaddr.replace(/\s/g, '+'),
      });
    }
    this.firstRef.current.focus();
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      lastNameErr: '',
      emailErr: '',
    });
  }

  handleSubmit(event) {
    // const { firstName, lastName, email } = this.state;
    event.preventDefault();
    const data = new FormData(event.target);
    const decodeUrl = decodeURI(
      'https://www.generalcatalyst.com/contact?first_name=test&last_name=test&email=test@test.com'
    );
    fetch(
      'https://dms.ringlead.com/api/v1/webtolead/6478/1a93150c-f28a-4786-8b96-3ac4f7c5f1dd/submission/',
      {
        method: 'POST',
        body: data,
        crossDomain: true,
        mode: 'no-cors',
      }
    )
      .then((res) => {
        if (res) {
          this.setState(
            { alerts: "Thank you! We've received your information." },
            () => {
              window.setTimeout(() => {
                window.open(decodeUrl, '_self');
              }, 5000);
            }
          );
        } else {
          event.preventDefault();
        }
      })
      .then((datan) => {
        if (datan) {
          this.setState(
            { alerts: "Thank you! We've received your information." },
            () => {
              window.setTimeout(() => {
                window.open(decodeUrl, '_self');
              }, 5000);
            }
          );
        } else {
          event.preventDefault();
        }
      })
      .catch(() => {});
  }

  handleAlertClose() {
    this.setState({ alerts: false });
  }

  handleNextFirst(ev) {
    ev.preventDefault();
    if (ev.keyCode === 13) {
      this.lastRef.current.focus();
    }
  }

  handleNextLast(ev) {
    ev.preventDefault();
    const isValidName = this.validateName();
    if (ev.target.value && ev.keyCode === 13) {
      this.setState({ lastNameErr: '' }, () => {
        this.emailRef.current.focus();
        window.scrollTo(0, document.querySelector('#emailRef').offsetTop);
      });
    } else if (ev.keyCode === 13) {
      if (isValidName) {
        this.setState({ lastNameErr: '' }, () => {
          this.emailRef.current.focus();
          window.scrollTo(0, document.querySelector('#emailRef').offsetTop);
        });
      }
    }
  }

  handleNextMail(ev) {
    ev.preventDefault();
    const isValidMail = this.validateMail();
    if (ev.target.value) {
      if (ev.keyCode === 13) {
        if (isValidMail) {
          this.setState({ emailErr: '' }, () => {
            this.companyRef.current.focus();
            window.scrollTo(0, document.querySelector('#companyRef').offsetTop);
          });
        }
      } else {
        window.scrollTo(0, document.querySelector('#emailRef').offsetTop);
        this.setState({ emailErr: '' }, () => {
          this.emailRef.current.focus();
          window.scrollTo(0, document.querySelector('#emailRef').offsetTop);
        });
      }
    } else if (isValidMail) {
      this.setState({ emailErr: '' });
    }
  }

  handleUnsubChange() {
    const { unsubcheckbox } = this.state;
    this.setState({ unsubcheckbox: !unsubcheckbox });
  }

  handleunGCnewssubcheckbox() {
    const { unGCnewssubcheckbox } = this.state;
    this.setState({
      unGCnewssubcheckbox: !unGCnewssubcheckbox,
      unGCnewssubcheckboxfinal: unGCnewssubcheckbox,
    });
  }

  validateMail() {
    const { email } = this.state;
    let emailErr = '';
    const mailRegex =
      /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    if (!email) {
      emailErr = 'Required';
    } else if (email && !mailRegex.test(email)) {
      emailErr = 'Please enter a valid email address.';
    }
    if (emailErr) {
      this.setState({ emailErr });
      return false;
    }
    return true;
  }

  validateName() {
    const { lastName } = this.state;
    let lastNameErr = '';
    if (!lastName) {
      lastNameErr = 'Required';
    }
    if (lastNameErr) {
      this.setState({ lastNameErr });
      return false;
    }
    return true;
  }

  render() {
    const {
      firstName,
      lastName,
      unsubcheckbox,
      unGCnewssubcheckbox,
      unGCnewssubcheckboxfinal,
      email,
      lastNameErr,
      emailErr,
      alerts,
    } = this.state;
    return (
      <div className="loginPage">
        {alerts ? (
          <Alerts
            alertVal={alerts}
            closeAlert={() => this.handleAlertClose()}
          />
        ) : (
          ''
        )}
        <Container className="fullWidth-100">
          <Row>
            <Col
              xs="7"
              className="pt-5 pb-4 mx-auto"
            >
              <div className="d-flex flex-column h-100">
                <div className="loginSection">
                  <div className="sldForm">
                    <form onSubmit={(ev) => this.handleSubmit(ev)}>
                      <div className="text-center my-4">
                        <img
                          src={Logo}
                          alt="logo"
                        />
                      </div>
                      <Row>
                        <Col
                          xs="12"
                          className="sinpuBlk"
                        >
                          <div className="inputInner">
                            <FormGroup>
                              <InputGroup>
                                <Label htmlFor="firstname" />
                                <input
                                  ref={this.firstRef}
                                  autoComplete="firstName"
                                  className="toggle form-control"
                                  name="firstName"
                                  // placeholder="First Name"
                                  readOnly={true}
                                  type="text"
                                  value={firstName}
                                  onChange={(ev) => this.handleChange(ev)}
                                  onKeyUp={(ev) => this.handleNextFirst(ev)}
                                  onKeyPress={(event) => {
                                    if (event.which === 13) {
                                      event.preventDefault();
                                    }
                                  }}
                                  // onKeyDown={(ev) => this.handleKeyFirst(ev)}
                                />
                              </InputGroup>
                              <input
                                type="hidden"
                                value={firstName}
                                name="first_name"
                              />
                              <input
                                type="hidden"
                                value={lastName}
                                name="last_name"
                              />
                              <input
                                type="hidden"
                                name="LeadSource"
                                value="Web"
                              />
                            </FormGroup>
                            <FormGroup>
                              <InputGroup>
                                <Label htmlFor="lastname" />
                                <input
                                  ref={this.lastRef}
                                  autoComplete="lastName"
                                  className="toggle form-control"
                                  name="lastName"
                                  readOnly={true}
                                  // placeholder="Last Name"
                                  type="text"
                                  value={lastName}
                                  onChange={(ev) => this.handleChange(ev)}
                                  onKeyUp={(ev) => this.handleNextLast(ev)}
                                  onKeyPress={(event) => {
                                    if (event.which === 13) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </InputGroup>
                              {lastNameErr ? (
                                <div
                                  className="error"
                                  id="error2"
                                >
                                  {lastNameErr}
                                </div>
                              ) : (
                                ''
                              )}
                            </FormGroup>
                            <FormGroup>
                              <InputGroup>
                                <Label htmlFor="email" />
                                <input
                                  ref={this.emailRef}
                                  autoComplete="email"
                                  id="asdfg"
                                  // id="email"
                                  className="toggle form-control"
                                  name="email"
                                  // placeholder="Email"
                                  readOnly={true}
                                  type="email"
                                  value={email}
                                  onChange={(ev) => this.handleChange(ev)}
                                  onKeyUp={(ev) => this.handleNextMail(ev)}
                                  onKeyPress={(event) => {
                                    if (event.which === 13) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </InputGroup>
                              {emailErr ? (
                                <div
                                  className="error"
                                  id="error3"
                                >
                                  {emailErr}
                                </div>
                              ) : (
                                ''
                              )}
                            </FormGroup>
                            <div>
                              <div className="d-block">
                                <div className="checkBox pb-3">
                                  <input
                                    ref={this.checkRef}
                                    id="toggle-on-q14"
                                    className="form-check-input"
                                    name="unsubcheckbox"
                                    type="checkbox"
                                    defaultChecked={unsubcheckbox}
                                    onChange={this.handleUnsubChange}
                                    value="1"
                                  />
                                  <input
                                    type="hidden"
                                    value={unsubcheckbox}
                                    name="00N5A00000GrVvc"
                                  />
                                  <Label
                                    className="font-weight-bold"
                                    htmlFor="toggle-on-q14"
                                  >
                                    Unsubscribe from the GC talent network
                                  </Label>
                                </div>
                                <div className="checkBox pb-3">
                                  <input
                                    ref={this.checkRef}
                                    id="toggle-on-q15"
                                    className="form-check-input"
                                    name="unGCnewssubcheckbox"
                                    type="checkbox"
                                    defaultChecked={unGCnewssubcheckbox}
                                    onChange={this.handleunGCnewssubcheckbox}
                                    value="1"
                                  />
                                  <input
                                    type="hidden"
                                    value={unGCnewssubcheckboxfinal}
                                    name="00N5A00000M5BaB"
                                  />
                                  <Label
                                    className="font-weight-bold"
                                    htmlFor="toggle-on-q15"
                                  >
                                    Unsubscribe from GC news and updates
                                  </Label>
                                </div>
                              </div>
                              <div className="btnActions mt-3">
                                <button
                                  type="submit"
                                  className="d-block mx-auto btn-mx280 solidBtn solidBtn__blue text-uppercase"
                                >
                                  Submit
                                  <img
                                    className="button_icon"
                                    src={Checkmark}
                                    alt="button icon"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default UnsubscribeForm;
