import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const { hostname } = window.location;
class DropDownButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleContact = this.handleContact.bind(this);
    this.handleIntroduction = this.handleIntroduction.bind(this);
  }

  handleToggle() {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  handleContact() {
    let url;
    if (hostname.includes('network')) {
      url = 'https://network.generalcatalyst.com/client';
    } else if (hostname.includes('igcdev-5gkc')) {
      url = 'https://igcdev-5gkc.onrender.com/client';
    } else {
      url = 'http://localhost:3000/client';
    }
    window.location.href = url;
    this.setState({});
  }

  handleIntroduction() {
    let url;
    if (hostname.includes('network')) {
      url = 'https://network.generalcatalyst.com/historicalIntro';
    } else if (hostname.includes('igcdev-5gkc')) {
      url = 'https://igcdev-5gkc.onrender.com/historicalIntro';
    } else {
      url = 'http://localhost:3000/historicalIntro';
    }
    window.location.href = url;
    this.setState({});
  }

  render() {
    const { dropdownOpen } = this.state;
    const { direction, args, label, customClass } = this.props;
    return (
      <div className={`d-flex ${customClass}`}>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={this.handleToggle}
          direction={direction}
        >
          <DropdownToggle caret>{label}</DropdownToggle>
          <DropdownMenu {...args}>
            <DropdownItem onClick={this.handleIntroduction}>Requested Introductions</DropdownItem>
            <DropdownItem onClick={this.handleContact}>All Contacts</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export default withRouter(DropDownButton);
