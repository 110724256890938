import React from 'react';
import { Card, CardHeader } from 'reactstrap';
import PersonalInfo from './ViewProfile';

function EditProfile() {
  return (
    <div>
      <div className="container CustomWidth">
        <Card className="card  mt-5">
          <CardHeader className="card-header">
            <h3 className="pageTitle">PROFILE SETTINGS</h3>
          </CardHeader>
        </Card>
        <PersonalInfo />
      </div>
    </div>
  );
}

export default EditProfile;
