import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
import { Modal } from 'react-bootstrap';
import { Row, Col, Button } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CheckCircle from '../../assets/images/errorImg.png'; // check-circle.png';
import SfTable from './SalesforceTable';
import * as URL from '../../constants/constants';
import Icon from '../shared/icomoon';
import Loader from '../common/loader/Loader';

class SalesForcetab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: '',
      href: '',
      tabIndex: 0,
      loading: false,
      message: '',
      syncMsg: '',
      movePage401: false,
    };
  }

  componentDidMount() {
    this.showLoader();
    axios
      .get(URL.ACCESS_TOKEN)
      .then((res) => {
        if (res) {
          this.setState({ accessToken: res.data.access_token });
          this.hideLoader();
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  handleQR() {
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    axios
      .get(URL.INDEX, config)
      .then((res) => {
        if (res) {
          window.open(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  handleSync() {
    this.setState({ syncMsg: 'Syncing Salesforce data' }, () => {
      axios
        .get(URL.SALESFORCE)
        .then((resp) => {
          if (resp) {
            this.hideLoader();
            this.setState({ showPop: true, syncMsg: false }, () => {
              this.handleMsg(resp.data.message);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true, loading: false });
          } else if (err.response.status === 500) {
            this.setState({ showPop: true }, () => {
              this.handleMsg('Something went wrong');
            });
          }
        });
    });
  }

  handleMsg(res) {
    this.setState({ message: res });
  }

  handleClose() {
    this.setState(
      {
        showPop: false,
        syncMsg: false,
      },
      () => {
        const { history } = this.props;
        history.go(0);
      }
    );
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  selectTabs(tabVal) {
    this.setState({
      tabIndex: tabVal,
    });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  render() {
    const {
      accessToken,
      href,
      tabIndex,
      showPop,
      loading,
      message,
      syncMsg,
      movePage401,
    } = this.state;

    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }

    return (
      <div className="">
        {loading ? <Loader /> : ''}
        <div className="saleforce">
          <Tabs
            activeKey={tabIndex}
            selectedIndex={tabIndex}
            onSelect={(tabVal) => this.selectTabs(tabVal)}
          >
            <TabList>
              <Tab>Daily log</Tab>
              <Tab>Config</Tab>
              {tabIndex === 0 ? (
                <button
                  type="button"
                  className="solidBtn solidBtn__blue"
                  onClick={() => this.handleSync()}
                >
                  on Demand
                </button>
              ) : (
                ''
              )}
            </TabList>
            <TabPanel>
              <SfTable />
            </TabPanel>
            <TabPanel>
              {href ? <h3 dangerouslySetInnerHTML={{ __html: href }} /> : ''}
              <Row>
                <Col sm="12">
                  <Row>
                    <Col
                      xs="12"
                      className="pt-5 pb-5 text-center"
                    >
                      {!accessToken ? (
                        <button
                          type="submit"
                          className="solidBtn solidBtn__blue"
                          onClick={() => this.handleQR()}
                        >
                          Salesforce OR
                        </button>
                      ) : (
                        <p className="m-0 p-0">Already Authenticated</p>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPanel>
          </Tabs>
        </div>
        {showPop ? (
          <Modal
            className="discModal customModal customModal--w500 thanksModal"
            show={showPop}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3 text-center">
                  <img
                    src={CheckCircle}
                    alt="checkCircle"
                  />
                  <h2>{message}</h2>
                </div>
                <Row>
                  <Col className="buttonActions">
                    <Button
                      className="m-auto w-auto solidBtn solidBtn__blue pl-4 pr-4"
                      onClick={() => this.handleClose()}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        {syncMsg ? (
          <Modal
            className="discModal customModal customModal--w500 thanksModal"
            show={syncMsg}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3 text-center">
                  <img
                    src={CheckCircle}
                    alt="checkCircle"
                  />
                  <h2>Syncing salesforce data</h2>
                </div>
                <Row>
                  <Col className="buttonActions">
                    <Button
                      className="m-auto w-auto solidBtn solidBtn__blue pl-4 pr-4"
                      onClick={() => this.handleClose()}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default withRouter(SalesForcetab);
