/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["clickInfo", "handleNextLast", "addClass", "removeClass", "handleScroll"]
}] */
/* eslint-disable */
import React from 'react';
import CommonLabelText from '../label/CommonLabelText'
import CommonButton from '../button/CommonButton'
import { Modal } from 'react-bootstrap';
import { Col } from 'reactstrap';
import Icon from  '../../shared/icomoon';

class CommonModal extends React.Component {
  render() {
    const { value, handleChangeCode, handleClose, showPop, verification, formValues } = this.props;

    return (
           <>
           <Modal className="discModal customModal customModal--w500" show={formValues.showPop} animation={false}>
                  <Icon icon="cross" className="closebuttonCust" onClick={handleClose} size={10} color="#919eab" />
                  <Modal.Body>
                    <div className="sBlk py-2">
                      <div className="crForm--login mt-3">
                        <Col xs="12">
                          <div className="form-group">
                            <CommonLabelText label='Enter Verification code' 
                            name="code"
                            id="code"
                            defaultValue={formValues.code}
                            handleChange={handleChangeCode }
                            />
                            <div className="error">{formValues.codeErr}</div>
                            <div className="error">{formValues.errorMsg}</div>
                          </div>
                          <CommonButton 
                          variant ='submit'
                          label ='Verify'
                          clickHandler = { verification }
                          customClass = 'btn-primary'
                          />
                        </Col>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
    </>
    )
   
  }
}

export default CommonModal;
