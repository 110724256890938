/* eslint-disable */
import React, { Component } from 'react';
import Layout from '../layout/Layout';
import { Link } from 'react-router-dom';
import '../../assets/scss/layouts/pages/_magicLink.scss';
import { Col, Row, Container } from 'reactstrap';
import Logo from '../../assets/images/gcLogo.png';
import FtrLogo from '../../assets/images/Form_logo.png';

class MagicLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { location } = this.props;
    return (
      <Layout>
      <div className="">
        <Container className="fullWidth-100 ">
          <Row>
            <Col xs="9" className="pt-5 pb-4 mx-auto mt-5">
              <div className="d-flex flex-column h-100">
                <div className="loginSection">
                    {
                      location && location.state && location.state.link && location.state.link === 'forget'
                        ? (
                          <p className="text-small fontSize">
                            You entered <Link color="#F18F3F">{location && location.state.email ? location.state.email : ''}</Link><br />
                            If this email address is associated with your General Catalyst then we will
                            email you the instructions for resetting your password. If you don&#39;t 
                            receive our email, then please look for it in your spam folder or double
                            check the spelling of your email address
                          </p>
                        )
                        : [
                         
                        ]
                    }
                  <hr />
                  <div className="mt-4  text-center">
                    <span>
                      Back to <Link to="/" color="link">&nbsp;Login</Link>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </Layout>

    );
  }
}

export default MagicLink;
