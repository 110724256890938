/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import '../../assets/scss/layouts/pages/_createUser.scss';
import '../../assets/scss/layouts/pages/_userdetails.scss';

function PersonalInfo({ data }) {
  return (
    <div>
      {data
        ? data.map((each) => {
            const d2 = each.last_login_at
              ? each.last_login_at.split(' ')[0].replace(/-/g, '')
              : '';
            return (
              <div
                className="container CustomWidth userdetails"
                key={each.id}
              >
                <form className="formHeightCuston mt-4">
                  <Row>
                    <Col xs="8">
                      <div className="form-group">
                        <label htmlFor="firstname">First name:</label>
                        <span>{each.first_name ? each.first_name : '_'}</span>
                      </div>
                    </Col>

                    <Col xs="4">
                      <div className="form-group">
                        <label htmlFor="company">Company:</label>
                        <span>{each.company ? each.company : '_'}</span>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="form-group">
                        <label htmlFor="lastname">Last name:</label>
                        <span>{each.last_name ? each.last_name : '_'}</span>
                      </div>
                    </Col>
                    <Col xs="4">
                      <div className="form-group">
                        <label htmlFor="title">Title:</label>
                        <span>{each.title ? each.title : '_'}</span>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="form-group">
                        <label htmlFor="emailImput">Email:</label>
                        <span>{each.email ? each.email : '_'}</span>
                      </div>
                    </Col>
                    <Col xs="4">
                      <div className="form-group">
                        <label htmlFor="role">Role:</label>
                        <span>{each.role ? each.role : '_'}</span>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="form-group">
                        <label htmlFor="status">Status:</label>
                        <span>{each.status ? each.status : '_'}</span>
                      </div>
                    </Col>
                    <Col xs="4">
                      <div className="form-group">
                        <label htmlFor="lastLogin">Last Login:</label>
                        <span>
                          {d2
                            ? `${d2.substring(4, 6)}/${d2.substring(
                                6,
                                9
                              )}/${d2.substring(0, 4)}`
                            : '_'}
                        </span>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="form-group">
                        <label htmlFor="focusArea">Focus Area:</label>
                        <span>{each.focus_area ? each.focus_area : '_'}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col
                      xs="12"
                      className="mb-5"
                    >
                      <h4 className="mb-4">Change Password</h4>
                      <Link
                        to="/update_password"
                        className="btn btn-primary"
                      >
                        Change Password
                      </Link>
                    </Col>
                  </Row>
                  <Row />
                </form>
              </div>
            );
          })
        : ''}
    </div>
  );
}
export default withRouter(PersonalInfo);
