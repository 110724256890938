import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Terms from './components/auth/Terms';
import Password from './components/auth/Password';
import ConfirmPassword from './components/auth/ConfirmPassword';
import ConfirmMagic from './components/auth/ConfirmMagic';
import MagicLink from './components/auth/MagicLink';
import UnsubscribeForm from './components/pages/UnsubscribeForm';
import GoogleLogin from './components/auth/GoogleLogin';
import NotFound from './components/dataNotFound/DataNotFound';
import RefreshSession from './components/auth/RefreshSession';
import Main from './Main';
import MainContactForm from './components/pages/MainContactForm';
import CongratulationForm from './components/contact/Congratulation';
import Login from './components/Login/Login';
import ForgotPassword from './components/ForgetPassword/ForgotPassword';

import './commonCss.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      // prettier-ignore
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/terms_conditions" component={Terms} />
          <Route exact path="/contact" component={MainContactForm} />
          <Route exact path="/congratulation" component={CongratulationForm} />
          <Route exact path="/unsubscribe" component={UnsubscribeForm} />
          <Route exact path="/password/*" component={Password} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/callback" component={GoogleLogin} />
          <Route exact path="/confirm/*" component={ConfirmPassword} />
          <Route exact path="/magic_link/*" component={ConfirmMagic} />
          <Route exact path="/reset_password" component={MagicLink} />
          <Route path="/refresh_session/*" component={RefreshSession} />
          <Route path="/notFound" component={NotFound} />
          <ProtectedRoute path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
