/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import * as URL from '../../constants/constants';
import '../../assets/scss/layouts/pages/_salesforce.scss';
import Loader from '../common/loader/Loader';

class SalesForceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: '',
      loading: false,
    };
  }

  componentDidMount() {
    axios
      .get(URL.LOGS)
      .then((res) => {
        if (
          res &&
          res.data.success &&
          res.data.message !== 'Data Not Present'
        ) {
          this.setState({ logs: res.data });
          this.hideLoader();
        } else if ('Data Not Present') {
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  render() {
    const { logs, loading } = this.state;
    return (
      <Row>
        <Col>
          {loading ? <Loader /> : ''}
          <div className="homecontent">
            <div className="content-div tblWraps">
              <div
                className="table-wrapper nonClickable"
                style={{ height: 500 }}
              >
                <table
                  className="table salesforce-list"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th> </th>
                      <th width="20%">Run Date</th>
                      <th width="20%">Pass | Fail</th>
                      <th width="60%">New Data | Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs && logs.data ? (
                      logs.data.map((each) => {
                        // yy-mm-dd to mm-dd-yy
                        const d2 = each.date;
                        return (
                          <tr>
                            <td />
                            <td>
                              {d2
                                ? `${d2.substring(5, 7)} / ${d2.substring(
                                    8,
                                    10
                                  )} / ${d2.substring(0, 4)}`
                                : '_'}
                            </td>
                            <td>{each.status}</td>
                            <td>{each.count}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          width="100%"
                          colSpan={3}
                          className="text-center"
                        >
                          No data present
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
export default SalesForceTable;
