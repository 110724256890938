import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import * as URL from '../../constants/constants';
import CommonButton from './button/CommonButton';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeValue: '',
      rating: '',
      feedbackSuggestion: ' ',
      showdata: false,
      hidedata: true,
      userRole: ',',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.closeToggleClick = this.closeToggleClick.bind(this);
    this.toggleClick = this.toggleClick.bind(this);
  }

  componentDidMount() {
    const role = Cookies.get('role');
    if (role === 'Admin') {
      this.setState({
        userRole: role,
      });
    }
  }

  handleChange(ev) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  handleNext() {
    this.setState({ showdata: true, hidedata: false });
  }

  handleFeedback() {
    const { rating, feedbackSuggestion } = this.state;
    const data = {
      rating,
      feedback_suggestion: feedbackSuggestion,
    };
    axios
      .post(`${URL.ADD_FEEDBACK}`, data)
      .then((res) => {
        if (res.data) {
          this.setState({
            activeValue: 'inactive',
            rating: '',
            feedbackSuggestion: '',
            hidedata: true,
            showdata: false,
          });
        }
      })
      .catch(() => {
        this.setState({ activeValue: 'inactive' });
      });
  }

  closeToggleClick() {
    this.setState({ activeValue: 'inactive', hidedata: true, showdata: false });
  }

  toggleClick() {
    this.setState({ activeValue: 'active' });
  }

  render() {
    const { activeValue, feedbackSuggestion, showdata, hidedata, userRole } =
      this.state;
    return (
      <div
        id="feedWrap"
        className={`feedWrap ${activeValue === 'active' ? activeValue : ''}`}
      >
        {/* <div className="feedWrap_btn"> */}
        {/* <div className="position-relative feedback-header"> */}
        <div className="text-dark font-1 feedback-btn-wrap">
          {userRole !== 'Admin' && (
            <CommonButton
              clickHandler={() => this.toggleClick()}
              variant="secondary"
              label="Feedback"
              iconSize={16}
              // icon="Icon" // TODO new icon need to be change if future
              iconColor="#141414"
              customClass={`border-0 feedBackButton ${activeValue}`}
            />
          )}
        </div>
        {/* </div> */}
        {/* </div> */}
        <div className="feedInnerWrap">
          <div className="feedInner__head">
            <h6 className="text-uppercase">Send Feedback</h6>
            <button
              type="button"
              className="popClose"
              onClick={() => this.closeToggleClick()}
            >
              <i className="icon-close" />
            </button>
          </div>
          {hidedata ? (
            <div>
              <FormGroup className="mb-5">
                <Label>How do you rate the app?</Label>
                <div className="star-rating">
                  <input
                    id="star-5"
                    type="radio"
                    name="rating"
                    value="5"
                    onClick={(ev) => this.handleChange(ev)}
                  />
                  <Label
                    htmlFor="star-5"
                    title="5 stars"
                  >
                    <i
                      className="active icon-Star"
                      aria-hidden="true"
                    />
                  </Label>
                  <input
                    id="star-4"
                    type="radio"
                    name="rating"
                    value="4"
                    onClick={(ev) => this.handleChange(ev)}
                  />
                  <Label
                    htmlFor="star-4"
                    title="4 stars"
                  >
                    <i
                      className="active icon-Star"
                      aria-hidden="true"
                    />
                  </Label>
                  <input
                    id="star-3"
                    type="radio"
                    name="rating"
                    value="3"
                    onClick={(ev) => this.handleChange(ev)}
                  />
                  <Label
                    htmlFor="star-3"
                    title="3 stars"
                  >
                    <i
                      className="active icon-Star"
                      aria-hidden="true"
                    />
                  </Label>
                  <input
                    id="star-2"
                    type="radio"
                    name="rating"
                    value="2"
                    onClick={(ev) => this.handleChange(ev)}
                  />
                  <Label
                    htmlFor="star-2"
                    title="2 stars"
                  >
                    <i
                      className="active icon-Star"
                      aria-hidden="true"
                    />
                  </Label>
                  <input
                    id="star-1"
                    type="radio"
                    name="rating"
                    value="1"
                    onClick={(ev) => this.handleChange(ev)}
                  />
                  <Label
                    htmlFor="star-1"
                    title="1 star"
                  >
                    <i
                      className="active icon-Star"
                      aria-hidden="true"
                    />
                  </Label>
                </div>
              </FormGroup>
              <div className="buttonActions">
                <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  onClick={() => this.closeToggleClick()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleNext()}
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
          {showdata ? (
            <div>
              <FormGroup>
                <Label>
                  Are there any features you&apos;d like us to add that would
                  help you better engage the GC network?
                </Label>
                <Input
                  type="textarea"
                  name="feedbackSuggestion"
                  value={feedbackSuggestion}
                  onChange={(ev) => this.handleChange(ev)}
                  autoComplete="off"
                />
              </FormGroup>
              <div className="buttonActions updateBtn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.closeToggleClick()}
                >
                  Cancel
                </button>
                {/* <button type="button" className="solidBtn solidBtn__blue ml-3" onclick={(e) => this.handleFeedback(e)}>Submit</button> */}
                <button
                  type="button"
                  className="solidBtn solidBtn__blue ml-3"
                  onClick={() => this.handleFeedback()}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default Feedback;
