import React, { Component } from 'react';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { location } = this.props;
    return (
      <div>
        {
          location && location.state && location.state.data
            ? (
              <div
                dangerouslySetInnerHTML={{ __html: location.state.data }}
              />
            )
            : ''
        }
      </div>
    );
  }
}
export default Terms;
