/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["clickInfo", "handleNextLast", "addClass", "removeClass", "handleScroll"]
}] */
/* eslint-disable */
import React, { Component, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import {
  Label, FormGroup
} from 'reactstrap';
import Alerts from './Alerts';
import FormLogo from '../../assets/images/gcLogoCenterIcon.png';
import '../../assets/scss/layouts/pages/_connectForm.scss';
import RoundLoader from '../../assets/images/roundLoader.svg';
import axios from 'axios';
import * as URL from '../../constants/constants';
import PersonalInfo from '../contact/PersonalInfo.jsx';
import LocationPreferences from '../contact/locationPreferences';
import Experience from '../contact/Experience';
import Opportunities from '../contact/Opportunities';
import Complete from '../contact/Complete';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      step: 1,
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      title: '',
      phone: '',
      linkedin: 'www.linkedin.com/in/',
      referred: '',
      primaryRegion: "",
      ethnicityStatus: '',
      firstNameErr: '',
      lastNameErr: '',
      emailErr: '',
      companyErr: '',
      titleErr: '',
      phonenumberErr: '',
      linkedInErr: '',
      referredErr: '',
      countries: [],
      experience: [],
      language:[],
      selectedExperience: [],
      expert: [],
      industryExperience: [],
      previous_company: '',
      interestedIn: [],
      interestedInErr: '',
      industryPreferences: [],
      industryPreferencesErr: '',
      primaryRegionErr: '',
      stage: [],
      roles: "",
      ethnicity: [],
      gender: '',
      experience: '',
      stage: '',
      showPop: false,
      gc_portfolio: true,
      linkedIn_profile_image: true,
      update_news: true,
      functionInErr: ''
    }
    this.validateName = this.validateName.bind(this)
    this.validateLastName = this.validateLastName.bind(this)
    this.validateMail = this.validateMail.bind(this)
    this.validateCompany = this.validateCompany.bind(this)
    this.validateReferred = this.validateReferred.bind(this)
    this.validateIndustry = this.validateIndustry.bind(this)
    this.validateInterestedIn = this.validateInterestedIn.bind(this)
    this.validateIndustryPreferences = this.validateIndustryPreferences.bind(this)
    this.validateFunction = this.validateFunction.bind(this)
    this.validateLinkedIn = this.validateLinkedIn.bind(this)
    this.validateprimaryRegion = this.validateprimaryRegion.bind(this)
  }

  nextStep(e) {
    const { step } = this.state;
    console.log("step: ", step)
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep(e) {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange(ev) {
    console.log('changeeeee', ev.target.value)
    const { type, checked } = ev.target;
    const input = ev.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    console.log('value:  :', value)
    this.setState({
      ...this.state,
      [ev.target.name]: value,
      firstNameErr: '',
      lastNameErr: '',
      emailErr: '',
      companyErr: '',
      titleErr: '',
      phonenumberErr: '',
      linkedInErr: '',
      referredErr: '',
      primaryRegionErr: ''
    });
  }
  componentDidMount() {
    window.addEventListener('load', () => {
      this.handleQueryString(); // Call handleQueryString on component load
    });
  }

  handleQueryString() {
    const queryParams = new URLSearchParams(window.location.search);
  
    const firstNameParam = queryParams.get('first_name');
    const lastNameParam = queryParams.get('last_name');
    const emailParam = queryParams.get('email');
    const linkedInProfileParam = queryParams.get('linkedInProfile');
  
    if (firstNameParam) {
      this.setState((prevState) => ({
        ...prevState,
        first_name: firstNameParam,
      }));
    }
  
    if (lastNameParam) {
      this.setState((prevState) => ({
        ...prevState,
        last_name: lastNameParam,
      }));
    }
  
    if (emailParam) {
      this.setState((prevState) => ({
        ...prevState,
        email: emailParam,
      }));
    }
  
    if (linkedInProfileParam) {
      this.setState((prevState) => ({
        ...prevState,
        linkedin: linkedInProfileParam,
      }));
    }
  }

  validateCompany() {
    const { company } = this.state;
    let companyErr = '';
    if (!company) {
      companyErr = 'This field is required.';
    }
    if (companyErr) {
      this.setState({ companyErr });
      return false;
    }
    return true;
  }

  validateApply() {
    const { applyStatus } = this.state;
    let applyErr = '';
    if (!applyStatus) {
      applyErr = 'This field is required.';
    }
    if (applyErr) {
      this.setState({ applyErr });
      return false;
    }
    return true;
  }

  validateIndustry() {
    const { countries } = this.state;
    let industryErr = '';
    if (countries.length === 0) {
      industryErr = 'This field is required.';
    }
    if (industryErr) {
      this.setState({ industryErr });
      return false;
    }
    return true;
  }

  validateReferred() {
    const { referred } = this.state;
    let referredErr = '';
    if (!referred) {
      referredErr = 'This field is required.';
    }
    if (referredErr) {
      this.setState({ referredErr });
      return false;
    }
    return true;
  }

  validatePhoneno() {
    const { phone } = this.state;
    let phonenoErr = '';
    const phoneregex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (!phone) {
      phonenoErr = 'This field is required.';
    } else if (phone && !phoneregex.test(phone)) {
      phonenoErr = 'Please enter valid phone number';
    }
    if (phonenoErr) {
      this.setState({ phonenoErr });
      return false;
    }
    return true;
  }

  validateMail() {
    const { email } = this.state;
    let emailErr = '';
    const mailRegex = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
    if (!email) {
      emailErr = 'This field is required.';
    } else if (email && !mailRegex.test(email)) {
      emailErr = 'Please enter a valid email address.';
    }
    if (emailErr) {
      this.setState({ emailErr });
      return false;
    }
    return true;
  }

  validateLastName() {
    const { last_name } = this.state;
    let lastNameErr = '';
    if (!last_name) {
      lastNameErr = 'This field is required.';
    }
    if (lastNameErr) {
      this.setState({ lastNameErr });
      return false;
    }
    return true;
  }
  validateName() {
    const { first_name } = this.state;
    let firstNameErr = '';
    if (!first_name) {
      firstNameErr = 'This field is required.';
    }
    if (firstNameErr) {
      this.setState({ firstNameErr });
      return false;
    }
    return true;
  }

  validateInterestedIn() {
    const { interestedIn } = this.state;
    let interestedInErr = ''
    if (interestedIn.length === 0) {
      interestedInErr = 'This field is required.';
    }
    if (interestedInErr) {
      this.setState({ interestedInErr })
      return false;
    }
    return true;
  }

  validateIndustryPreferences() {
    const { industryPreferences } = this.state;
    let industryPreferencesErr = ''
    if (industryPreferences.length === 0) {
      industryPreferencesErr = 'This field is required.';
    }
    if (industryPreferencesErr) {
      this.setState({ industryPreferencesErr })
      return false;
    }
    return true;
  }

  validateFunction() {
    const { expert } = this.state;
    let functionInErr = ''
    if(expert.length === 0) {
      functionInErr = 'This field is required.';
    }
    if (functionInErr) {
      this.setState({ functionInErr })
      return false;
    }
    return true;
  }

  validateLinkedIn() {
    const { linkedin } = this.state;
    let linkedInErr = '';
    if (!linkedin || linkedin == 'www.linkedin.com/in/') {
      linkedInErr = 'This field is required.';
    }
    if (linkedInErr) {
      this.setState({ linkedInErr });
      return false;
    }
    return true;
  }

  validateprimaryRegion() {
    const { primaryRegion } = this.state;
    console.log(primaryRegion);
    let primaryRegionErr = '';
    if (!primaryRegion) {
      primaryRegionErr = 'This field is required.';
    }
    if (primaryRegionErr) {
      this.setState({ primaryRegionErr });
      return false;
    }
    return true;
  }

  render() {
    const { step } = this.state;
    const { first_name, last_name, email, phonenumber, company, title, linkedin, referred, experience } = this.state;
    const values = { first_name, last_name, email, phonenumber, company, title, linkedin, referred };
    switch (step) {
      case 1:
        return (
          <PersonalInfo
            nextStep={(e) => this.nextStep(e)}
            handleChange={(e) => this.handleChange(e)}
            values={this.state}
            validateName={this.validateName}
            validateLastName={this.validateLastName}
            validateMail={this.validateMail}
            validateCompany={this.validateCompany}
            validateReferred={this.validateReferred}
            validateLinkedIn={this.validateLinkedIn}
          />
        );

      case 2:
        return (
          <Opportunities
            nextStep={(e) => this.nextStep(e)}
            prevStep={(e) => this.prevStep(e)}
            handleChange={(e) => this.handleChange(e)}
            formValues={this.state}
            component={this}
            validateInterestedIn={this.validateInterestedIn}
            validateIndustryPreferences={this.validateIndustryPreferences}
          />
        );
      case 3:
        return (
          <Experience
            nextStep={(e) => this.nextStep(e)}
            prevStep={(e) => this.prevStep(e)}
            handleChange={(e) => this.handleChange(e)}
            formValues={this.state}
            component={this}
            validateFunction={this.validateFunction}
          />
        );
      case 4:
        return (
          <LocationPreferences
            nextStep={(e) => this.nextStep(e)}
            prevStep={(e) => this.prevStep(e)}
            handleChange={(e) => this.handleChange(e)}
            formValues={this.state}
            component={this}
            validateIndustry={this.validateIndustry}
            validateprimaryRegion={this.validateprimaryRegion}
          />
        );
      case 5:
        return (
          <Complete
            nextStep={(e) => this.nextStep(e)}
            prevStep={(e) => this.prevStep(e)}
            handleChange={(e) => this.handleChange(e)}
            formValues={this.state}
            component={this}
          />
        );
      default:
        return ""
    }
  }
}
