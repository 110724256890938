import React from 'react';
import AuditTrail from './AuditTrail';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';

function AuditContent({ location }) {
  let roles;
  if (location && location.state && location.state.role) {
    roles = location.state.role;
  }
  return (
    <div>
      {/* <NavBarHeader /> */}
      <GCPrivatePageLayout>
        <div className="">
          <AuditTrail authRole={roles} />
        </div>
      </GCPrivatePageLayout>
    </div>
  );
}
export default AuditContent;
