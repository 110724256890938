import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';
import cookies from 'js-cookie';
import { Modal } from 'react-bootstrap';
import Switch from 'react-switch';
import axios from 'axios';
import Alerts from './Alerts';
import '../../assets/scss/layouts/pages/_salesforce.scss';
import * as URL from '../../constants/constants';
import Icon from '../shared/icomoon';

class Securitytab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      showPop: false,
      code: '',
      codeErr: '',
      alerts: false,
      movePage401: false,
    };
  }

  componentDidMount() {
    if (cookies.get().otp === 'true' || cookies.get('otpScan') === 'true') {
      this.setState({ checked: true });
    } else if (cookies.get().otp === 'false') {
      this.setState({ checked: '' });
    }
  }

  handleChange(ev) {
    const re = /^[0-9\b]+$/;
    if (ev.target.value === '' || re.test(ev.target.value)) {
      this.setState({ [ev.target.name]: ev.target.value });
    }
  }

  async handleSwitch(check) {
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    this.setState({ checked: check });
    if (check) {
      await axios
        .get(URL.TWO_FACTOR, config)
        .then(async (res) => {
          if (res.data.success) {
            this.setState({ showPop: true });
            const image = new Image();
            image.src = await res.data.data;
            this.setState({ qrCode: image.src });
            document.getElementById('qr').appendChild(image);
            // document.body.appendChild(image);
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            this.setState({ movePage401: true });
          }
        });
    } else {
      axios
        .delete(URL.TWO_FACTOR, config)
        .then(() => {
          cookies.set('otp', false);
          cookies.set('otpScan', false);
          this.setState({ showPop: false });
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  handleClose() {
    this.setState({
      showPop: false,
      checked: false,
      codeErr: '',
    });
  }

  handleAlertClose() {
    this.setState({ alerts: false, code: '' });
  }

  verification() {
    const isValid = this.validate();
    const { code } = this.state;
    if (isValid) {
      this.setState({ codeErr: '' });
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      const data = {
        otp: code,
      };
      axios
        .post(URL.TWO_FACTOR, data, config)
        .then((res) => {
          if (
            res.data.success &&
            res.status !== 201 &&
            res.data.message ===
              'Enabled Two Factor Authentication Successfully'
          ) {
            cookies.set('otpScan', true);
            this.setState(
              { showPop: false, alerts: 'Successfully authenticates' },
              () => {
                window.setTimeout(() => {
                  this.setState({ alerts: false });
                }, 6000);
              }
            );
          } else if (res.status === 201) {
            this.setState(
              {
                showPop: false,
                checked: false,
                alerts: res.data.message,
                code: '',
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ alerts: false });
                }, 6000);
              }
            );
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            this.setState({ movePage401: true });
          }
        });
    }
  }

  validate() {
    let codeErr = '';
    const { code } = this.state;
    const passwordRegex = /^[0-9\b]+$/;
    if (!code) {
      codeErr = 'Required';
    } else if (!passwordRegex.test(code)) {
      codeErr = 'Code can only be whole numbers';
    }
    if (codeErr) {
      this.setState({ codeErr });
      return false;
    }
    return true;
  }

  render() {
    const { checked, showPop, code, codeErr, alerts, movePage401, qrCode } =
      this.state;
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <Row>
        {alerts ? (
          <Alerts
            alertVal={alerts}
            closeAlert={() => this.handleAlertClose()}
          />
        ) : (
          ''
        )}
        <Col className="mb-3">
          <div className="security">
            <div className="card-body p-0">
              <div className="">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td width="80%">Google Two Factor Verification</td>
                      <td
                        width="100%"
                        className="align-content-end d-flex justify-content-end toggle-btn"
                      >
                        <Switch
                          onChange={(check) => this.handleSwitch(check)}
                          checkedIcon=""
                          uncheckedIcon=""
                          activeText="On"
                          inActiveText="Off"
                          offHandleColor="#3d3d3d"
                          offColor="#fff"
                          onColor="#623BA5"
                          onHandleColor="#fff"
                          checked={checked}
                        />
                        &nbsp;
                        <span
                          className="d-inline-block"
                          style={{ verticalAlign: 'super', padding: '2px 0' }}
                        >
                          {checked ? 'On' : 'Off'}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
        {showPop ? (
          <Modal
            className="discModal customModal customModal--w500"
            show={showPop}
            animation={false}
            onHide={() => this.handleClose()}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <div className="modal-hdr d-flex">
              <h5>
                <Modal.Title>2-Steps Verification</Modal.Title>
              </h5>
              <Modal.Header
                closeButton
                className="border-0"
              />
            </div>
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3">
                  <p
                    id="qr3"
                    className="modal-title text-center"
                  >
                    <img
                      id="qr"
                      src={qrCode && qrCode}
                      className="modal-title text-center"
                      alt=""
                    />
                  </p>
                  <Col
                    xs="12"
                    className="p-0"
                  >
                    <div className="form-group">
                      <Label className="pb-2">Verification code</Label>
                      <input
                        name="code"
                        type="text"
                        className="form-control"
                        id="code"
                        value={code}
                        onChange={(ev) => this.handleChange(ev)}
                      />
                      <div className="error">{codeErr}</div>
                    </div>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => this.verification()}
                    >
                      Verify
                    </button>
                  </Col>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </Row>
    );
  }
}
export default Securitytab;
