/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["deleteIcon", "expireLink", "deactivate", "loginIcon"]
}] */
/* eslint-disable */
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import cookies from 'js-cookie';
import { Modal, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Row, Col, Form, FormGroup, Label } from 'reactstrap';
import axios from 'axios';
import * as URL from '../../constants/constants';
import '../../assets/scss/layouts/pages/_home.scss';
import Icon from '../shared/icomoon';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import { truncate } from '../common/Utilities';
import SearchField from '../common/SearchField';
import Loader from '../common/loader/Loader';

const optionStatus = [
  { value: 'Request Information', label: 'Request Information' },
  { value: 'Request Introduction', label: 'Request Introduction' },
];

const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Username', key: 'username' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Website', key: 'website' },
];

class AuditTrail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateErr: false,
      endDateErr: false,
      showSingleRecordModal: false,
      loading: false,
      offset: 0,
      data: [],
      singleRecord: '',
      orgdata: [],
      perPage: 20,
      pagination: 0,
      audit: '',
      pageCount: 0,
      status: '',
      count: 0,
      fcounts: '',
      scounts: '',
      sortState: 'requestor',
      arrowState: 'asc',
      role: '',
      errorMsg: '',
      singleRecordShowPop: false,
      exportXLSShowPop: false,
      redirectOut: false,
      deleteAlert: false,
      ID: '',
      MAIL: '',
      movePage401: false,
      page: 0,
      exportData: [],
      listofRecords: [],
      start_date: '',
      end_date: '',
      current_date: this.formatDate(moment(new Date())),
      tableWrapperHt: 500,
      pageYmargin: 90,
    };
    this.getTableHeight = this.getTableHeight.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.csvdownloadRef = React.createRef(null);
  }

  componentDidMount() {
    const { addEventListener } = window;
    addEventListener('load', () => this.getTableHeight());
    addEventListener('resize', () => this.getTableHeight());
  }

  componentDidUpdate(prevProps, prevState) {
    const { innerHeight } = window;
    if (innerHeight !== prevState.innerHeight) {
      this.getTableHeight();
    }
  }

  componentWillUnmount() {
    const { removeEventListener } = window;
    removeEventListener('load', () => this.getTableHeight());
    removeEventListener('resize', () => this.getTableHeight());
  }

  handleStartDateChange(date) {
    this.setState({ startDateErr: false });
    this.setState({ start_date: date });
  }
  handleEndDateChange(date) {
    this.setState({ endDateErr: false });
    this.setState({ end_date: date });
  }
  handleCloseSingleRecordModal() {
    this.setState({ showSingleRecordModal: false });
  }
  handleShowSingleRecordModal(singleRecordData) {
    this.setState({ singleRecord: singleRecordData });
    this.setState({ showSingleRecordModal: true });
  }

  getTableHeight() {
    const { pageYmargin } = this.state;
    const { innerHeight, document } = window;
    const headerHt = document?.getElementById('HeaderWrap')?.offsetHeight;
    const footrHt = document?.getElementById('adminFooter')?.offsetHeight;
    const admFiltrTabHt =
      document.getElementById('adminFilterTab').offsetHeight;
    const tableWrapperHt =
      innerHeight - (headerHt + footrHt + pageYmargin + admFiltrTabHt);
    this.setState({ innerHeight, tableWrapperHt });
  }

  downloadXLSFile() {
    const { start_date, end_date, loading } = this.state;
    this.setState({ loading: true });
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    if (start_date !== '' && end_date !== '') {
      axios
        .get(
          `${URL.EXPORT_DATA}?start_date=${start_date}&end_date=${end_date}`,
          config
        )
        .then((data) => {
          if (data.data.message == 'Record not found') {
            alert('The selected dates donot have any Record');
            this.setState({ loading: false });
          } else {
            this.setState({ exportData: data.data.data });
            setTimeout(() => {
              this.csvdownloadRef.current.link.click();
              this.setState({ exportXLSShowPop: false });
              this.setState({ start_date: '' });
              this.setState({ end_date: '' });
            }, 2000);
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } else if (start_date !== '' && end_date === '') {
      this.setState({ endDateErr: true });
      this.setState({ loading: false });
    } else if (start_date === '' && end_date !== '') {
      this.setState({ startDateErr: true });
      this.setState({ loading: false });
    } else if (start_date === '' && end_date === '') {
      this.setState({ startDateErr: true });
      this.setState({ endDateErr: true });
      this.setState({ loading: false });
    }
  }
  handleShowExportXLSModal() {
    this.setState({ exportXLSShowPop: true });
  }
  showLoader() {
    this.setState({ loading: true });
  }
  hideLoader() {
    this.setState({ loading: false });
  }

  handleSingleRecord(id) {
    console.log(id);
    this.showLoader();
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    axios
      .get(`${URL.GET_AUDIT_BY_ID}?id=${id}`, config)
      .then((res) => {
        if (res.data.audit) {
          console.log(res.data.audit);
          this.setState({ singleRecordShowPop: true });
          this.handleShowSingleRecordModal(res.data.audit);
          this.hideLoader();
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }
  handleClose() {
    this.setState({ exportXLSShowPop: false, start_date: '', end_date: '' });
    // this.setState({ start_date: '' });
    // this.setState({ end_date: '' });
  }
  componentDidMount() {
    const { history } = this.props;
    if (cookies.get('role') === 'Admin') {
      this.get_audit_detail();
    }
  }

  get_audit_detail() {
    const { offset, perPage, pagination, arrowState, sortState, page } =
      this.state;
    this.showLoader();
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    axios
      .get(
        `${URL.AUDIT_DETAIL}?page=${pagination}&sort=${sortState}&order=${arrowState}`,
        config
      )
      .then((res) => {
        if (res) {
          const { data } = res.data;
          const slice =
            pagination === '' ? data.slice(offset, offset + perPage) : data;
          this.setState({
            data: data,
            count: res.data.count,
            fcounts: res.data.fcount,
            scounts: res.data.scount,
            pageCount: Math.ceil(res.data.count / perPage),
            orgdata: data,
            data: slice,
            errorMsg: '',
          });
          this.hideLoader();
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  sortData(val) {
    const { audit, status, arrowState, sortState } = this.state;
    let sortOrder = arrowState;
    if (sortState === val) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState({ sortState: val, arrowState: sortOrder }, () => {
      if (audit || status.value) {
        this.setState({ pagination: 0 }, () => {
          this.getSearch();
        });
      } else {
        this.get_audit_detail();
      }
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join(' / ');
  }

  handleSearch(ev) {
    this.setState({ pagination: 0, audit: ev.target.value }, () => {
      if (ev.target.value) {
        this.getSearch();
      } else {
        this.get_audit_detail();
      }
    });
  }

  getSearch() {
    this.setState({ loading: true });
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    const {
      audit,
      offset,
      perPage,
      pagination,
      status,
      sortState,
      arrowState,
    } = this.state;
    const request_type = status.value;
    const data2 = {
      audit,
      page: pagination,
      sort: sortState,
      order: arrowState,
      request_type: request_type,
    };
    axios
      .post(URL.SEARCH_AUDIT, data2, config)
      .then((res) => {
        if (res.data.success && res.data.message !== 'No Record Found') {
          const { data } = res.data;
          const slice =
            pagination === '' ? data.slice(offset, offset + perPage) : data;
          this.setState({
            count: res.data.count,
            fcounts: res.data.fcount,
            scounts: res.data.scount,
            errorMsg: '',
            pageCount: Math.ceil(res.data.count / perPage),
            orgdata: res.data.data,
            data: slice,
          });
          this.setState({ loading: false });
        } else if (res.data.success && res.data.message === 'No Record Found') {
          this.setState({
            pageCount: 0,
            data: [],
            orgdata: [],
            loading: false,
            errorMsg: res.data.message,
          });
        }
      })
      .catch(() => {
        this.setState({ data: [], loading: false });
      });
  }

  handleStatus(values) {
    this.setState({ status: values }, () => {
      if (values.value) {
        this.setState({ pagination: 0 }, () => {
          this.getSearch();
        });
      } else {
        this.get_audit_detail();
      }
    });
  }

  handlePageClick(e) {
    const { perPage, audit } = this.state;
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    this.setState({ offset, pagination: e.selected }, () => {
      this.loadMoreData();
      if (audit) {
        this.getSearch();
      } else {
        this.get_audit_detail();
      }
    });
  }

  loadMoreData() {
    const { audit, offset, perPage } = this.state;
    const data = audit;
    const slice = data.slice(offset, offset + perPage);
    this.setState({ pageCount: Math.ceil(data.length / perPage), data: slice });
  }

  cancelDelete() {
    this.setState({ deleteAlert: false });
  }
  handleCloseDelete() {
    let header;
    if (cookies.get().loginToken1) {
      config;
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    const { ID } = this.state;
    this.setState({ loading: true });
    axios
      .delete(`${URL.DELETE_AUDIT_RECORD}?id=${ID}`, config)
      .then((res) => {
        if (res) {
          this.setState({ loading: false });
          this.get_audit_detail();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
    this.setState({ deleteAlert: false });
  }
  deleteIcon(ev, id) {
    ev.stopPropagation();
    this.setState({
      deleteAlert: 'Do you want to delete this request information?',
      ID: id,
    });
  }

  render() {
    const {
      showSingleRecordModal,
      data,
      singleRecord,
      role,
      user,
      audit,
      status,
      offset,
      perPage,
      loading,
      pageCount,
      singleRecordShowPop,
      exportXLSShowPop,
      errorMsg,
      redirectOut,
      ID,
      MAIL,
      deleteAlert,
      count,
      orgdata,
      pagination,
      fcounts,
      scounts,
      arrowState,
      sortState,
      movePage401,
      page,
      exportData,
      start_date,
      end_date,
      csvLink,
      current_date,
      tableWrapperHt,
    } = this.state;
    if (redirectOut) {
      return <Redirect to="/login" />;
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    const value = Number.parseFloat(cookies.get('currentPage'));
    return (
      <div className="content-div CustomWidth">
        {loading ? <Loader /> : ''}
        <div className="">
          <div className="homecontent">
            <div
              id="adminFilterTab"
              className="pr-3  w-100 d-flex justify-content-between admin-filter"
            >
              <div className="siteFilter d-flex align-items-top col-gap search pt-3 pl-3">
                <SearchField
                  placeholder="Search Requestor"
                  onChange={this.handleSearch}
                  value={audit}
                />
              </div>

              <div className="commonBtn pt-3">
                <button
                  type="button"
                  className="solidBtn solidBtn__blue"
                  onClick={() => this.handleShowExportXLSModal()}
                >
                  <i className="icon-download mr-2" />
                  Export Into XLS
                </button>
              </div>
            </div>
            <div className="card-body p-0 content-div tblWraps">
              <div
                className="table-wrapper"
                style={{ height: tableWrapperHt, overflow: 'auto' }}
              >
                <table
                  className="table audit-trail-list"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr
                      style={{ position: 'sticky', top: 0, background: '#fff' }}
                    >
                      <th>h &nbsp;</th>
                      <th
                        width="10%"
                        onClick={() => this.sortData('requestor')}
                      >
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'requestor'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Requestor
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th
                        width="24%"
                        onClick={() => this.sortData('subject')}
                      >
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'subject'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Subject
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th
                        // width="11%"
                        onClick={() => this.sortData('other')}
                      >
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'other'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Message
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th
                        // width="11%"
                        onClick={() => this.sortData('contact')}
                      >
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'contact'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Intro To
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th
                        width="9%"
                        onClick={() => this.sortData('request_type')}
                      >
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'request_type'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Type
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th
                        width="10%"
                        onClick={() => this.sortData('created_at')}
                      >
                        <span
                          className={
                            arrowState === 'asc' &&
                            sortState === 'created_at'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Date Submitted
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ? data.map((item, index) => {
                          const date = new Date(item.created_at);
                          return (
                            <tr
                              onClick={() => this.handleSingleRecord(item.id)}
                              key={item.id}
                            >
                              <td>&nbsp;</td>
                              <td>{item.requestor}</td>
                              <td>{item.subject}</td>
                              <td>{item.other ? truncate(item.other) : ''}</td>
                              <td>{item.contact}</td>
                              <td>{item.request_type}</td>
                              <td>{this.formatDate(date)}</td>
                              <td className="actions">
                                <span
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title="Delete"
                                >
                                  <Icon
                                    icon="trash"
                                    size={18}
                                    color="#8f8f8f"
                                    onClick={(ev) =>
                                      this.deleteIcon(ev, item.id)
                                    }
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                    {errorMsg ? (
                      <tr
                        width="100%"
                        className="text-center"
                      >
                        <td
                          colSpan="9"
                          width="100%"
                        >
                          {errorMsg}
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                </table>
              </div>
              {!errorMsg ? (
                <div
                  id="adminFooter"
                  className="card-footer clearfix"
                >
                  <Row>
                    <Col
                      xs="12"
                      lg="6"
                      className="d-flex align-items-center"
                    >
                      <span className="fnt-14">
                        {`Showing ${fcounts} to ${scounts} of ${count} entries`}
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      lg="6"
                    >
                      <ReactPaginate
                        forcePage={pagination} // {Number.isNaN(value) ? 0 : value}
                        previousLabel="Previous"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={pageCount}
                        onPageChange={(e) => this.handlePageClick(e)}
                        containerClassName="pagination"
                        subcontainerClassname="pages-pagination"
                        previousLinkClassName="pagination__link"
                        nextLinkClassName="pagination__link"
                        activeClassName="active"
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                ''
              )}
            </div>
            {loading ? <Loader /> : ''}
          </div>
        </div>
        {singleRecordShowPop === true && singleRecord ? (
          <Modal
            className="formModalWrap customModal cstmModal--w500"
            show={showSingleRecordModal}
            onHide={() => this.handleCloseSingleRecordModal()}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleCloseSingleRecordModal()}
              size={10}
              color="#919EAB"
            />
            <Modal.Header closeButton>
              <div className="w-100">
                <Row>
                  <Col
                    xs="7"
                    className="form-group mb-0"
                  >
                    <div className="leftHeadText">
                      <Label className="pr-2 mb-0 modal-label">
                        Request Type
                      </Label>
                      <span className="formWrap_content">
                        {singleRecord.request_type}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs="5"
                    className="form-group mb-0"
                  >
                    <div className="rightHead text-right">
                      <Label className="modal-label pr-2 mb-0">Date</Label>
                      <span className="formWrap_content w-auto">
                        {this.formatDate(new Date(singleRecord.created_at))}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col
                  xs="12"
                  className="form-group"
                >
                  <Row>
                    <Col className="lblWrap lblWrap--width">
                      <Label className="modal-label mb-0">Subject</Label>
                    </Col>
                    <Col className="frmWrap_details">
                      <span className="formWrap_content">
                        {singleRecord.subject}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs="12"
                  className="form-group"
                >
                  <Row>
                    <Col className="lblWrap lblWrap--width">
                      <Label className="modal-label mb-0">Requestor</Label>
                    </Col>
                    <Col className="frmWrap_details">
                      <span className="formWrap_content">
                        {singleRecord.requestor}
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs="12"
                  className="form-group"
                >
                  <Row>
                    <Col
                      xs="12"
                      className="formWrap_content"
                    >
                      <Label className="modal-label mb-1">
                        What are you looking to speak with this operator about?
                      </Label>
                    </Col>
                    <Col
                      xs="12"
                      className="formWrap_content"
                    >
                      <span className="formWrap_content">
                        {singleRecord.other}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div className="buttonActions updateBtn">
                <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  onClick={() => this.handleCloseSingleRecordModal()}
                >
                  Cancel
                </button>
                <Button
                  className="btn btn-primary"
                  onClick={() => this.handleCloseSingleRecordModal()}
                >
                  OK
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {exportXLSShowPop ? (
          <Modal
            show={exportXLSShowPop}
            onHide={() => this.handleClose()}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <Modal.Header closeButton>
              <h6 className="mb-0">Export Into XLS</h6>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <FormGroup>
                      <Label className="modal-label">Start Date</Label>
                      <DatePicker
                        selected={start_date}
                        onChange={(date) => this.handleStartDateChange(date)}
                        className="form-control"
                        dateFormat="MM/dd/yyyy"
                      />
                      {this.state.startDateErr ? (
                        <span className="error">Start Date Required</span>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <FormGroup>
                      <Label className="modal-label">End Date</Label>
                      <DatePicker
                        selected={end_date}
                        className="form-control"
                        onChange={(date) => this.handleEndDateChange(date)}
                        dateFormat="MM/dd/yyyy"
                      />
                      {this.state.endDateErr ? (
                        <span className="error">End Date Required</span>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </Col>
                  <Col
                    xs="12"
                    className="buttonActions updateBtn mb-5"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-secondary mr-2"
                      onClick={() => this.handleClose()}
                    >
                      Cancel
                    </button>
                    <CSVLink
                      data={exportData}
                      filename={`${current_date}_audit_data.csv`}
                      target="_blank"
                      ref={this.csvdownloadRef}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.downloadXLSFile()}
                    >
                      Download
                    </button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        {deleteAlert ? (
          <Modal
            className="discModal  customModal customModal--w500 thanksModal"
            show={deleteAlert}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.cancelDelete()}
              size={10}
              color="#919EAB"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3 text-center">
                  <h2>{deleteAlert}</h2>
                </div>
              </div>
              <Row>
                <Col className="buttonActions col">
                  <Button
                    className="m-auto w-auto solidBtn solidBtn__blue pl-4 pr-4"
                    onClick={() => this.handleCloseDelete()}
                  >
                    Ok
                  </Button>
                  <button
                    type="button"
                    className="md-w-40 btn btn-outline-primary mr-3"
                    onClick={() => this.cancelDelete()}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default AuditTrail;
