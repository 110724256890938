/* eslint-disable */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import cookies from 'js-cookie';
import axios from 'axios';

import Scrollbars from 'react-custom-scrollbars-2';
import TableWrapper from '../common/table/TableWrapper';
import Loader from '../common/loader/Loader';
import * as URL from '../../constants/constants';
import CommonButton from '../common/button/CommonButton';
import RequestIntroModal from '../common/Modal/RequestIntroModal';
import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import AsideComponent from '../common/aside/AsideComponent';
import CommonAside from '../common/aside/CommonAside';
import Feedback from '../common/Feedback';
import {
  requestInfoModalMesage,
  reqInfoSuccessMessage,
  reqIntroSuccessMessage,
} from '../common/Utilities';
let header;
let timerId;
// const mailToCont = [];
class ContactTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counts: 0,
      loading: false,
      showAside: false,
      toggelFilter: false,
      id: '',
      user: '',
      arrowState: 'asc',
      sortState: 'first_name',
      perPage: 20,
      pagination: 0,
      clPagination: 0,
      offset: 0,
      data: [],
      searchValue: '',
      filterList: [],
      filterIndustry: [],
      filterStage: [],
      filterRegion: [],
      filterLevel: [],
      filterExperience: [],
      selectedFunctionArr: [],
      selectedIndustryArr: [],
      selectedStatusArr: [],
      selectedRegionArr: [],
      selectedLevelArr: [],
      selectedExperienceArr: [],
      selectedCheckboxes: [],
      selectAllCheckBox: [],
      selectedContactsArr: [],
      reqInfoContList: [],
      openProfDropDown: false,
      requestInfoModal: false,
      showRequestIntroModal: false,
      desc: requestInfoModalMesage(),
      subject: '',
      email: '',
      reqInfoMess: '',
      reqInfoSub: '',
      reqInfoDefMail: 'talent@generalcatalyst.com',
      opportunityError: '',
      companyError: '',
      subjError: '',
      descError: '',
      scrollHeight: 550,
      ilterCount: 0,
      hitApiAgain: true,
      activeValue: 'inactive',
      rating: '',
      feedbackSuggestion: ' ',
      showdata: false,
      hidedata: true,
      disableReqIntroMessage: false,
      showSuccessModal: false,
      successModalType: '',
      // function filter list
      functionFilterShow: false,
      filterListNew: [],
      // industry filter list
      industryFilterShow: false,
      industryFilterListNew: [],
      // Stage Filter list
      stageFilterListNew: [],
      stageFilterShow: false,
      // region Filter list
      regionFilterListNew: [],
      regionFilterShow: false,
      // level filter list
      levelFilterListNew: [],
      levelFilterShow: false,
      // experience filter list
      experienceFilterListNew: [],
      experienceFilterShow: false,
      pathName: '',
      isSelectedAll: false,
      isMobile: window.innerWidth <= 480,
      newest: false
    };
    this.handleAside = this.handleAside.bind(this);
    this.setFilterToggle = this.setFilterToggle.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.getClientData = this.getClientData.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.getFilterList = this.getFilterList.bind(this);
    this.getSelectedFilters = this.getSelectedFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleProfDropDown = this.handleProfDropDown.bind(this);
    this.handleRequestInfoModal = this.handleRequestInfoModal.bind(this);
    this.handleRequestIntroModal = this.handleRequestIntroModal.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.handleNewest = this.handleNewest.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectedContactsEmails = this.selectedContactsEmails.bind(this);
    this.submitReqInfo = this.submitReqInfo.bind(this);
    this.getSearchClient = this.getSearchClient.bind(this);
    // this.sendMail = this.sendMail.bind(this);
    // this.validateMail = this.validateMail.bind(this);
    this.handleId = this.handleId.bind(this);
    this.logOut = this.logOut.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);
    this.sendMailReqIntro = this.sendMailReqIntro.bind(this);
    this.submitReqInto = this.submitReqInto.bind(this);
    this.validateReqIntroMail = this.validateReqIntroMail.bind(this);
    this.getTableHeight = this.getTableHeight.bind(this);
    this.getScrollPosition = this.getScrollPosition.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeToggleClick = this.closeToggleClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.toggleClick = this.toggleClick.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.handleAsideDataShow = this.handleAsideDataShow.bind(this);
    this.sortData = this.sortData.bind(this);
    this.hanleSuccessModal = this.hanleSuccessModal.bind(this);
    this.handleShowHideFilters = this.handleShowHideFilters.bind(this);
    this.handleShowHideIndFilter = this.handleShowHideIndFilter.bind(this);
    this.handleShowHideStageFilter = this.handleShowHideStageFilter.bind(this);
    this.handleShowHideRegionFilter =
      this.handleShowHideRegionFilter.bind(this);
    this.handleShowHideLevelFilter = this.handleShowHideLevelFilter.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleShowHideExperienceFilter =
      this.handleShowHideExperienceFilter.bind(this);

    this.getScrollBarRef = React.createRef();
  }

  componentDidMount() {
    const userType = cookies.get('role');
    const pathName = window.location.pathname;
    this.setState({
      userRole: userType,
      pathName,
    });
    window.addEventListener('resize', () => this.getTableHeight());
    this.getClientData();
    this.getFilterList();
    // NOTE: getScrollPosition is testing, if OK this will be removed
    // window.addEventListener('scroll', () => this.handlePageScroll());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.getTableHeight());
    // NOTE: getScrollPosition is testing, if OK this will be removed
    // window.removeEventListener('scroll', () => this.handlePageScroll());
  }

  handleChange(ev) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  handleNext() {
    this.setState({ showdata: true, hidedata: false });
  }

  handleFeedback() {
    const { rating, feedbackSuggestion } = this.state;
    const data = {
      rating,
      feedback_suggestion: feedbackSuggestion,
    };
    axios
      .post(`${URL.ADD_FEEDBACK}`, data)
      .then((res) => {
        if (res.data) {
          this.setState({
            activeValue: 'inactive',
            rating: '',
            feedbackSuggestion: '',
            hidedata: true,
            showdata: false,
          });
        }
      })
      .catch(() => {
        this.setState({ activeValue: 'inactive' });
      });
  }

  handleInputChange(event, from = '') {
    if (from === 'reqInfo' && event.target.value.lenght >= 800) {
      event.preventDefault();
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleAside(event) {
    const idClicked = event.currentTarget.id;
    this.setState((prevState) => ({
      showAside: !prevState.showAside,
      id: idClicked,
    }));
  }

  handleAsideDataShow(contact) {
    const idClicked = contact.id;
    this.setState((prevState) => {
      if (prevState.showAside) {
        return { id: idClicked };
      } else return { showAside: !prevState.showAside, id: idClicked };
    });
  }

  handleSearchInput(e) {
    const searchValue = e.target.value;
    const {
      selectedFunctionArr,
      selectedIndustryArr,
      selectedStatusArr,
      selectedRegionArr,
      selectedLevelArr,
      selectedExperienceArr,
    } = this.state;
    this.setState(
      {
        searchValue,
        data: [],
        pagination: 0,
      },
      () => {
        clearTimeout(timerId);
        if (searchValue.length === 0) {
          this.setState({
            isSelectedAll: false,
            selectedContactsArr: [],
            reqInfoContList: [],
            reqInfoContListArr: [],
          });
          if (
            selectedFunctionArr.length === 0 &&
            selectedIndustryArr.length === 0 &&
            selectedStatusArr.length === 0 &&
            selectedRegionArr.length === 0 &&
            selectedLevelArr.length === 0 &&
            selectedExperienceArr.length === 0
          ) {
            this.fetchMoreData();
          } else {
            // this.getSearchClient();
            timerId = setTimeout(() => this.getSearchClient(), 300);
          }
        } else {
          console.log('third else');
          // this.getSearchClient();
          timerId = setTimeout(() => this.getSearchClient(), 300);
        }
      }
    );
  }

  handleProfDropDown() {
    this.setState((prev) => ({ openProfDropDown: !prev.openProfDropDown }));
  }

  handleRequestInfoModal() {
    //  TO for future
    // const buttonClicked = event && event.currentTarget && event.currentTarget.name;
    this.setState((prev) => ({
      requestInfoModal: !prev.requestInfoModal,
      desc: requestInfoModalMesage(),
      addedContactErr: '',
      subjError: '',
    }));
  }

  handleRequestIntroModal() {
    const { data, id, showRequestIntroModal } = this.state;
    let { selectedContactsArr } = this.state;
    const selectedContact = data.filter((item) => item.id === id);
    const doesContactExist = selectedContactsArr.filter(
      (item) => item.salesforceId === selectedContact[0].cont_id
    );
    if (showRequestIntroModal) {
      selectedContactsArr = selectedContactsArr.filter(
        (item) => item.salesforceId !== selectedContact[0].cont_id
      );
    } else if (!doesContactExist.length) {
      selectedContactsArr = [
        ...selectedContactsArr,
        {
          name: selectedContact[0].cont_name,
          salesforceId: selectedContact[0].cont_id,
          status: selectedContact[0].status,
        },
      ];
    }

    this.setState({
      showRequestIntroModal: !showRequestIntroModal,
      desc: '',
      opportunityError: '',
      companyError: '',
      addedContactErr: '',
      selectedContactsArr,
      reqInfoContList: selectedContactsArr,
      reqInfoContListArr: selectedContactsArr,
    });
  }

  handleId(id) {
    this.setState({ id });
  }

  handleSwitch(ev) {
    ev.preventDefault();
    localStorage.removeItem('currentClientPage');
    const { history } = this.props;
    history.push({
      pathname: './admin',
    });
    cookies.remove('loginToken1');
    cookies.remove('googleLogin1');
    cookies.remove('email1');
    cookies.remove('role1'); // add at last don't
    cookies.remove('username1');
    cookies.remove('loginAs');
    cookies.remove('currentClientPage');

    localStorage.removeItem('acctStatus');
    localStorage.removeItem('industryStatus');
    localStorage.removeItem('stageStatus');
    localStorage.removeItem('regionStatus');
    localStorage.removeItem('levelStatus');
    localStorage.removeItem('account_Status');
    localStorage.removeItem('industry_Status');
    localStorage.removeItem('stage_Status');
    localStorage.removeItem('region_Status');
    localStorage.removeItem('level_Status');
  }
  // all below 5 methods have similarities need to merge into one

  handleShowHideFilters() {
    const { functionFilterShow } = this.state;
    let newFilterList = [];
    const {
      filterList,
      // filterIndustry,
      // filterStage,
      // filterRegion,
      // filterLevel,
    } = this.state;
    const fullFilterList = filterList;
    this.setState((prev) => ({ functionFilterShow: !prev.functionFilterShow }));
    if (functionFilterShow) {
      newFilterList = filterList.slice(0, 4);
    }
    this.setState({
      filterListNew: functionFilterShow ? newFilterList : fullFilterList,
    });
  }

  handleShowHideIndFilter() {
    const { industryFilterShow } = this.state;
    let newFilterList = [];
    const {
      filterIndustry,
      // filterStage,
      // filterRegion,
      // filterLevel,
    } = this.state;
    const fullFilterList = filterIndustry;
    this.setState((prev) => ({ industryFilterShow: !prev.industryFilterShow }));
    if (industryFilterShow) {
      newFilterList = filterIndustry.slice(0, 4);
    }
    this.setState({
      industryFilterListNew: industryFilterShow
        ? newFilterList
        : fullFilterList,
    });
  }

  handleShowHideStageFilter() {
    const { stageFilterShow } = this.state;
    let newFilterList = [];
    const {
      filterStage,
      // filterRegion,
      // filterLevel,
    } = this.state;
    const fullFilterList = filterStage;
    this.setState((prev) => ({ stageFilterShow: !prev.stageFilterShow }));
    if (stageFilterShow) {
      newFilterList = filterStage.slice(0, 4);
    }
    this.setState({
      stageFilterListNew: stageFilterShow ? newFilterList : fullFilterList,
    });
  }

  handleShowHideRegionFilter() {
    const { regionFilterShow } = this.state;
    let newFilterList = [];
    const { filterRegion } = this.state;
    const fullFilterList = filterRegion;
    this.setState((prev) => ({ regionFilterShow: !prev.regionFilterShow }));
    if (regionFilterShow) {
      newFilterList = filterRegion.slice(0, 4);
    }
    this.setState({
      regionFilterListNew: regionFilterShow ? newFilterList : fullFilterList,
    });
  }

  handleShowHideLevelFilter() {
    const { levelFilterShow } = this.state;
    let newFilterList = [];
    const { filterLevel } = this.state;
    const fullFilterList = filterLevel;
    this.setState((prev) => ({ levelFilterShow: !prev.levelFilterShow }));
    if (levelFilterShow) {
      newFilterList = filterLevel.slice(0, 4);
    }
    this.setState({
      levelFilterListNew: levelFilterShow ? newFilterList : fullFilterList,
    });
  }

  handleShowHideExperienceFilter() {
    const { experienceFilterShow } = this.state;
    let newFilterList = [];
    const { filterExperience } = this.state;
    const fullFilterList = filterExperience;
    this.setState((prev) => ({
      experienceFilterShow: !prev.experienceFilterShow,
    }));
    if (experienceFilterShow) {
      newFilterList = filterExperience.slice(0, 4);
    }
    this.setState({
      experienceFilterListNew: experienceFilterShow
        ? newFilterList
        : fullFilterList,
    });
  }

  handleSelectAll(event) {
    const checkbox = event.target;
    const selectedContactsArr = [];
    if (checkbox.checked) {
      const { data } = this.state;
      console.log(data);
      data.map((item) => {
        selectedContactsArr.push({
          name: item.cont_name,
          salesforceId: item.cont_id,
          status: item.status,
        });
        return item;
      });
    }
    this.setState({
      selectedContactsArr: checkbox.checked ? selectedContactsArr : [],
      reqInfoContListArr: checkbox.checked ? selectedContactsArr : [],
      reqInfoContList: checkbox.checked ? selectedContactsArr : [],
      isSelectedAll: checkbox.checked,
    });
  }

  handleCheckbox(event, item) {
    const checkbox = event.target;
    const { selectedContactsArr } = this.state;
    if (checkbox.checked) {
      selectedContactsArr.push({
        name: item.cont_name,
        salesforceId: item.cont_id,
        status: item.status,
      });
    } else {
      const index = selectedContactsArr.findIndex(
        (x) => x.salesforceId === item.cont_id
      );
      selectedContactsArr.splice(index, 1);
    }
    this.setState({
      selectedContactsArr,
      reqInfoContListArr: selectedContactsArr,
      reqInfoContList: selectedContactsArr,
      isSelectedAll: false,
    });
  }

  getTableHeight() {
    const headeHt = document?.getElementById('HeaderWrap')?.offsetHeight;
    const innerRowHt = document?.getElementById('InnerRow')?.offsetHeight;
    const tblHeight = innerRowHt - headeHt;
    this.setState({
      scrollHeight: tblHeight,
      isMobile: window.innerWidth <= 480,
    });
  }

  getClientData(newest = false) {
    const { arrowState, sortState, perPage, offset, clPagination, isMobile } =
      this.state;
    const accountStatus = JSON.parse(localStorage.getItem('acctStatus'));
    const industryStatus = JSON.parse(localStorage.getItem('industryStatus'));
    const stageStatus = JSON.parse(localStorage.getItem('stageStatus'));
    const regionStatus = JSON.parse(localStorage.getItem('regionStatus'));
    const levelStatus = JSON.parse(localStorage.getItem('levelStatus'));
    const pathName = window.location.pathname;

    const urlData = {
      page: clPagination,
      sort: sortState,
      order: arrowState,
      newest: newest,
      historical_introduction: pathName.includes('/historicalIntro')
        ? pathName
        : '',
    };
    this.showLoader();
    if (
      accountStatus ||
      industryStatus ||
      stageStatus ||
      regionStatus ||
      levelStatus
    ) {
      this.getSearchClient();
    } else {
      axios
        .post(URL.SALESFORCE_CONTACT, urlData)
        .then((res) => {
          if (res.data.data && res.data.data.length !== 0) {
            const { data } = res.data;
            const slice =
              clPagination === '' ? data.slice(offset, offset + perPage) : data;
            this.setState((prev) => ({
              counts: res.data.count,
              fcounts: data.fcount,
              scounts: data.scount,
              role: cookies.get('role1'),
              errorMsg: '',
              pageCount: Math.ceil(data.count / perPage),
              orgdata: data.data,
              data: slice, // res.data.data
              clPagination: prev.clPagination + 1,
              pagination: 0,
              id: data[0].id,
              showAside: !isMobile,
            }));
            this.hideLoader();
          } else {
            this.setState({ errorMsg: 'No Record Found', loading: false, data: '', showAside: false });
            this.hideLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            err &&
            err.response &&
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            this.setState({ movePage401: true, loading: false });
          }
          this.setState({ data: [], loading: false, errorMsg: '' });
        })
        .finally(() => {
          this.getTableHeight();
        });
    }
  }

  setFilterToggle() {
    this.setState((prevState) => ({ toggelFilter: !prevState.toggelFilter }));
  }

  getFilterList() {
    axios
      .get(URL.FILTER_DROPDOWN)
      .then((res) => {
        if (res.data.success) {
          const {
            function: filterList,
            industry,
            status,
            region,
            level,
            experience,
          } = res.data;
          this.setState({
            filterListNew:
              filterList.length > 4 ? filterList.slice(0, 4) : filterList,
            industryFilterListNew:
              industry.length > 4 ? industry.slice(0, 4) : industry,
            stageFilterListNew: status.length > 4 ? status.slice(0, 4) : status,
            regionFilterListNew:
              region.length > 4 ? region.slice(0, 4) : region,
            levelFilterListNew: level.length > 4 ? level.slice(0, 4) : level,
            experienceFilterListNew:
              experience.length > 4 ? experience.slice(0, 4) : experience,
            filterList,
            filterIndustry: industry,
            filterStage: status,
            filterRegion: region,
            filterLevel: level,
            filterExperience: experience,
          });
          this.hideLoader();
        } else {
          this.hideLoader();
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          this.setState({ movePage401: true, loading: false });
        }
      })
      .finally(() => {
        this.getTableHeight();
      });
  }

  getSelectedFilters(event, selectedFilter, item) {
    const {
      selectedFunctionArr,
      selectedIndustryArr,
      selectedStatusArr,
      selectedRegionArr,
      selectedCheckboxes,
      selectedLevelArr,
      selectedExperienceArr,
    } = this.state;
    let selectedChkBx;
    if (selectedFilter === 'Function') selectedChkBx = selectedFunctionArr;
    else if (selectedFilter === 'Preferred Industry')
      selectedChkBx = selectedIndustryArr;
    else if (selectedFilter === 'Status') selectedChkBx = selectedStatusArr;
    else if (selectedFilter === 'Current Region')
      selectedChkBx = selectedRegionArr;
    else if (selectedFilter === 'Level') selectedChkBx = selectedLevelArr;
    else if (selectedFilter === 'Industry Experience')
      selectedChkBx = selectedExperienceArr;
    else selectedChkBx = selectedCheckboxes;

    const checkbox = event.target;

    if (checkbox.checked) {
      const cehckedId = { name: item.cont_name, salesforceId: item.cont_id };
      selectedChkBx.push(cehckedId);
      this.setState({ selectedCheckboxes: selectedChkBx }, () => {
        if (selectedFilter !== 'Contact') {
          this.getSearchClient();
        }
      });
    } else {
      const isSelected = selectedChkBx.some(
        (d) => d.salesforceId === item.cont_id
      );
      const index = selectedChkBx.findIndex(
        (d) => d.salesforceId === item.cont_id
      );
      if (isSelected && index > -1) {
        selectedChkBx.splice(index, 1);
        this.setState({ selectedCheckboxes: selectedChkBx }, () => {
          if (selectedFilter !== 'Contact') {
            this.getSearchClient();
          }
        });
      }
    }
    if (selectedFilter === 'Contact') {
      this.selectedContactsEmails();
    }
  }

  getFilters(event, selectedFilter) {
    const {
      selectedFunctionArr,
      selectedIndustryArr,
      selectedStatusArr,
      selectedRegionArr,
      selectedCheckboxes,
      selectedLevelArr,
      selectedContactsArr,
      selectedExperienceArr,
    } = this.state;
    let selectedChkBx;
    if (selectedFilter === 'Function') selectedChkBx = selectedFunctionArr;
    else if (selectedFilter === 'Preferred Industry')
      selectedChkBx = selectedIndustryArr;
    else if (selectedFilter === 'Status') selectedChkBx = selectedStatusArr;
    else if (selectedFilter === 'Current Region')
      selectedChkBx = selectedRegionArr;
    else if (selectedFilter === 'Level') selectedChkBx = selectedLevelArr;
    else if (selectedFilter === 'Contact') selectedChkBx = selectedContactsArr;
    else if (selectedFilter === 'Industry Experience')
      selectedChkBx = selectedExperienceArr;
    else selectedChkBx = selectedCheckboxes;

    const checkbox = event.target;
    const checkboxValue = checkbox.name;

    if (checkbox.checked) {
      selectedChkBx.push(checkboxValue);
      this.setState(
        {
          selectedCheckboxes: selectedChkBx,
          hitApiAgain: true,
          pagination: 0,
          counts: 0,
          data: [],
        },
        () => {
          if (selectedFilter !== 'Contact') {
            this.getSearchClient();
          }
        }
      );
    } else {
      const index = selectedChkBx.indexOf(checkboxValue);
      if (index > -1) {
        selectedChkBx.splice(index, 1);
        this.setState(
          {
            selectedCheckboxes: selectedChkBx,
            hitApiAgain: true,
            pagination: 0,
            counts: 0,
            data: [],
          },
          () => {
            if (selectedFilter !== 'Contact') {
              this.getSearchClient();
            }
          }
        );
      }
    }
  }

  getSearchClient() {
    const {
      data,
      searchValue,
      perPage,
      pagination,
      sortState,
      arrowState,
      accountStatus,
      industryStatus,
      stageStatus,
      regionStatus,
      levelStatus,
      selectedFunctionArr,
      selectedIndustryArr,
      selectedStatusArr,
      selectedRegionArr,
      selectedLevelArr,
      selectedExperienceArr,
      loading,
    } = this.state;
    if (JSON.parse(localStorage.getItem('accountStatus'))) {
      this.state.accountStatus = JSON.parse(
        localStorage.getItem('accountStatus')
      );
    } else {
      accountStatus;
    }
    if (JSON.parse(localStorage.getItem('industryStatus'))) {
      this.state.industryStatus = JSON.parse(
        localStorage.getItem('industryStatus')
      );
    } else {
      industryStatus;
    }
    if (JSON.parse(localStorage.getItem('stageStatus'))) {
      this.state.stageStatus = JSON.parse(localStorage.getItem('stageStatus'));
    } else {
      stageStatus;
    }
    if (JSON.parse(localStorage.getItem('regionStatus'))) {
      this.state.regionStatus = JSON.parse(
        localStorage.getItem('regionStatus')
      );
    } else {
      regionStatus;
    }
    if (JSON.parse(localStorage.getItem('levelStatus'))) {
      this.state.levelStatus = JSON.parse(localStorage.getItem('levelStatus'));
    } else {
      levelStatus;
    }
    const data2 = {
      user: searchValue,
      page: pagination,
      function: selectedFunctionArr.toString(),
      industry: selectedIndustryArr.toString(),
      region: selectedRegionArr.toString(),
      level: selectedLevelArr.toString(),
      status: selectedStatusArr.toString(),
      experience: selectedExperienceArr.toString(),
      sort: sortState,
      order: arrowState,
    };
    if (loading) return;
    this.showLoader();
    // console.log('data222: ', data2)
    axios
      .post(URL.SEARCH_CLIENT, data2)
      .then((res) => {
        // console.log('res: ', res.data.count);
        if (res.data.success && res.data.data) {
          this.setState({
            counts: res.data.count,
            fcounts: res.data.fcount,
            scounts: res.data.scount,
            role: cookies.get('role1'),
            errorMsg: '',
            pageCount: Math.ceil(res.data.count / perPage),
            orgdata: res.data.data,
            // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
            data: [...data, ...res.data.data],
            pagination: pagination + 1,
          });
          this.hideLoader();
        } else {
          this.setState({
            errorMsg: 'Data Not Found',
            loading: false,
          });
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          this.setState({
            movePage401: true,
            data: [],
            counts: 0,
            errorMsg: 'Data Not Found',
            loading: false,
          });
        }
      })
      .finally(() => {
        const selectedFilter = document.getElementById('filterGroup');
        const seletedFilterCount =
          selectedFilter &&
          selectedFilter.children &&
          selectedFilter.children.length;
        this.setState({
          filterCount: seletedFilterCount,
        });
      });
  }

  getScrollPosition() {
    const {
      selectedFunctionArr,
      selectedIndustryArr,
      selectedStatusArr,
      selectedRegionArr,
      selectedLevelArr,
      selectedExperienceArr,
      data,
      counts,
      searchValue,
    } = this.state;
    const { current } = this.getScrollBarRef;
    const { scrollTop, clientHeight, scrollHeight } = current.getValues();
    this.setState({ scrollTop });
    if (
      (scrollHeight - (scrollTop + clientHeight) <= 20 ||
        !(scrollHeight - (scrollTop + clientHeight))) &&
      data.length < counts
    ) {
      if (
        selectedFunctionArr.length === 0 &&
        selectedIndustryArr.length === 0 &&
        selectedStatusArr.length === 0 &&
        selectedRegionArr.length === 0 &&
        selectedLevelArr.length === 0 &&
        selectedExperienceArr.length === 0 &&
        searchValue === ''
      ) {
        this.fetchMoreData();
      } else {
        this.getSearchClient();
      }
    }
  }

  sortData(val) {
    const { arrowState, sortState, user, focusareaStatus } = this.state;
    const accountStatus = JSON.parse(localStorage.getItem('acctStatus'));
    const industryStatus = JSON.parse(localStorage.getItem('industryStatus'));
    const stageStatus = JSON.parse(localStorage.getItem('stageStatus'));
    const regionStatus = JSON.parse(localStorage.getItem('regionStatus'));
    const levelStatus = JSON.parse(localStorage.getItem('levelStatus'));

    let sortOrder = arrowState;
    let vall;
    this.setState({
      hitApiAgain: true,
      showAside: false,
      clPagination: 0,
      counts: 0,
      data: [],
    });
    if (val) {
      if (val === 'contact') {
        vall = 'first_name';
      } else if (val === 'company') {
        vall = 'account_name';
      } else if (val === 'functions') {
        vall = 'function';
      } else if (val === 'previous Companies') {
        vall = 'previous_companies';
      } else if (val === 'Preffered Roll') {
        vall = 'status';
      } else vall = val;
    }
    if (sortState === vall) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.setState({ sortState: vall, arrowState: sortOrder }, () => {
      if (
        user ||
        accountStatus ||
        industryStatus ||
        stageStatus ||
        regionStatus ||
        levelStatus ||
        focusareaStatus
      ) {
        this.setState({ user });
        this.getSearchClient();
      } else {
        // this.getClientData();
      }
    });
  }

  toggleClick() {
    this.setState({ activeValue: 'active' });
  }

  closeToggleClick() {
    this.setState({ activeValue: 'inactive', hidedata: true, showdata: false });
  }

  loadMoreData() {
    const { orgdata, offset, perPage } = this.state;
    const data = orgdata;
    const slice = data.slice(offset, offset + perPage);
    this.setState({ pageCount: Math.ceil(data.length / perPage), data: slice });
  }

  async fetchMoreData() {
    const { loading, data, pagination, arrowState, sortState, hitApiAgain } =
      this.state;
    console.log({ loading, hitApiAgain });
    if (loading) return; // Prevent multiple simultaneous requests
    if (!hitApiAgain) return;

    try {
      this.setState({ loading: true });
      const apiPayload = {
        order: arrowState,
        sort: sortState,
        page: pagination + 1,
      };
      const response = await axios.post(URL.SALESFORCE_CONTACT, apiPayload);
      const updatedData = [...data, ...response.data.data];
      this.setState((prev) => ({
        data: updatedData,
        pagination: prev.pagination + 1,
        clPagination: 0,
      }));
    } catch (error) {
      console.error('Error fetching more data:', error);
    } finally {
      this.setState({ loading: false });
    }
  }

  logOut() {
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    this.setState({ loading: true });
    axios
      .delete(URL.SIGN_OUT, config)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          cookies.remove('loginToken');
          cookies.remove('email');
          cookies.remove('role'); // add at last don't
          cookies.remove('loginToken1');
          cookies.remove('email1');
          cookies.remove('role1'); // add at last don't
          cookies.remove('loginAs');
          cookies.remove('currentPage');
          cookies.remove('currentClientPage');
          cookies.set('tabChange', 'no');
          localStorage.removeItem('acctStatus');
          localStorage.removeItem('industryStatus');
          localStorage.removeItem('stageStatus');
          localStorage.removeItem('regionStatus');
          localStorage.removeItem('levelStatus');

          localStorage.removeItem('account_Status');
          localStorage.removeItem('industry_Status');
          localStorage.removeItem('stage_Status');
          localStorage.removeItem('region_Status');
          localStorage.removeItem('level_Status');
          this.setState({ redirectLogin: true });
          window.location.reload();
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        const apiStatus = err.response.status;
        if (apiStatus === 401 || apiStatus === 500) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  validateReqIntroMail() {
    const { selectedContactsArr, otherMsg } = this.state;
    let addedContactErr;
    let otherMsgErr;
    if (selectedContactsArr && selectedContactsArr.length <= 0) {
      addedContactErr = 'Please select atleast one contact';
    }
    if (!otherMsg) {
      otherMsgErr = 'Required';
    }
    if (addedContactErr || otherMsgErr) {
      this.setState({ addedContactErr, otherMsgErr });
      return false;
    }
    return true;
  }

  // TODO merge it with above sendEmail common requestIntro requestInfo

  sendMailReqIntro() {
    const isValid = this.validateReqIntroMail();
    if (isValid) {
      this.setState({ companyError: '', opportunityError: '' });
      const {
        company,
        opportunity,
        otherMsg,
        id,
        data: userData,
        selectedContactsArr,
      } = this.state;
      const currentSelected = userData.filter(
        (item) => item.id === parseInt(id, 10)
      );
      const contacts = [];
      selectedContactsArr.forEach((item) => {
        contacts.push([item.name, item.salesforceId]);
      });
      // Request an intro to {contactName} at {accountName}
      const subject = `Requesting Intro to ${currentSelected[0].cont_name} at ${currentSelected[0].account_name}`;
      const data = {
        subject,
        intro: otherMsg,
        company,
        opportunity,
        id,
        message: 'Message',
        about_company: company,
        about_opportunity: opportunity,
        other: otherMsg,
        request_type: 'Request Introduction',
        sender: currentSelected[0].cont_name,
        contact: contacts,
      };
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      axios
        .post(URL.INTRO_MAIL, data, config)
        .then((res) => {
          axios.post(URL.ADD_AUDIT_TRAIL, data, config);
          if (res.data.success && res.data.message !== 'Missing Parameter') {
            this.setState({
              showPop: '',
              showOther: true,
              company: '',
              opportunity: '',
              opportunityError: '',
              otherMsg: '',
              // id: '',
              successModalType: 'reqIntro',
              // showAside: false,
              reqInfoContList: [],
              selectedContactsArr: [],
              reqInfoContListArr: [],
              otherMsgErr: undefined,
              addedContactErr: undefined,
              showRequestIntroModal: false,
              showSuccessModal: true,
            }); // showOther: true
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            this.setState({ movePage401: true, loading: false });
          }
        })
        .finally(() => {});
    }
  }

  selectedContactsEmails() {
    const { selectedContactsArr, data } = this.state;
    const selectedCont = [];
    const selectedContactsArrr = [];
    data.forEach((item) => {
      if (selectedContactsArr.some((d) => d.salesforceId === item.cont_id)) {
        const newObj = {
          name: item.cont_name,
          email: item.cont_email,
          salesforceId: item.cont_id,
        };
        const newArr = [item.cont_name, item.cont_id];
        selectedContactsArrr.push(newArr);
        selectedCont.push(newObj);
      }
    });
    this.setState({
      reqInfoContList: selectedCont,
      reqInfoContListArr: selectedContactsArrr,
    });
  }

  hanleSuccessModal() {
    this.setState((prev) => ({
      showSuccessModal: !prev.showSuccessModal,
      successModalType: '',
    }));
  }

  submitReqInfo(e) {
    e.preventDefault();
    this.sendMail();
  }

  submitReqInto(e) {
    e.preventDefault();
    this.sendMailReqIntro();
    // this.setState({
    //   reqInfoContList: [],
    //   selectedContactsArr: [],
    //   selectedCheckboxes: [],
    // });
  }

  clearFilters(selectedFilter) {
    this.setState(
      {
        [selectedFilter]: [],
        pagination: 0,
        data: [],
      },
      () => {
        this.getSearchClient();
      }
    );
  }

  clearAllFilters() {
    this.setState(
      {
        selectedFunctionArr: [],
        selectedIndustryArr: [],
        selectedStatusArr: [],
        selectedRegionArr: [],
        selectedLevelArr: [],
        selectedCheckboxes: [],
        pagination: 0,
        data: [],
        filterCount: 0,
      },
      () => {
        this.getClientData();
      }
    );
  }

  handleNewest() {
    const { newest } = this.state;
    this.setState(
      { newest: !newest, clPagination: 0 },
      () => {
        this.getClientData(!newest); // Passes the opposite value of newest
      }
    );
  }

  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  render() {
    const {
      showAside,
      id,
      toggelFilter,
      loading,
      counts,
      data,
      searchValue,
      filterList,
      filterIndustry,
      filterStage,
      filterRegion,
      filterLevel,
      selectedFunctionArr,
      selectedIndustryArr,
      selectedStatusArr,
      selectedRegionArr,
      selectedLevelArr,
      desc,
      selectAllCheckBox,
      selectedContactsArr,
      reqInfoContList,
      showRequestIntroModal,
      reqInfoDefMail,
      opportunityError,
      companyError,
      scrollHeight,
      filterCount,
      userRole,
      activeValue,
      feedbackSuggestion,
      showdata,
      hidedata,
      showSuccessModal,
      addedContactErr,
      successModalType,
      functionFilterShow,
      filterListNew,
      industryFilterShow,
      industryFilterListNew,
      pathName,
      // Stage Filter list
      stageFilterListNew,
      stageFilterShow,
      // region Filter list
      regionFilterListNew,
      regionFilterShow,
      // level filter list
      levelFilterListNew,
      levelFilterShow,
      sortState,
      isSelectedAll,
      experienceFilterShow,
      filterExperience,
      selectedExperienceArr,
      experienceFilterListNew,
      isMobile,
    } = this.state;

    return (
      <>
        {loading && <Loader />}
        <GCPrivatePageLayout
          handleFeedback={this.toggleClick}
          selectedFunctionArr={selectedFunctionArr}
          selectedIndustryArr={selectedIndustryArr}
          selectedStatusArr={selectedStatusArr}
          selectedRegionArr={selectedRegionArr}
          selectedLevelArr={selectedLevelArr}
          selectedExperienceArr={selectedExperienceArr}
          toggelFilter={toggelFilter}
          sortState={sortState}
          setFilterToggle={this.setFilterToggle}
          handleNewest={this.handleNewest}
          searchValue={searchValue}
          handleSearchInput={this.handleSearchInput}
          clearFilters={this.clearFilters}
          filterCount={filterCount}
          activeValue={activeValue}
          functionFilterShow={functionFilterShow}
          pathName={pathName}
          isMobile={isMobile}
          showAside={showAside}
        >
          <div
            id="tblWrap"
            className="tblWraps"
            style={{ height: `${scrollHeight}px` }}
          >
            {showAside && id && (
              <AsideComponent
                tableData={data}
                showAside={showAside}
                handleAside={this.handleAside}
                id={id}
                handleRequestInfoModal={this.handleRequestIntroModal}
                counts={counts}
                handleId={this.handleId}
              />
            )}
            {toggelFilter && (
              <CommonAside
                tableData={data}
                setFilterToggle={this.setFilterToggle}
                toggelFilter={toggelFilter}
                filterList={filterListNew}
                filterIndustry={industryFilterListNew}
                filterStage={stageFilterListNew}
                filterRegion={regionFilterListNew}
                filterLevel={levelFilterListNew}
                filterExperience={experienceFilterListNew}
                getSelectedFilters={this.getFilters}
                selectedFunctionArr={selectedFunctionArr}
                selectedIndustryArr={selectedIndustryArr}
                selectedStatusArr={selectedStatusArr}
                selectedRegionArr={selectedRegionArr}
                selectedLevelArr={selectedLevelArr}
                selectedExperienceArr={selectedExperienceArr}
                clearAllFilter={this.clearAllFilters}
                handleShowHideFilters={this.handleShowHideFilters}
                functionFilterShow={functionFilterShow}
                handleShowHideIndFilter={this.handleShowHideIndFilter}
                industryFilterShow={industryFilterShow}
                // Stage Filter list
                stageFilterShow={stageFilterShow}
                handleShowHideStageFilter={this.handleShowHideStageFilter}
                // region Filter list
                regionFilterShow={regionFilterShow}
                handleShowHideRegionFilter={this.handleShowHideRegionFilter}
                // level filter list
                levelFilterShow={levelFilterShow}
                handleShowHideLevelFilter={this.handleShowHideLevelFilter}
                // experience filter list
                experienceFilterShow={experienceFilterShow}
                handleShowHideExperienceFilter={
                  this.handleShowHideExperienceFilter
                }
              />
            )}
            <Scrollbars
              style={{ width: '100%', height: '100%', marginRight: '10px' }}
              onScroll={this.getScrollPosition}
              ref={this.getScrollBarRef}
            >
              <TableWrapper
                selectedFunctionArr={selectedFunctionArr}
                selectedIndustryArr={selectedIndustryArr}
                selectedStatusArr={selectedStatusArr}
                selectedRegionArr={selectedRegionArr}
                selectedLevelArr={selectedLevelArr}
                selectedExperienceArr={selectedExperienceArr}
                toggelFilter={toggelFilter}
                sortState={sortState}
                setFilterToggle={this.setFilterToggle}
                handleAside={this.handleAside}
                handleAsideDataShow={this.handleAsideDataShow}
                showAside={showAside}
                id={id}
                tableData={data}
                filterList={filterList}
                filterIndustry={filterIndustry}
                filterStage={filterStage}
                filterRegion={filterRegion}
                filterLevel={filterLevel}
                filterExperience={filterExperience}
                getSelectedFilters={this.handleCheckbox}
                handleRequestInfoModal={this.handleRequestIntroModal}
                selectAllCheckBox={selectAllCheckBox}
                selectedContactsArr={selectedContactsArr}
                counts={counts}
                handleId={this.handleId}
                clearAllFilter={this.clearAllFilters}
                sortData={this.sortData}
                pathName={pathName}
                handleSelectAll={this.handleSelectAll}
                isSelectedAll={isSelectedAll}
              />
            </Scrollbars>
          </div>
          <RequestIntroModal
            handleInputChange={this.handleInputChange}
            openModal={showRequestIntroModal}
            handleOpenModal={this.handleRequestIntroModal}
            desc={desc}
            reqInfoContList={reqInfoContList}
            submitReqInto={this.submitReqInto}
            sendMail={this.sendMailReqIntro}
            reqInfoDefMail={reqInfoDefMail}
            data={data}
            id={id}
            opportunityError={opportunityError}
            companyError={companyError}
            addedContactErr={addedContactErr}
            openSuccessModal={showSuccessModal}
            otherMsgErr={this.state.otherMsgErr}
            handleSuccessModal={this.hanleSuccessModal}
            successModalTitle={
              successModalType === 'reqIntro'
                ? 'Introduction Requested'
                : 'Information Requested'
            }
            successModalMessage={
              successModalType === 'reqIntro'
                ? reqIntroSuccessMessage
                : reqInfoSuccessMessage
            }
          />
          {userRole === 'Admin' && (
            <SwitchBack handleClick={this.handleSwitch} />
          )}
          <Feedback
            activeValue={activeValue}
            feedbackSuggestion={feedbackSuggestion}
            showdata={showdata}
            hidedata={hidedata}
            toggleClick={this.toggleClick}
            closeToggleClick={this.closeToggleClick}
            handleChange={this.handleChange}
            handleNext={this.handleNext}
            handleFeedback={this.handleFeedback}
          />
        </GCPrivatePageLayout>
      </>
    );
  }
}

export default ContactTable;

function SwitchBack({ handleClick }) {
  return (
    <div className="switch-back-float d-inline-flex ico-btn">
      <CommonButton
        variant="primary"
        clickHandler={handleClick}
        label="Switch Back"
        customClass="request-info"
        icon="switch-user"
        iconColor="#FFFFFF"
        buttonIconSize={18}
      />
    </div>
  );
}
