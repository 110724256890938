import React, { Component } from 'react';
import axios from 'axios';
import cookies from 'js-cookie';
import '../../assets/scss/layouts/pages/_magicLink.scss';
import DataNotFound from '../dataNotFound/DataNotFound';
import * as URL from '../../constants/constants';
import Alerts from '../pages/Alerts';

import Loader from '../common/loader/Loader';

class ConfirmMagic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      alerts: false,
      errorMsg: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const urlToken = match.params[0];
    const data = {
      token: urlToken,
    };
    this.showLoader();
    const { history } = this.props;
    axios.post(URL.MAGIC_LOGIN, data)
      .then((res) => {
        if (res.data.status === 200) {
          cookies.set('otp', res.data.otp_required_for_login);
          if (res.data.role === 'Admin') {
            cookies.set('loginToken', res.data.auth_token);
            cookies.set('email', res.data.email);
            cookies.set('role', res.data.role);
            history.push({
              pathname: '/admin',
            });
            this.hideLoader();
          } else if (res.data.role === 'User') {
            cookies.set('loginToken1', res.data.auth_token);
            cookies.set('email1', res.data.email);
            cookies.set('role1', res.data.role);
            history.push({
              pathname: '/client',
            });
            this.hideLoader();
          }
        } else {
          this.setState({ errorMsg: res.data.message, loading: false });
        }
      })
      .catch(() => {
        this.setState({ alerts: 'Something went wrong', loading: false });
      });
  }

  handleAlertClose() {
    this.setState({ alerts: false });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  render() {
    const { loading, alerts, errorMsg } = this.state;
    return (
      <div className="loginPage">
        {alerts ? (
          <Alerts
            alertVal={alerts}
            closeAlert={() => this.handleAlertClose()}
          />
        ) : (
          ''
        )}
        {loading ? <Loader /> : ''}
        {errorMsg ? <DataNotFound message={errorMsg} /> : null}
      </div>
    );
  }
}

export default ConfirmMagic;
