import React, { Component } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Label } from 'reactstrap';
import FormLabel from '../common/commonlabel';
import CommonMultiselect from '../common/CommonMultiselect';
import {
  experience,
  optionExpertise,
  optionIndustry,
} from '../common/selectlist';

const selectedExperties = [];
const IndustryExperiences = [];

export default class Experience extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageHeight: 200,
    };
    this.resizeForm = this.resizeForm.bind(this);
  }

  componentDidMount() {
    this.resizeForm();
    window.addEventListener('resize', () => this.resizeForm());
    window.addEventListener('load', () => this.resizeForm());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.resizeForm());
    window.removeEventListener('load', () => this.resizeForm());
  }

  handleExperts(e) {
    const { formValues, component } = this.props;
    const selectedExpert = e.currentTarget.ariaLabel;
    const index = formValues.expert.indexOf(selectedExpert);
    if (index > -1) {
      formValues.expert.splice(index, 1);
      component.setState({ expert: selectedExperties });
    } else {
      if (formValues.expert.length > 1) {
        return;
      }
      selectedExperties.push(selectedExpert);
      component.setState({ expert: selectedExperties, functionInErr: '' });
    }
  }

  handleIndustryExperience(e) {
    const { formValues, component } = this.props;
    const industryexperience = e.currentTarget.ariaLabel;
    const index = formValues.industryExperience.indexOf(industryexperience);
    if (index > -1) {
      formValues.industryExperience.splice(index, 1);
      component.setState({ industryExperience: IndustryExperiences });
    } else {
      IndustryExperiences.push(industryexperience);
      component.setState({ industryExperience: IndustryExperiences });
    }
  }

  resizeForm() {
    // const pageOuterHeight = document.getElementById('outerWrap').offsetHeight;
    const pageOuterHeight = window.innerHeight - 120;
    const pageheadHeight = document?.getElementById('formHead')?.offsetHeight;
    const pageCtaHeight = document?.getElementById('formCta')?.offsetHeight;
    const finalHeight = pageOuterHeight - (pageheadHeight + pageCtaHeight);
    this.setState({ pageHeight: finalHeight });
  }

  selectExperience(e) {
    const { component } = this.props;
    const selectedExperiences = e.currentTarget.ariaLabel;
    component.setState({ experience: [selectedExperiences] });
  }

  continue(ev) {
    ev.preventDefault();
    const { validateFunction, nextStep } = this.props;
    const validFunction = validateFunction();
    if (validFunction) {
      setTimeout(() => {
        nextStep();
      }, 200);
    }
  }

  previous(ev) {
    const { prevStep } = this.props;
    ev.preventDefault();
    prevStep();
  }

  render() {
    const { formValues, handleChange } = this.props;
    const { functionInErr } = formValues;
    const { pageHeight } = this.state;

    return (
      <>
        <div
          id="formHead"
          className="LocationPrefernces cneterForm"
        >
          <div id="pageNumber">
            <h4> Step 3 of 5</h4>
          </div>
          <div id="pageHeader">
            <h2> Tell us about your professional experience</h2>
          </div>
          <hr />
        </div>
        <div className="formHeightCuston">
          <form
            onSubmit={(ev) => this.continue(ev)}
            className="form-horizontal"
          >
            <div
              className="formMain"
              style={{ height: pageHeight }}
            >
              <Scrollbars
                style={{ width: '100%', height: '100%' }}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                horizontal={false}
                className="scrollbarHide"
              >
                <div className="formInputWrap">
                  <div className="headingH4">
                    <h4>Please choose no. of years of experience</h4>
                  </div>
                  <FormGroup className="form-group-active">
                    <FormLabel
                      id="experience"
                      htmlFor="experience"
                      text="Please choose no. of experience"
                    />
                    <CommonMultiselect
                      itemArray={experience}
                      handleClick={(e) => this.selectExperience(e)}
                      compareArray={formValues.experience}
                    />
                  </FormGroup>
                  <hr />
                  <div>
                    <div className="headingH4">
                      <h4> Primary areas of functional expertise </h4>
                    </div>
                    <FormGroup className="form-group-active row">
                      <FormLabel
                        id="expert"
                        htmlFor="expert"
                        text="Please choose no more than two"
                      />
                      <CommonMultiselect
                        itemArray={optionExpertise}
                        handleClick={(e) => this.handleExperts(e)}
                        compareArray={formValues.expert}
                      />
                      {functionInErr ? (
                        <div
                          className="error"
                          id="error2"
                        >
                          {functionInErr}
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>

                    <hr />
                    <div>
                      <h3> Industries where you have operating experience </h3>
                    </div>
                    <FormGroup className="form-group-active row">
                      <FormLabel
                        id="industry_experience"
                        htmlFor="industry_experience"
                        text="Select all that apply "
                      />
                      <CommonMultiselect
                        itemArray={optionIndustry}
                        handleClick={(e) => this.handleIndustryExperience(e)}
                        compareArray={formValues.industryExperience}
                      />
                    </FormGroup>
                    <hr />
                    <div className="headingH4">
                      <h4> Previous Companies </h4>
                    </div>
                    <FormGroup className="form-group-active row">
                      <Label className="form control-label">
                        Optional list of companies you previously worked for
                      </Label>
                      {/* <FormLabel id="industry_experience" htmlFor="industry_experience" text='Optional list of companies you previously worked for' /> */}
                      <textarea
                        name="previous_company"
                        value={formValues.previous_company}
                        onChange={(e) => handleChange(e)}
                        placeholder="Amazon, Google"
                        className="form-control"
                        maxLength="30"
                      />
                    </FormGroup>
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div
              id="formCta"
              className="actionButton"
            >
              <div className="submit">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={(ev) => this.previous(ev)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
