/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import cookies from 'js-cookie';
import { Row, Col, FormGroup } from 'reactstrap';

import GCPrivatePageLayout from '../layout/GCPrivatePageLayout';
import UserInfo from '../pages/FileUpload';
import '../../assets/scss/layouts/pages/_home.scss';
import '../../assets/scss/layouts/pages/_createUser.scss';
import * as URL from '../../constants/constants';
import CommonLabelText from '../common/label/CommonLabelText';
import {
  customStylesReactSelect,
  customThemeReactSelect,
} from '../common/Utilities';

import Loader from '../common/loader/Loader';

const optionStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Deactivated' },
];
const optionRole = [
  { value: 'Admin', label: 'Admin' },
  { value: 'User', label: 'User' },
];

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userName: '',
      firstName: '',
      company: '',
      title: '',
      email: '',
      role: '',
      roleChange: '',
      status: '',
      statusChange: '',
      // lastLogin: '',
      firstNameErr: '',
      lastNameErr: '',
      companyErr: '',
      avt: '',
      content: '',
      movePage401: false,
      lastName: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.data) {
      this.setState({
        firstName: location.state.data.map((each) => each.first_name),
        lastName: location.state.data.map((each) => each.last_name),
        company: location.state.data.map((each) => each.company),
        title: location.state.data.map((each) => each.title),
        email: location.state.data.map((each) => each.email),
        roleChange: location.state.data.map((each) => each.role),
        statusChange: location.state.data.map((each) => each.status),
        // lastLogin: location.state.data.map((each) => each.status),
      });
    }
    let mail;
    if (cookies.get('email') && cookies.get('email1')) {
      mail = cookies.get('email1');
    } else if (cookies.get('email1')) {
      mail = cookies.get('email1');
    } else if (cookies.get('email')) {
      mail = cookies.get('email');
    }
    this.showLoader();
    const data = {
      email: mail,
    };
    axios
      .post(URL.USER_DATA, data)
      .then((res) => {
        if (res) {
          this.setState({
            content: res.data.data,
          });
          this.hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      firstNameErr: '',
      lastNameErr: '',
      companyErr: '',
    });
  }

  handleBtn() {
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        firstNameErr: '',
        lastNameErr: '',
        companyErr: '',
      });
      const {
        firstName,
        lastName,
        company,
        role,
        roleChange,
        status,
        avt,
        statusChange,
        title,
        first_name: fname,
        last_name: lname,
        cCompany,
        cTitle, // lastLogin,
      } = this.state;
      const data = {
        first_name: firstName || fname,
        last_name: lastName || lname,
        company: company || cCompany,
        title: title || cTitle,
        role: role.value || roleChange[0],
        status: status.value || statusChange,
        avatar: avt,
      };
      let header;
      if (cookies.get().loginToken1) {
        header = cookies.get().loginToken1;
      } else if (cookies.get().loginToken) {
        header = cookies.get().loginToken;
      } else if (cookies.get().auth_token1) {
        header = cookies.get().auth_token1;
      } else if (cookies.get().auth_token) {
        header = cookies.get().auth_token;
      }
      const config = {
        headers: {
          Authorization: header,
        },
      };
      this.showLoader();
      axios
        .post(URL.PROFILE, data, config)
        .then((res) => {
          if (res.data.success) {
            this.hideLoader();
            const { history } = this.props;
            history.push({
              pathname: '/personal_info',
            });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }

  handleStatus(values) {
    this.setState({ status: values }); //  convert to obj
  }

  handleRole(values) {
    this.setState({ role: values });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  cancel() {
    const { history } = this.props;
    history.push({
      pathname: '/personal_info',
    });
  }

  validate() {
    let firstNameErr = '';
    let lastNameErr = '';
    let companyErr = '';
    const { firstName, company, lastName, content } = this.state;
    if (!firstName && !(content && content[0].first_name)) {
      firstNameErr = 'Required';
    }
    if (!lastName && !(content && content[0].last_name)) {
      lastNameErr = 'Required';
    }
    if (!company && !(content && content[0].company)) {
      companyErr = 'Required';
    }
    if (firstNameErr || companyErr || lastNameErr) {
      this.setState({ firstNameErr, companyErr, lastNameErr });
      return false;
    }
    return true;
  }

  render() {
    const {
      firstName,
      lastName,
      company,
      title,
      email,
      role,
      status,
      firstNameErr,
      loading,
      statusChange,
      roleChange,
      content,
      lastNameErr,
      companyErr,
      movePage401,
    } = this.state;
    const {
      first_name: fname,
      last_name: lname,
      company: cCompany,
      email: cEmail,
      role: cRole,
      status: cStatus,
      title: cTitle,
    } = content && content[0];
    let data;
    // let img;
    if (content) {
      data = content;
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }
    return (
      <GCPrivatePageLayout
        headerlabel="Edit Profile"
        // clickHandler={(ev) => this.editUser(ev)}
        // buttonLabel="Edit Profile"
        // buttonVariant="primary"
      >
        <div className="content-div">
          <div className="clientApp">
            <div className="container CustomWidth">
              <div>
                {loading ? <Loader /> : ''}

                <UserInfo data={data} />
                <div className="form-wrapper">
                  <form className="form-horizontal">
                    <FormGroup>
                      <Row>
                        <Col
                          xs="12"
                          lg="6"
                        >
                          <CommonLabelText
                            label="First Name"
                            name="firstName"
                            id="firstname"
                            value={firstName || fname}
                            handleChange={(ev) => this.handleChange(ev)}
                            setDefaultValue={fname}
                          />
                          <div className="error">{firstNameErr}</div>
                        </Col>

                        <Col
                          xs="12"
                          lg="6"
                        >
                          <CommonLabelText
                            label="Last Name"
                            name="lastName"
                            id="lastname"
                            value={lastName || lname}
                            handleChange={(ev) => this.handleChange(ev)}
                            setDefaultValue={lname}
                          />
                          <div className="error">{lastNameErr}</div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col
                          xs="12"
                          lg="6"
                        >
                          <CommonLabelText
                            label="Company"
                            name="company"
                            id="company"
                            value={company || cCompany}
                            handleChange={(ev) => this.handleChange(ev)}
                            // setDefaultValue={cCompany}
                          />
                          <div className="error">{companyErr}</div>
                        </Col>

                        <Col
                          xs="12"
                          lg="6"
                        >
                          <CommonLabelText
                            label="Title"
                            name="title"
                            id="title"
                            value={title || cTitle}
                            handleChange={(ev) => this.handleChange(ev)}
                            setDefaultValue={cTitle}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col
                          xs="12"
                          lg="6"
                        >
                          <CommonLabelText
                            label="Email"
                            name="email"
                            id="email"
                            disabled="disabled"
                            dataplacement="right"
                            tooltip="tooltip"
                            title="Not Editable"
                            value={email || cEmail}
                            handleChange={(ev) => this.handleChange(ev)}
                            setDefaultValue={cEmail}
                          />
                        </Col>

                        <Col
                          xs="12"
                          lg="6"
                        >
                          {cookies.get('role') === 'Admin' &&
                          cookies.get('role1') !== 'User' &&
                          cookies.get('email') !== email[0] ? (
                            <div className="form-group">
                              <label htmlFor="nameImput"> Role </label>
                              <Select
                                id="nameImput"
                                name="role"
                                placeholder={roleChange || cRole}
                                value={role}
                                onChange={(values) => this.handleRole(values)}
                                options={optionRole}
                                className="status-box"
                                styles={customStylesReactSelect}
                                theme={customThemeReactSelect}
                              />
                            </div>
                          ) : (
                            [
                              cookies.get('role') === 'User' ||
                              cookies.get('role1') === 'User' ||
                              cookies.get('email') === email[0] ? (
                                <div className="form-group">
                                  <label htmlFor="nameImput">Role</label>

                                  <div className="select-box">
                                    <select
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      id="nameImput"
                                      placeholder="Role"
                                      disabled="disabled"
                                      data-placement="right"
                                      data-toggle="tooltip"
                                      title="Not Editable"
                                    >
                                      <option value="0">
                                        {roleChange || cRole}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                ''
                              ),
                            ]
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col
                          xs="12"
                          lg="6"
                        >
                          {cookies.get('role') === 'Admin' &&
                          cookies.get('role1') !== 'User' ? (
                            <>
                              <label htmlFor="nameImput">Status</label>
                              <Select
                                name="status"
                                disabled="disabled"
                                className="status-box"
                                placeholder={statusChange || cStatus}
                                value={status}
                                options={optionStatus}
                                onChange={(values) => this.handleStatus(values)}
                                styles={customStylesReactSelect}
                                theme={customThemeReactSelect}
                              />
                            </>
                          ) : (
                            [
                              cookies.get('role') === 'User' ||
                              cookies.get('role1') === 'User' ? (
                                <>
                                  <label htmlFor="nameImput">Status</label>
                                  <div className="select-box">
                                    <select
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      id="nameImput"
                                      placeholder="Role"
                                      disabled="disabled"
                                      data-placement="right"
                                      data-toggle="tooltip"
                                      title="Not Editable"
                                    >
                                      <option value="0">
                                        {statusChange || cStatus}
                                      </option>
                                    </select>
                                  </div>
                                </>
                              ) : (
                                ''
                              ),
                            ]
                          )}
                        </Col>

                        <Col />
                      </Row>
                    </FormGroup>
                    <Row>
                      <Col className="buttonActions updateBtn mb-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => this.handleBtn()}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={() => this.cancel()}
                        >
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GCPrivatePageLayout>
    );
  }
}
export default withRouter(UserEdit);
