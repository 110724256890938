import React, { Component } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import Alerts from '../pages/Alerts';
import Icon from '../shared/icomoon';
import '../../assets/scss/layouts/pages/_forgotpass.scss';
import DataNotFound from '../dataNotFound/DataNotFound';
import * as URL from '../../constants/constants';
import Loader from '../common/loader/Loader';

class ConfirmPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwd: '',
      confirmPass: '',
      tokens: '',
      redirect: false,
      passwdErr: '',
      confirmPassErr: '',
      isPasswdShownNew: '',
      isPasswdShownconf: '',
      alerts: false,
      loading: false,
      errorMsg: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const urlToken = match.params[0];
    this.setState({ tokens: urlToken });
    const data = {
      token: urlToken,
    };
    this.showLoader();
    axios
      .post(URL.CHECK_LINK, data)
      .then((res) => {
        console.log('confirm passwd', res);
        if (res.status === 204) {
          this.hideLoader();
        } else if (res.data.message) {
          this.setState({ errorMsg: res.data.message, loading: false });
        } else {
          this.setState({ errorMsg: 'Something went wrong', loading: false });
        }
      })
      .catch(() => {
        this.setState({ errorMsg: 'Something went wrong', loading: false });
      });
  }

  handleChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }

  handleAlertClose() {
    this.setState({ alerts: false });
  }

  validate() {
    let passwdErr = '';
    let confirmPassErr = '';
    const passwordRegex = /([0-9])/;
    const { passwd, confirmPass } = this.state;
    if (!passwd) {
      passwdErr = 'Required';
    } else if (passwd.length < 3 || passwd.length > 25) {
      passwdErr = 'Use Strong Password';
    } else if (!passwordRegex.test(passwd)) {
      passwdErr = 'Use Strong Password';
    }
    if (!confirmPass) {
      confirmPassErr = 'Required';
    } else if (passwd !== confirmPass) {
      confirmPassErr = 'Password should be match';
    }
    if (passwdErr || confirmPassErr) {
      this.setState({ passwdErr, confirmPassErr });
      return false;
    }
    return true;
  }

  confirmBtn(event) {
    event.preventDefault();
    const { tokens } = this.state;
    const { passwd, confirmPass } = this.state;
    const isValid = this.validate();
    if (isValid) {
      this.setState({
        passwdErr: '',
        confirmPassErr: '',
      });
      const data = {
        password: passwd,
        password_confirmation: confirmPass,
        token: tokens,
      };
      this.showLoader();
      if (tokens) {
        axios
          .post(URL.UPDATE_PASSWORD, data)
          .then((res) => {
            if (res.data.success) {
              this.hideLoader();
              const { history } = this.props;
              history.push('/login');
            } else {
              this.setState({ loading: false, errorMsg: 'Token is not valid' });
            }
          })
          .catch(() => {
            this.setState({ errorMsg: 'Something went wrong', loading: false });
          });
      }
    }
  }

  passwdVisibleNew() {
    const { isPasswdShownNew } = this.state;
    this.setState({
      isPasswdShownNew: !isPasswdShownNew,
    });
  }

  passwdVisibleConf() {
    const { isPasswdShownconf } = this.state;
    this.setState({
      isPasswdShownconf: !isPasswdShownconf,
    });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  render() {
    const {
      passwd,
      confirmPass,
      redirect,
      passwdErr,
      confirmPassErr,
      isPasswdShownNew,
      isPasswdShownconf,
      alerts,
      loading,
      errorMsg,
    } = this.state;
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="">
        {loading ? <Loader /> : ''}
        {alerts ? (
          <Alerts
            alertVal={alerts}
            closeAlert={() => this.handleAlertClose()}
          />
        ) : (
          ''
        )}
        <Container className="fullWidth-100">
          <Row>
            {errorMsg ? (
              <DataNotFound message={errorMsg} />
            ) : (
              <Col
                xs="7"
                className="pt-5 pb-4 mx-auto extraPadd"
              >
                <div className="d-flex flex-column h-100">
                  <div className="loginSection">
                    <h1 className="h1">Create Password</h1>
                    <div className="my-5">
                      <Form>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <Icon
                                  icon="lock"
                                  size={17}
                                  color="#919eab"
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type={isPasswdShownNew ? 'text' : 'password'}
                              name="passwd"
                              value={passwd}
                              placeholder="Password"
                              onChange={(ev) => this.handleChange(ev)}
                              required
                            />
                            <InputGroupAddon addonType={['prepend', 'append']}>
                              <InputGroupText>
                                <Icon
                                  icon={isPasswdShownNew ? 'eye' : 'close-eye'}
                                  size={18}
                                  color="#919eab"
                                  onClick={() => this.passwdVisibleNew()}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <div className="error">{passwdErr}</div>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <Icon
                                  icon="lock"
                                  size={17}
                                  color="#919eab"
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type={isPasswdShownconf ? 'text' : 'password'}
                              name="confirmPass"
                              value={confirmPass}
                              placeholder="Confirm Password"
                              onChange={(ev) => this.handleChange(ev)}
                              required
                            />
                            <InputGroupAddon addonType={['prepend', 'append']}>
                              <InputGroupText>
                                <Icon
                                  icon={isPasswdShownconf ? 'eye' : 'close-eye'}
                                  size={18}
                                  color="#919eab"
                                  onClick={() => this.passwdVisibleConf()}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <div className="error">{confirmPassErr}</div>
                        </FormGroup>
                        <FormGroup>
                          <label
                            htmlFor="passwordcontent"
                            className="w-100"
                          >
                            <span className="smaller">
                              Passwords must be greater than 6 characters and
                              less than 25 characters long and contain at least
                              1 uppercase, 1 lowercase, 1 numeric, and 1 special
                              character.
                            </span>{' '}
                          </label>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            type="button"
                            color="primary"
                            className="mt-4 d-block mx-auto btn-mx280 solidBtn solidBtn__blue text-uppercase"
                            onClick={(event) => this.confirmBtn(event)}
                          >
                            Submit
                          </Button>
                        </FormGroup>
                        {/* <Row className="align-items-center p-3">
                          <Col xs="12">
                            <span>
                              back to
                            <Link to="/" color="link">&nbsp;Login</Link>
                            </span>
                          </Col>
                        </Row> */}
                      </Form>
                    </div>
                  </div>
                  {/* <div className="gcFtrLog text-center mt-5">
                    <img src={FtrLogo} alt="Genral Catalyst" style={{ maxWidth: '180px' }} />
                    </div> */}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
export default withRouter(ConfirmPassword);
