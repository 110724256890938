import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Logo from '../../assets/images/gcLogo.png';
import FtrLogo from '../../assets/images/Form_logo.png';

class DataNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }

  render() {
    const { message } = this.props;
    return (
      <Col
        xs="7"
        className="pt-5 pb-4 mx-auto"
      >
        <div className="d-flex flex-column h-100">
          <div className="loginSection">
            <div className="text-center my-5">
              <img
                src={Logo}
                alt="logo"
              />
            </div>
            <h4 className="mb-0 fontSize text-grey text-center">
              {!message ? 'Data Not Found' : message}
            </h4>
          </div>
          <div className="gcFtrLog text-center mt-5">
            <img
              src={FtrLogo}
              alt="Genral Catalyst"
              style={{ maxWidth: '180px' }}
            />
          </div>
        </div>
      </Col>
    );
  }
}
export default DataNotFound;
