import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import cookies from 'js-cookie';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import * as URL from '../../constants/constants';
import '../../assets/scss/layouts/pages/_home.scss';
import Icon from '../shared/icomoon';
import {
  customStylesReactSelectFilter,
  customThemeReactSelect,
} from '../common/Utilities';
import SearchField from '../common/SearchField';
import Loader from '../common/loader/Loader';

const optionStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Deactivated' },
  { value: 'Expired', label: 'Expired' },
  { value: 'Invited', label: 'Invited' },
  { value: 'All', label: 'All' },
];

class AdminHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offset: 0,
      data: [],
      orgdata: [],
      perPage: 20,
      pagination: 0,
      user: '',
      pageCount: 0,
      status: '',
      count: 0,
      fcounts: '',
      scounts: '',
      // sortBy: 'last_name',
      // sortOrder: 'asc',
      sortState: 'first_name',
      arrowState: 'asc',
      role: '',
      errorMsg: '',
      showPop: false,
      redirectOut: false,
      deleteAlert: false,
      ID: '',
      MAIL: '',
      movePage401: false,
      tableWrapperHt: 500,
      pageYmargin: 90,
    };
    this.getTableHeight = this.getTableHeight.bind(this);
  }

  componentDidMount() {
    const { addEventListener } = window;
    addEventListener('load', () => this.getTableHeight());
    addEventListener('resize', () => this.getTableHeight());
    const { history } = this.props;
    if (
      (cookies.get('loginToken1') && cookies.get('loginToken')) ||
      (cookies.get('auth_token') && cookies.get('loginToken1'))
    ) {
      history.push({ pathname: '/client' }); // /client
    } else if (
      (cookies.get('loginToken') && cookies.get('role') === 'Admin') ||
      (cookies.get('auth_token') && cookies.get('role') === 'Admin')
    ) {
      history.push({ pathname: '/admin' }); // admin
      cookies.set('tabChange', 'no');
      this.setState({ role: cookies.get('role') });
      this.getData();
      this.expireLink();
      // this.deactivate();
      const pageNum = localStorage.getItem('currentAdminPage');
      this.handlePageClick({ selected: Number(pageNum) });
      localStorage.removeItem('currentAdminPage');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { innerHeight } = window;
    if (innerHeight !== prevState.innerHeight) {
      this.getTableHeight();
    }
  }

  componentWillUnmount() {
    const { removeEventListener } = window;
    removeEventListener('load', () => this.getTableHeight());
    removeEventListener('resize', () => this.getTableHeight());
  }

  handleClose() {
    this.setState({
      showPop: false,
    });
  }

  handleStatus(values) {
    this.setState({ status: values }, () => {
      if (values.value === 'All') {
        this.setState(
          {
            offset: 0,
            data: [],
            orgdata: [],
            perPage: 20,
            pagination: 0,
            user: '',
            pageCount: 0,
          },
          () => {
            this.getData();
          }
        );
      } else if (values.value !== 'All' && values.value) {
        this.setState({ pagination: 0 }, () => {
          this.getSearch();
        });
      } else {
        this.getData();
      }
    });
  }

  handleSearch(ev) {
    const { status } = this.state;
    if (status.value === 'All') {
      this.setState({ status: '' });
      this.getData();
    } else if (ev.target.value) {
      this.setState({ pagination: 0, user: ev.target.value }, () => {
        this.getSearch();
      });
    } else {
      this.setState({ user: '' }, () => {
        this.getData();
      });
    }
  }

  handlePageClick(e) {
    const { perPage, user, status } = this.state;
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    // const page = !selectedPage ? 0 : selectedPage;
    cookies.set('currentPage', e.selected);
    this.setState({ offset, pagination: e.selected }, () => {
      localStorage.setItem('currentAdminPage', e.selected);
      this.loadMoreData();
      if (status.value === 'All') {
        this.setState({ status: '' }, () => {
          this.getData();
        });
      } else if (user || status.value) {
        this.getSearch();
      } else {
        this.getData();
      }
    });
  }

  handleCreate() {
    const { role } = this.state;
    const { history } = this.props;
    history.push({
      pathname: '/create_user',
      state: {
        role,
      },
    });
  }

  handleAdminMail(email) {
    const { pagination } = this.state;
    const { history } = this.props;
    history.push({
      pathname: '/edit_details',
      state: {
        email,
        pagination,
      },
    });
  }

  handleCloseDelete() {
    const { ID, MAIL } = this.state;
    const data = {
      id: ID,
    };
    this.setState({ loading: true });
    axios
      .post(URL.DELETE_CLIENT, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res) {
          if (MAIL === cookies.get('email')) {
            cookies.remove('loginToken');
            cookies.remove('email');
            cookies.remove('role'); // add at last don't
            cookies.remove('username1Sandbox');

            cookies.remove('loginToken1');
            cookies.remove('email1');
            cookies.remove('role1'); // add at last don't
            cookies.remove('username1Sandbox');
            cookies.remove('loginAs');

            cookies.remove('currentPage');
            cookies.remove('currentClientPage');
            this.setState({ redirectOut: true });
          } else {
            // history.go(0);
            this.getData();
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
      });
    this.setState({ deleteAlert: false });
  }

  getData() {
    const { offset, perPage, pagination, sortState, arrowState } = this.state;
    const data2 = {
      page: pagination, // cookies.get('currentPage') ? cookies.get('currentPage') : pagination,
      sort: sortState,
      order: arrowState,
    };
    this.showLoader();
    axios
      .post(URL.USER_HOME, data2)
      .then((res) => {
        if (res && res.data) {
          const { data } = res.data;
          const slice =
            pagination === '' ? data.slice(offset, offset + perPage) : data;
          this.setState({
            count: res.data.count,
            fcounts: res.data.fcount,
            scounts: res.data.scount,
            pageCount: Math.ceil(res.data.count / perPage),
            orgdata: res.data.data,
            data: slice,
            errorMsg: '',
          });
          this.hideLoader();
        } else {
          // this.hideLoader();
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true, loading: false });
        }
        this.setState({ data: [], errorMsg: '', loading: false });
      });
  }

  getSearch() {
    const { user, offset, perPage, pagination, status, sortState, arrowState } =
      this.state;
    const adminStatus =
      !status.value || status.value === 'All' ? '' : status.value;
    // const orderVal = arrowState ? 'asc' : 'desc';
    const data2 = {
      user,
      page: pagination, // cookies.get('currentPage') ? cookies.get('currentPage') : pagination,
      status: adminStatus,
      sort: sortState,
      order: arrowState,
    };
    // this.showLoader();
    axios
      .post(URL.SEARCH_ADMIN, data2)
      .then((res) => {
        if (res.data.success && res.data.message !== 'No Record Found') {
          const { data } = res.data;
          const slice =
            pagination === '' ? data.slice(offset, offset + perPage) : data;
          // const slice = data.slice(offset, offset + perPage);
          this.setState({
            count: res.data.count,
            fcounts: res.data.fcount,
            scounts: res.data.scount,
            errorMsg: '',
            pageCount: Math.ceil(res.data.count / perPage),
            orgdata: res.data.data,
            data: slice,
          });
          this.hideLoader();
        } else if (res.data.success && res.data.message === 'No Record Found') {
          // this.hideLoader();
          this.setState({
            pageCount: 0,
            data: [],
            orgdata: [],
            loading: false,
            errorMsg: res.data.message,
          });
        }
      })
      .catch(() => {
        this.setState({ data: [], loading: false });
      });
  }

  getTableHeight() {
    const { pageYmargin } = this.state;
    const { innerHeight, document } = window;
    const headerHt = document?.getElementById('HeaderWrap')?.offsetHeight;
    const footrHt = document?.getElementById('adminFooter')?.offsetHeight;
    const admFiltrTabHt =
      document?.getElementById('adminFilterTab')?.offsetHeight;
    const tableWrapperHt =
      innerHeight - (headerHt + footrHt + pageYmargin + admFiltrTabHt);
    this.setState({ innerHeight, tableWrapperHt });
  }

  sortData(val) {
    const { user, status, arrowState, sortState } = this.state;
    // sortBy: 'last_name',
    // sortOrder: 'asc',
    // sortState: 'last_name',
    // arrowState: 'asc',
    let sortOrder = arrowState;
    if (sortState === val) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    this.setState({ sortState: val, arrowState: sortOrder }, () => {
      if (status.value === 'All') {
        this.setState({ status: '' });
        this.getData();
      } else if (user || status.value) {
        this.setState({ pagination: 0 }, () => {
          this.getSearch();
        });
      } else {
        this.getData();
      }
    });
  }

  loadMoreData() {
    const { orgdata, offset, perPage } = this.state;
    const data = orgdata;
    const slice = data.slice(offset, offset + perPage);
    this.setState({ pageCount: Math.ceil(data.length / perPage), data: slice });
  }

  expireLink() {
    axios
      .get(URL.EXPIRE_CHECK)
      .then(() => {})
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({ movePage401: true });
        }
      });
  }

  deleteIcon(ev, id, mail) {
    ev.stopPropagation();
    this.setState({
      deleteAlert: 'Do you want to delete this user account?',
      ID: id,
      MAIL: mail,
    });
  }

  updateUser(ev, email) {
    const { currentPage } = this.state;
    ev.stopPropagation();
    const { history } = this.props;
    history.push({
      pathname: '/update_user',
      state: {
        email,
        currentPage,
      },
    });
  }

  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }

  loginIcon(ev, mail, ROLE) {
    ev.stopPropagation();
    const data = {
      email: mail,
    };
    let header;
    if (cookies.get().loginToken1) {
      header = cookies.get().loginToken1;
    } else if (cookies.get().loginToken) {
      header = cookies.get().loginToken;
    } else if (cookies.get().auth_token1) {
      header = cookies.get().auth_token1;
    } else if (cookies.get().auth_token) {
      header = cookies.get().auth_token;
    }
    const config = {
      headers: {
        Authorization: header,
      },
    };
    this.setState({ loading: true });
    if (ROLE === 'User') {
      axios
        .post(URL.LOGIN_AS, data, config)
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success) {
            localStorage.setItem('loginName', res.data.username);
            localStorage.setItem('loginFocusArea', res.data.focus_area);
            cookies.set('loginToken1', res.data.auth_token);
            cookies.set('email1', res.data.email);
            cookies.set('role1', res.data.role);
            cookies.set('username1Sandbox', res.data.username);
            cookies.set('loginAs', 'loginAs');
            localStorage.setItem('initialModal', true);
            const { history } = this.props;
            history.push({
              pathname: './client',
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setState({ movePage401: true, loading: false });
          }
        });
    } else if (ROLE === 'Admin') {
      this.setState({ showPop: true });
    }
  }

  cancelDelete() {
    this.setState({ deleteAlert: false });
  }

  render() {
    const {
      data,
      role,
      user,
      status,
      loading,
      pageCount,
      showPop, // mail, mailError,
      errorMsg,
      redirectOut,
      deleteAlert,
      count,
      pagination,
      fcounts,
      scounts,
      arrowState,
      sortState,
      movePage401,
      tableWrapperHt,
    } = this.state;
    if (redirectOut) {
      return <Redirect to="/login" />;
    }
    if (movePage401) {
      return <Redirect to="/unauthorize" />;
    }

    return (
      <div className="CustomWidth">
        {loading ? <Loader /> : ''}
        <div>
          <div className="homecontent">
            {/* TODO - move pagetitle to header */}
            {/* <div className="card-header mb-3 pl-0">

               <h3 className="pageTitle">
                {role === 'Admin'
                  ? 'User list'
                  : [role === 'User' ? 'CONTACT LIST' : '']}
              </h3>
            </div> */}
            <div
              id="adminFilterTab"
              className="pr-3  w-100 d-flex justify-content-between admin-filter"
            >
              {role === 'Admin' ? (
                <div className="siteFilter d-flex align-items-top col-gap search pt-3 pl-3">
                  <SearchField
                    placeholder="Search User"
                    value={user}
                    onChange={(ev) => this.handleSearch(ev)}
                  />
                  <div className="form-inline">
                    <div className="filterBy text-center pr-3">Filter by</div>

                    <div className="dropdown singleTag">
                      <Select
                        className="status_box rounded-8"
                        name="status"
                        value={status}
                        onChange={(values) => this.handleStatus(values)}
                        options={optionStatus}
                        placeholder="Status"
                        isSearchable={false}
                        theme={customThemeReactSelect}
                        styles={customStylesReactSelectFilter}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {role === 'Admin' ? (
                <div className="commonBtn pt-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.handleCreate()}
                  >
                    Create User
                  </button>
                </div>
              ) : (
                <div className="commonBtn" />
              )}
            </div>
            <div
              id="admintblWrap"
              className="content-div tblWraps"
            >
              <div
                className="table-wrapper"
                style={{ height: tableWrapperHt }}
              >
                <table
                  className="table user-list-table"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr
                      style={{ position: 'sticky', top: 0, background: '#fff' }}
                    >
                      <th className="d-none">&nbsp;</th>
                      <th onClick={() => this.sortData('first_name')}>
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'first_name'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Name
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th onClick={() => this.sortData('company')}>
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'company'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Company
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th onClick={() => this.sortData('title')}>
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'title'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Title
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th onClick={() => this.sortData('email')}>
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'email'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Email
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th onClick={() => this.sortData('role')}>
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'role'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Role
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th onClick={() => this.sortData('status')}>
                        <span
                          className={
                            arrowState === 'asc' && sortState === 'status'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Status
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th onClick={() => this.sortData('last_login_at')}>
                        <span
                          className={
                            arrowState === 'asc' &&
                            sortState === 'last_login_at'
                              ? 'sortWrap sortWrap--up'
                              : 'sortWrap'
                          }
                        >
                          Last Login
                          <Icon
                            icon="Union"
                            color="secondary"
                            size={12}
                          />
                        </span>
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && role === 'Admin'
                      ? data.map((each) => {
                          const d2 = each.last_login_at
                            ? each.last_login_at.split(' ')[0].replace(/-/g, '')
                            : '';
                          return (
                            <tr
                              onClick={() => this.handleAdminMail(each.email)}
                              key={each.id}
                            >
                              <td className="d-none">&nbsp;</td>
                              <td>
                                <h5>{`${each.first_name} ${each.last_name}`}</h5>
                              </td>
                              <td>{each.company}</td>
                              <td>
                                {each.company_title ? each.company_title : '-'}
                              </td>
                              <td>{each.email}</td>
                              <td>{each.role}</td>
                              <td>{each.status}</td>
                              <td>
                                {d2
                                  ? `${d2.substring(4, 6)}/${d2.substring(
                                      6,
                                      9
                                    )}/${d2.substring(0, 4)}`
                                  : '-'}
                              </td>
                              <td className="actions">
                                <span
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title="Delete"
                                >
                                  <Icon
                                    icon="trash"
                                    size={18}
                                    color="#8F8F8F"
                                    onClick={(ev) =>
                                      this.deleteIcon(ev, each.id, each.email)
                                    }
                                  />
                                </span>
                                {each.status === 'Active' &&
                                each.role === 'User' ? (
                                  <span
                                    data-placement="top"
                                    data-toggle="tooltip"
                                    title="Login as"
                                  >
                                    <Icon
                                      icon="login-as"
                                      size={18}
                                      color="#8F8F8F"
                                      onClick={(ev) =>
                                        this.loginIcon(
                                          ev,
                                          each.email,
                                          each.role
                                        )
                                      }
                                    />
                                  </span>
                                ) : (
                                  ''
                                )}
                                <span
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title="Edit"
                                >
                                  <Icon
                                    icon="edit"
                                    size={18}
                                    color="#8F8F8F"
                                    onClick={(ev) =>
                                      this.updateUser(ev, each.email)
                                    }
                                  />
                                </span>
                                {each.status === 'Deactivated' ? (
                                  <span
                                    className="inactivedot"
                                    style={{ color: 'red', fontWeight: 'bold' }}
                                  />
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ''}
                    {errorMsg ? (
                      <tr
                        width="100%"
                        className="text-center"
                      >
                        <td
                          colSpan="9"
                          width="100%"
                        >
                          {errorMsg}
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* {loading ? <Loader /> : ''} */}
            <div
              id="adminFooter"
              className="card-footer clearfix"
            >
              {role === 'Admin' && data && data.length !== 0 ? (
                <Row>
                  <Col
                    xs="12"
                    lg="6"
                    md="6"
                    sm="6"
                    className="d-flex align-items-center"
                  >
                    <span className="fnt-14">
                      {`Showing ${fcounts} to ${scounts} of ${count} entries`}
                    </span>
                  </Col>
                  <Col
                    xs="12"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <ReactPaginate
                      forcePage={pagination} // {Number.isNaN(value) ? 0 : value}
                      previousLabel="Previous"
                      nextLabel="Next"
                      breakLabel="..."
                      breakClassName="break-me"
                      // marginPageDisplayed={2}
                      // pageRangeDisplayed={5}
                      pageCount={pageCount}
                      onPageChange={(e) => this.handlePageClick(e)}
                      containerClassName="pagination"
                      subcontainerClassname="pages-pagination"
                      previousLinkClassName="pagination__link"
                      nextLinkClassName="pagination__link"
                      activeClassName="active"
                    />
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {showPop ? (
          <Modal
            className="discModal  customModal customModal--w500 thanksModal"
            show={showPop}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.handleClose()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3 text-center">
                  <h2>
                    Please Verify!
                    <br />
                    You can not Login
                    <br />
                    as Admin
                  </h2>
                </div>
              </div>
              <Row>
                <Col className="buttonActions">
                  <Button
                    className="m-auto w-auto solidBtn solidBtn__blue pl-4 pr-4"
                    onClick={() => this.handleClose()}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        {deleteAlert ? (
          <Modal
            className="discModal  customModal customModal--w500 thanksModal"
            show={deleteAlert}
            animation={false}
          >
            <Icon
              icon="cross"
              className="closebuttonCust"
              onClick={() => this.cancelDelete()}
              size={10}
              color="#919eab"
            />
            <Modal.Body>
              <div className="sBlk py-2">
                <div className="crForm--login mt-3 text-center">
                  <h2>{deleteAlert}</h2>
                </div>
              </div>
              <Row>
                <Col className="buttonActions">
                  <Button
                    className="m-auto w-auto solidBtn solidBtn__blue pl-4 pr-4"
                    onClick={() => this.handleCloseDelete()}
                  >
                    Ok
                  </Button>
                  <button
                    type="button"
                    className="md-w-40 btn btn-outline-primary mr-3"
                    onClick={() => this.cancelDelete()}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withRouter(AdminHome);
