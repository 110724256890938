import '../../assets/scss/layouts/pages/_connectForm.scss';
import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import GeneralCataLogo from '../../assets/images/generalCata-logo.png';

function Congratulation() {
  const handleClick = (ev) => {
    ev.preventDefault();
    window.location.href = 'https://www.generalcatalyst.com/';
  };

  return (
    <div className="contactform">
      <div className="container">
        <Row className="borderRoundForm">
          <Col
            md={{ span: 4 }}
            className="bgdarkForm"
          >
            <div className="Form-leftSide">
              <h6>IN GOOD COMPANY</h6>
              <div className="headingGroup">
                <h3>Welcome to General Catalyst&apos;s Talent Network</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `The information you enter will be stored in GC's secure
                      database that can only be accessed by the GC partnership. We
                      will not share your information with the broader GC
                      portfolio without your consent.
                      <br /> <br />
                      Questions? Reach out to:
                      <a href="mailto: talent@generalcatalyst.com">
                        talent@generalcatalyst.com
                      </a>
                      .`,
                  }}
                />
              </div>

              <div className="contactForm-leftlogo">
                <img
                  src={GeneralCataLogo}
                  alt="logo"
                />
              </div>
            </div>
          </Col>
          <Col
            md={{ span: 8 }}
            className="custom-margin"
          >
            <div className="Congratulation">
              <div className="congrats">
                {' '}
                <h4>Congratulations!</h4>{' '}
              </div>
              <div className="">
                <div className="form-info">
                  <p>
                    {`Your information is successfully submitted to GC's secure database. 
                      Our team will review your profile and will be in touch
                      when we find a potential fit within our portfolio.`}
                  </p>
                </div>
                <div className="submit btn congratsbtn">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={(e) => handleClick(e)}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Congratulation;
